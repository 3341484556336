import axios from 'axios'

import { Visitor, AppointmentFormFields } from '../types/Interfaces'

class VisitorRegistrationService {
  async register(values: DeepPartial<Visitor>) {
    return axios
      .post(`/api/visitors`, { visitor: values })
      .then((response) => response.data)
  }

  async update(values: AppointmentFormFields) {
    return axios
      .put(`/api/visitors/${values.visitor_id}`, { visitor: values })
      .then((response) => response.data)
  }

  async fetchVisitorDetails(email: string) {
    return axios.get(`/api/visitors/${email}`, {}).then((response) => {
      return response.data
    })
  }
}

export default new VisitorRegistrationService()
