import { User } from '../types/Interfaces'

export const verifyUserAccountType = (expected_type: string, user?: User) => {
  if (user) {
    switch (expected_type) {
      case 'company_admin':
        return !user?.tenant_id && user?.account_type === 'admin'
      case 'global_or_admin':
        return (
          !user?.tenant_id && ['global', 'admin'].includes(user?.account_type)
        )
      case 'tenant_admin':
        return user?.tenant_id && user?.account_type === 'admin'
    }
  }
  return false
}
