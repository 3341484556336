/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'

import Theme from '../styles/theme'

import { useRecoilValue } from 'recoil'
import { companyState } from '../Session'
import { Company } from '../types/Interfaces'

interface Props {
  type: 'success' | 'error'
  show: boolean
  message?: string
  inline?: boolean
}

const InlineAlert = (props: Props) => {
  const company = useRecoilValue(companyState)

  if (!props.show) return null
  return (
    <div css={AlertStyles(props, company)}>
      {props.message || 'Oeps! Er is iets misgegaan. Probeer het opnieuw.'}
    </div>
  )
}

export default InlineAlert

const AlertStyles = (props: Props, company?: Company) => css`
  display: ${props.inline == true ? 'inline' : 'block'};
  margin: ${props.inline ? '0 1rem' : '2rem 0'};
  padding: 1rem 2rem;
  background: ${props.type == 'success'
    ? Theme(company).colors.backgrounds.success
    : Theme(company).colors.backgrounds.error};
  color: ${props.type == 'success'
    ? Theme(company).colors.fontcolors.success
    : Theme(company).colors.fontcolors.error};
  border: 0.1rem solid
    ${props.type == 'success'
      ? Theme(company).colors.borders.success
      : Theme(company).colors.borders.error};
  font-weight: normal;
`
