/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

const PageNotFound = () => {
  const { t } = useTranslation()
  return (
    <section css={PageNotFoundStyles}>
      <img src='/logo.svg' />
      <h1>{t('error:page_not_found')}</h1>
    </section>
  )
}

export default PageNotFound

const PageNotFoundStyles = css`
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;

  img {
    margin-bottom: 4rem;
    margin-right: 1rem;
    width: 80vw;
    max-width: 40rem;
  }
`
