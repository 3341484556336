/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useState } from 'react'
import Search from '../../../../base/components/Search'
import { Formik, Form } from 'formik'
import * as Validator from '../../../shared/Validations'
import { useAlert } from 'react-alert'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  SelectFieldWrapper,
  StyledCreatableSelect,
  Button,
} from '../../../components'

import {
  Appointment,
  User,
  Visitor,
  Company,
  Tenant,
} from '../../../types/Interfaces'

import { useRecoilValue, useRecoilState } from 'recoil'
import { companyState } from '../../../Session'
import UserCard from './UserCard'
import WelcomeText from './WelcomeText'

import Theme from '../../../styles/theme'
import { useTranslation } from 'react-i18next'

// type KeyboardEvent = 'keydown' | 'keyup'

interface Props {
  visitor?: Visitor
  appointments: Appointment[]
  users: User[]
  bufferAppointment: (userId: string) => void
  tenants: Tenant[]
  goToNextStep: () => void
  goToSecondStep: () => void
}

const Step3 = ({
  visitor,
  users,
  tenants,
  bufferAppointment,
  goToSecondStep,
  goToNextStep,
}: Props) => {
  const company = useRecoilValue(companyState)
  const [filteredUsers, setFilteredUsers] = useState(users)
  const [filter, setFilter] = useState<string>('')
  const { t } = useTranslation('visitor_form')
  const alert = useAlert()

  const newFormValues = {
    id: 0,
  }

  const handleUserClick = (userId?: number) => {
    if (userId) {
      bufferAppointment(userId?.toString())
      goToNextStep()
    } else {
      alert.show(t('no_employee_selected_error'), { type: 'error' })
    }
  }

  const handleUpdate = (targetValue: string) => {
    const search = targetValue.toLowerCase()
    setFilter(targetValue)
    if (targetValue) {
      setFilteredUsers(
        users.filter(
          (user) =>
            user.first_name.toLowerCase().startsWith(search) ||
            user.last_name.toLowerCase().startsWith(search),
        ),
      )
    } else {
      setFilteredUsers(users)
    }
  }

  const userDisplayShort = () => {
    return (
      <div>
        <Search
          label={t('search_user')}
          onChange={handleUpdate}
          value={filter}
        />

        <div className='user-display'>
          {filteredUsers.map((user, index) => (
            <UserCard
              key={index}
              user={user}
              handleUserClick={handleUserClick}
            />
          ))}
        </div>
      </div>
    )
  }

  const userDisplayLong = () => {
    return (
      <Formik
        initialValues={newFormValues}
        // validate={Validator.validate({ id: Validator.userId })}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values) => handleUserClick(values.id)}
      >
        {() => (
          <Form>
            <div css={StepContainerStyles(company)}>
              <SelectFieldWrapper label={t('appointment_with')} name='id'>
                <StyledCreatableSelect
                  name='id'
                  placeholder={t('appointment_with')}
                  options={users.map((user) => ({
                    label: user.first_name + ' ' + user.last_name,
                    value: user.id,
                    company_name: user.company_name,
                    portrait: user.portrait,
                  }))}
                />
              </SelectFieldWrapper>
            </div>
            <div css={ButtonStyles(company)}>
              <Button type='submit' size='large' value='' disabled={false}>
                <div>
                  {t('continue')}
                  <FontAwesomeIcon icon={['far', 'angle-right']} />
                </div>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    )
  }

  return (
    <div css={CurrentStepStyles(company)}>
      <div css={StepContainerStyles(company)}>
        <div className='step-3-header'>
          <div>
            <div className='visitor-welcome'>
              <WelcomeText
                visitor={visitor}
                stepBackFunction={() => goToSecondStep()}
              />
            </div>
          </div>
          <div className='selected-company'>
            <img
              className='selected-logo'
              src={tenants[0] ? tenants[0].logo : company?.logo}
            />
          </div>
        </div>
        <h2 className='selection'>{t('user_selection')}?</h2>
        {users.length <= 12 ? userDisplayShort() : userDisplayLong()}
      </div>
    </div>
  )
}

export default Step3

const StepContainerStyles = (company?: Company) => css`
  background-color: ${Theme(company).colors.backgrounds.inner};
  margin-bottom: 4rem;
  @media ${Theme(company).breakpoints.medium} {
    margin-bottom: 11.5rem;
  }
  @media ${Theme(company).breakpoints.medium} {
    margin-top: 7.6rem;
  }
`

const CurrentStepStyles = (company?: Company) => css`
  color: #807c79;
  font-size: 2rem;
  line-height: 2.4rem;
  margin-bottom: 5.1rem;
  margin-top: 1.6rem;

  .visitor-welcome {
    margin-bottom: 0rem;
  }

  .step-3-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6rem;

    @media ${Theme(company).breakpoints.smallDown} {
      margin-bottom: 5rem;
    }

    @media ${Theme(company).breakpoints.mobileDown} {
      display: block;
      margin-bottom: 2.5rem;
    }
  }

  .user-display {
    margin-top: 2rem;
    display: grid;
    row-gap: 6rem;
    grid-template-columns: repeat(4, 1fr);

    @media ${Theme(company).breakpoints.notDesktop} {
      grid-template-columns: repeat(3, 1fr);
    }

    @media ${Theme(company).breakpoints.smallDown} {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 4rem;
    }
  }

  .selected-logo {
    max-width: 20rem;
    max-height: 10rem;

    @media ${Theme(company).breakpoints.mobileDown} {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      max-width: 10rem;
    }
  }

  .selected-company {
    text-align: right;

    @media ${Theme(company).breakpoints.mobileDown} {
      text-align: left;
    }
  }

  h1 {
    margin-right: 1.3rem;
  }

  h2.selection {
    margin-bottom: 6.5rem;
    color: #070707;
    font-size: 3rem;
    font-weight: 400;
    letter-spacing: -0.039rem;

    @media ${Theme(company).breakpoints.mobileDown} {
      margin-bottom: 3.5rem;
    }
  }
  input[type='search'] {
    margin-bottom: 5.5rem;
    //In case we find out it bugs in even older versions of Safari (as per https://github.com/h5bp/html5-boilerplate/issues/396)
    /* -webkit-appearance: textfield; */
  }
`

const ButtonStyles = (company?: Company) => css`
  > div {
    @media ${Theme(company).breakpoints.mediumDown} {
      justify-content: center;
    }

    button {
      min-width: 25rem;
      @media ${Theme(company).breakpoints.mediumDown} {
        width: 100%;
        min-width: auto;
        padding: 2.3rem 4.5rem;
      }

      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
`
