/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React, { useLayoutEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom'
import qs from 'qs'
import { useRecoilState } from 'recoil'
import { userState, companyState } from '../../../Session'
import { useTranslation } from 'react-i18next'

import {
  PageStyles,
  ContainerStyles,
  MainStyles,
  ActionBarStyles,
  FooterStyles,
} from '../../../styles/container-styles'

import { InlineAlert, Button, InputField, Logo } from '../../../components'
import AuthenticationService from '../../../services/AuthenticationService'

import { FormResponse } from '../../../types/Interfaces'

import * as Validator from '../../../shared/Validations'

const UserLogin = (props: RouteComponentProps) => {
  const { t } = useTranslation('user_authentication')
  const [company] = useRecoilState(companyState)
  const [user] = useRecoilState(userState)
  const [submissionError, setSubmissionError] = useState<string | undefined>()

  useLayoutEffect(() => {
    if (user) props.history.push('/admin')
  }, [])

  return (
    <section css={PageStyles(company)}>
      <div css={ContainerStyles('small', company)}>
        <div css={MainStyles}>
          <Logo url={company?.logo} />
          <h4>{t('sign_in')}</h4>

          <Formik
            initialValues={{
              email:
                (qs.parse(props.location.search, {
                  ignoreQueryPrefix: true,
                }).email as string) || '',
              password: '',
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmissionError(undefined)

              try {
                const response: FormResponse = await AuthenticationService.signIn(
                  values,
                )
                if (response.success) {
                  window.location.href = response.redirect
                    ? `${response.redirect}?email=${values.email}`
                    : '/admin'
                } else {
                  setSubmissionError(response.errors)
                }
              } catch {
                setSubmissionError(Validator.unexpectedError)
              }

              setSubmitting(false)
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <InputField type='text' label='E-mail' name='email' />

                {company && (
                  <React.Fragment>
                    <InputField
                      type='password'
                      label={t('field:password')}
                      name='password'
                      autoComplete='current-password'
                      caption={
                        <span css={ForgotPasswordStyles}>
                          {t('forgot_password')}{' '}
                          <NavLink to='/users/password/new'>
                            {t('click_here')}
                          </NavLink>
                          .
                        </span>
                      }
                    />
                  </React.Fragment>
                )}

                <div css={ActionBarStyles}>
                  <Button
                    type='submit'
                    value={company ? t('sign_in') : t('go_to_dashboard')}
                    loading={isSubmitting}
                  />
                </div>

                {submissionError && (
                  <InlineAlert
                    type='error'
                    show={true}
                    message={submissionError}
                  />
                )}
              </Form>
            )}
          </Formik>
        </div>

        {company && company.domains.length > 0 && (
          <div css={FooterStyles(company)}>
            <span>
              {t('no_account_yet')}{' '}
              <NavLink to='/users/sign_up'>{t('create_account_now')}</NavLink>.
            </span>
          </div>
        )}
      </div>
    </section>
  )
}

const ForgotPasswordStyles = css`
  margin-top: 1rem !important;
`

export default withRouter(UserLogin)
