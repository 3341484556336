/** @jsx jsx */
import { useTranslation } from 'react-i18next'
import { jsx, css } from '@emotion/core'
import Theme from '../../../styles/theme'

import { Visitor, Company } from '../../../types/Interfaces'
import StepBackButton from './StepBackButton'

interface Props {
  visitor?: Visitor
  stepBackFunction: () => void
}
const WelcomeText = (props: Props) => {
  const { t } = useTranslation('visitor_form')

  return (
    <div>
      <StepBackButton stepBack={props.stepBackFunction} />
      <div className='visitor-welcome' css={VisitorStyles}>
        <div className='welcome-text'>
          <h1>{t('welcome_only')}</h1>
          <h1> {props.visitor?.first_name + ' ' + props.visitor?.last_name}</h1>
        </div>
      </div>
    </div>
  )
}

export default WelcomeText

const VisitorStyles = css`
  display: flex;
  margin-bottom: 5rem;

  .welcome-text {
    display: flex;
    flex-wrap: nowrap;

    @media ${Theme().breakpoints.mediumDown} {
      display: block;
    }

    h1 {
      margin-right: 1.3rem;
      margin-bottom: 0rem;
    }
  }

  @media ${Theme().breakpoints.mediumDown} {
    align-items: end;
  }

  @media ${Theme().breakpoints.smallDown} {
    display: block;
  }
`
