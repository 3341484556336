import axios from 'axios'
import { Review } from '../types/Interfaces'

class ReviewService {
  async fetchAll() {
    return axios.get(`/api/reviews`).then((response) => response.data)
  }

  async update(reference: string, values: DeepPartial<Review>) {
    return axios
      .put(`/api/reviews/${reference}`, { review: values })
      .then((response) => response.data)
  }
}

export default new ReviewService()
