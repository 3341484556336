/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'
import Theme from '../styles/theme'

import { useRecoilValue } from 'recoil'
import { companyState } from '../Session'
import { Company } from '../types/Interfaces'

interface Props {
  image?: string
  size: string
}

const Portrait = (props: Props) => {
  const company = useRecoilValue(companyState)

  const setFallbackImage = (event: any) => {
    event.target.src = '/silhouette.png'
  }

  return (
    <figure css={PortraitStyles(company)} className={props.size}>
      <img src={props.image || '/silhouette.png'} onError={setFallbackImage} />
    </figure>
  )
}

export default Portrait

const PortraitStyles = (company?: Company) => css`
  margin-right: 1rem;
  background: ${Theme(company).colors.backgrounds.outer};
  border-radius: 50%;
  &.medium {
    width: 4rem;
    height: 4rem;
  }
  &.small {
    width: 2.3rem;
    height: 2.3rem;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`
