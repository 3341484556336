/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

import { Link } from 'react-router-dom'
import { Fade } from 'react-awesome-reveal'
import { Button, PageWidth } from '../../components'

import Theme from '../../styles/theme'

const Hero = (): JSX.Element => {
  const { t } = useTranslation('homepage')

  return (
    <PageWidth wide className='grow'>
      <div css={HeroStyles}>
        <div className='cta'>
          <Fade
            triggerOnce
            cascade
            direction='left'
            damping={0.25}
            delay={500}
            duration={700}
          >
            <h1>{t('header')}</h1>
            <p className='subheader'>{t('subheader')}</p>
            <p className='subheader'>{t('subheader_two')}</p>
            {/* <Link to='/company/sign_up'>
              <Button type='default' size='large' value=''>
                <div>{t('cta_button')}</div>
              </Button>
            </Link> */}
          </Fade>
        </div>
        <Fade triggerOnce duration={700}>
          <div css={VideoStyles}>
            <video
              poster='/video-placeholder.jpg'
              controls={false}
              playsInline={true}
              autoPlay={true}
              muted={true}
              loop={true}
            >
              <source
                src='https://s3-eu-west-1.amazonaws.com/content.bouwensgroep.nl/video/arrived-at-square.mp4'
                type='video/mp4'
              />
            </video>
          </div>
        </Fade>
      </div>
    </PageWidth>
  )
}

export default Hero

const HeroStyles = css`
  display: flex;
  justify-content: space-between;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 2rem;
  margin-bottom: 8rem;

  @media ${Theme().breakpoints.notDesktop} {
    margin-bottom: 6rem;
    flex-direction: column;
  }

  .cta {
    width: 100%;
    max-width: 52.6rem;
    margin-right: 5%;
    h1 {
      font-size: 5rem;
      letter-spacing: -0.066rem;
      line-height: 5.6rem;
      margin-top: 11rem;
      margin-bottom: 4.5rem;
      max-width: 48rem;

      @media ${Theme().breakpoints.mediumDown} {
        margin-top: 2rem;
      }
    }

    p.subheader {
      font-size: 2rem;
      line-height: 3rem;
      margin: 2rem 0;
      color: ${Theme().colors.fontcolors.body};
      @media ${Theme().breakpoints.mediumDown} {
        margin: 4rem 0;
      }
    }

    a {
      &:hover {
        text-decoration: none;
      }
      > div {
        justify-content: flex-start;
      }

      button {
        padding: 2rem 4rem;

        @media ${Theme().breakpoints.mediumDown} {
          padding: 2rem;
          font-size: 2.4rem;
        }
      }
    }
  }
`

const VideoStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;

  video {
    max-width: min(100%, 65rem);

    @media ${Theme().breakpoints.notDesktop} {
      margin-top: 5rem;
    }
  }
`
