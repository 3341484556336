/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useCallback, useRef, useState } from 'react'
import useClickOutside from '../../hooks/clickOutside'
import { Field, FieldProps } from 'formik'
import Theme from '../../styles/theme'

import FieldCaptionOrError from './FieldCaptionOrError'
import { HexColorPicker } from 'react-colorful'
import 'react-colorful/dist/index.css'

import { useRecoilValue } from 'recoil'
import { companyState } from '../../Session'
import { Company } from '../../types/Interfaces'

interface Props {
  label: string
  name: string
  setColor: (color: string) => void
}

const ColorField = (props: Props) => {
  const company = useRecoilValue(companyState)

  return (
    <Field name={props.name}>
      {({ field, meta }: FieldProps) => (
        <div
          css={InputStyles(company)}
          // Have to use classes, conditional Styles causes issues
          className={`${meta.touched && meta.error ? 'has-error' : ''}`}
        >
          <label>{props.label}</label>
          <ColorPicker value={`#${field.value}`} setColor={props.setColor} />
          <div className='input-wrapper'>
            <input
              maxLength={6}
              type='text'
              {...field}
              onPaste={(e) => props.setColor(e.clipboardData.getData('Text'))}
            />
            <FieldCaptionOrError error={meta.error} />
          </div>
        </div>
      )}
    </Field>
  )
}

const ColorPicker = (props: {
  value?: string
  setColor: (color: string) => void
}) => {
  const [isOpen, toggle] = useState(false)
  const close = useCallback(() => toggle(false), [])
  const popover = useRef(null)

  useClickOutside(popover, close)

  return (
    <div css={ColorPickerStyles}>
      <div
        className='swatch'
        style={{ backgroundColor: props.value || '#FFFFFF' }}
        onClick={() => toggle(true)}
      />

      {isOpen && (
        <div className='popover' ref={popover}>
          <HexColorPicker
            color={props.value || '#FFFFFF'}
            onChange={props.setColor}
          />
        </div>
      )}
    </div>
  )
}

export default ColorField

const ColorPickerStyles = css`
  position: relative;

  .swatch {
    width: 4rem;
    height: 4rem;
    margin: 0 2rem;
    border-radius: 50%;
    border: 0.4rem solid white;
    box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.1),
      inset 0 0 0 0.1rem rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  .popover {
    position: absolute;
    top: calc(100% + 0.3rem);
    left: 0;
    border-radius: 0.9rem;
    box-shadow: 0 0.6rem 1.2rem rgba(0, 0, 0, 0.15);
  }

  .react-colorful {
    z-index: 10;
  }
`

const InputStyles = (company?: Company) => css`
  display: flex;
  align-items: center;
  position: relative;
  white-space: nowrap;
  margin: 1rem 0;

  label {
    flex-grow: 1;
    font-weight: 400;
    min-width: 12rem;
  }

  .input-wrapper {
    margin-bottom: -1.5rem;
    min-width: 7.5rem;
    position: relative;
    &:before {
      content: '#';
      position: absolute;
      left: 0;
      top: 0.4rem;
      color: ${Theme(company).colors.fontcolors.primary};
    }
  }

  input {
    margin: 0;
    min-width: 0;
    width: 100%;
    font-size: 1.6rem;
    font-weight: 300;
    color: ${Theme(company).colors.fontcolors.body};
    border: 0;
    border-bottom: 0.1rem solid ${Theme(company).colors.borders.primary};
    border-width: 0.1rem;
    background: ${Theme(company).colors.backgrounds.input};
    height: 3rem;
    padding-left: 1.7rem;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: #070707 !important;
    }

    &:hover,
    &:focus {
      outline: 0;
    }
    &::placeholder {
      color: ${Theme(company).colors.fontcolors.primary};
      font-weight: normal;
    }
  }

  @media ${Theme(company).breakpoints.mobileDown} {
    flex-wrap: wrap;
  }

  &.has-error {
    input {
      border-bottom-color: ${Theme(company).colors.borders.error};
      &::placeholder {
        color: ${Theme(company).colors.fontcolors.error};
      }
    }

    label {
      color: ${Theme(company).colors.fontcolors.error};
    }
  }
`
