/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import Theme from '../../styles/theme'
import Select, { components } from 'react-select'
import CreatableSelect from 'react-select/creatable'

import { useRecoilState } from 'recoil'
import { companyState } from '../../Session'

import { Company } from '../../types/Interfaces'

// React-select does not accept a defaultValue prop of just the value,
// but requires an object with a matching .value AND a matching .label.
// So we find the matching option here to pass through to the react-select component.
const matchingOption = (defaultValue: any, options: any) => {
  if (defaultValue != undefined) {
    if (defaultValue?.value != undefined) {
      // In this case the defaultValue already has a .value, so we assume it is react-select ready.
      return defaultValue
    } else {
      const valueToMatch = defaultValue.toString()

      return options.find((option: any) => {
        return valueToMatch == option.value.toString()
      })
    }
  }
}

export const StyledSelect = (props: any) => {
  const [company] = useRecoilState(companyState)
  const { defaultValue, customOption, ...otherProps } = props

  return (
    <Select
      css={SelectCss(props, company)}
      styles={SelectStyles(company)}
      components={
        customOption
          ? { DropdownIndicator, Option: customOption }
          : { DropdownIndicator }
      }
      defaultValue={matchingOption(defaultValue, props.options)}
      isDisabled={props.disabled}
      maxMenuHeight={props.className === 'user-invitation' ? 220 : 150}
      {...otherProps}
    />
  )
}

export const StyledCreatableSelect = (props: any) => {
  const [company] = useRecoilState(companyState)
  const { defaultValue, ...otherProps } = props

  return (
    <CreatableSelect
      css={SelectCss(props, company)}
      styles={SelectStyles(company)}
      components={{ DropdownIndicator, Option: CustomOption }}
      formatCreateLabel={(userInput) => `${userInput}`}
      defaultValue={matchingOption(defaultValue, props.options)}
      isDisabled={props.disabled}
      {...otherProps}
    />
  )
}

const DropdownIndicator = (props: any) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}></components.DropdownIndicator>
    )
  )
}

const CustomOption = (props: any) => {
  const [company] = useRecoilState(companyState)
  const option = props.options.find(
    (t: {
      label: string
      value: string
      company_name: string
      portrait: string
    }) => t.value === props.value,
  )
  return (
    <components.Option {...props}>
      <div
        css={OptionStyles}
        className={option && option.company_name ? 'has-details' : ''}
      >
        <div className='has-portrait'>
          <img
            className='drop-down-portrait'
            src={option.portrait ? option.portrait : '/silhouette.png'}
          />
          <div>
            <div>{props.label}</div>
            {company?.is_multi_tenant && (
              <div className='details'>{option.company_name}</div>
            )}
          </div>
        </div>
      </div>
    </components.Option>
  )
}

const SelectCss = (props: any, company?: Company) => css`
  margin: 1.8rem 0 0.3rem;
  font-size: 1.6rem;
  font-weight: 300;
  border: 0;
  border-bottom: 0.1rem solid ${Theme(company).colors.borders.primary};
  border-width: 0.1rem;
  background: ${Theme(company).colors.backgrounds.input};
  width: 100%;
  height: 4.1rem;
  transition: 0.2s padding ease-in-out;

  &:hover,
  &:focus {
    outline: 0;
  }
  &:invalid {
    color: ${Theme(company).colors.fontcolors.primary};
  }
  & .Selectplaceholder {
    color: ${props.disabled
      ? Theme(company).colors.fontcolors.disabled
      : Theme(company).colors.fontcolors.primary};
  }
`

const SelectStyles = (company?: Company) => {
  return {
    placeholder: (provided: any, state: any) => ({
      ...provided,
      fontSize: '1.6rem',
      marginTop: '8px',
      color: state.selectProps.isDisabled
        ? Theme(company).colors.fontcolors.disabled
        : Theme(company).colors.fontcolors.primary,
    }),
    control: (provided: any) => ({
      ...provided,
      backgroundColor: 'none',
      border: 0,
      boxShadow: 'none',
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      alignItems: 'flex-end',
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      color: Theme(company).colors.fontcolors.primary,
      padding: '0px 8px',
      transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: '2px 0px',
      height: '32px',
      alignItems: 'flex-end',
    }),
    input: (provided: any) => ({
      ...provided,
      marginTop: '8px',
    }),
    option: (provided: any, { isFocused, isSelected }: any) => ({
      ...provided,
      fontSize: '1.6rem',
      background: isFocused
        ? `${Theme(company).colors.backgrounds.outer} !important`
        : 'transparent !important',
      color: isSelected ? Theme(company).colors.fontcolors.primary : 'unset',
      '&:hover': {
        cursor: 'pointer',
      },
    }),
    menuList: (provided: any) => ({
      ...provided,
      fontSize: '1.6rem',
      backgroundColor: Theme(company).colors.backgrounds.inner,
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: Theme(company).colors.fontcolors.body,
      marginTop: '8px',
      marginLeft: '0px',
    }),
  }
}

const OptionStyles = css`
  .details {
    font-size: 1.4rem;
    opacity: 0.5;
  }

  .has-portrait {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    align-items: center;

    .drop-down-portrait {
      max-height: 5rem;
      margin-left: 1rem;
      margin-right: 2rem;
      border: white solid 0.1rem;
      box-shadow: 0 0 1.8rem 0 rgba(92, 88, 86, 0.15);
      border-radius: 50%;
    }
  }
`
