/** @jsx jsx */
import { jsx, css } from '@emotion/core'

import {
  Appointment,
  Visitor,
  Company,
  Tenant,
} from '../../../types/Interfaces'

import { useState, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { companyState } from '../../../Session'
import WelcomeText from './WelcomeText'

import Theme from '../../../styles/theme'
import { useTranslation } from 'react-i18next'

interface Props {
  visitor?: Visitor
  appointments: Appointment[]
  goToNextStep: () => void
  setTenant: (tenantOrCompany: Tenant | Company, tenant_id?: number) => void
  goToFirstStep: () => void
  tenantsWithUsers: Tenant[]
}

const Step2 = ({
  visitor,
  tenantsWithUsers,
  goToNextStep,
  setTenant,
  goToFirstStep,
}: Props) => {
  const company = useRecoilValue(companyState)
  const company_and_tenants =
    company &&
    [...tenantsWithUsers, company].sort((a, b) => {
      const x = a.name.toLowerCase()
      const y = b.name.toLowerCase()
      if (x < y) {
        return -1
      }
      if (x > y) {
        return 1
      }
      return 0
    })
  const [selectedLetter, setLetter] = useState<string>()
  const [availableLetters, setAvailableLetters] = useState<string[]>([])
  const [companyAndTenants, setCompanyAndTenants] = useState(
    company_and_tenants || [],
  )
  const [filteredTenants, setFilteredTenants] = useState(
    company_and_tenants || [],
  )
  const [showAlphabet, changeShowAlphabet] = useState(false)
  const { t } = useTranslation('visitor_form')
  const alphabet = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('')

  useEffect(() => {
    const tenantLetters = companyAndTenants.map((tenant) => {
      return tenant.name[0]
    })
    setAvailableLetters(
      tenantLetters.filter(
        (tenantLetter, index) => tenantLetters.indexOf(tenantLetter) == index,
      ),
    )
  }, [])

  const handleLetterClick = (filter: string) => {
    if (selectedLetter === filter) {
      setLetter(undefined)
      company_and_tenants && setFilteredTenants(company_and_tenants)
    } else {
      setLetter(filter)
      if (filter === 'Other') {
        setFilteredTenants(
          companyAndTenants.filter((tenant) =>
            /^[^a-zA-Z]$/.test(tenant.name[0]),
          ),
        )
      } else {
        setFilteredTenants(
          companyAndTenants.filter((tenant) => tenant.name[0] === filter),
        )
      }
    }
    changeShowAlphabet(false)
  }

  const handleFilterClick = () => {
    changeShowAlphabet(!showAlphabet)
  }

  const handleCompanyClick = (selectedCompany: Tenant | Company) => {
    // passing a company without its tenants
    if (selectedCompany === company) {
      setTenant(selectedCompany)
      // passing a tenant of that company
    } else {
      setTenant(selectedCompany, selectedCompany.id)
    }
    goToNextStep()
  }

  const letterGridDisplay = () => {
    return (
      <div className={'letter-grid' + (showAlphabet ? ' show' : '')}>
        {alphabet.map((letter) => (
          <button
            key={letter}
            className={'letter ' + (selectedLetter == letter ? 'active' : '')}
            onClick={() => handleLetterClick(letter)}
            disabled={!availableLetters.includes(letter)}
          >
            {letter}
          </button>
        ))}
        <button
          className={
            'letter' + (selectedLetter == 'Other' ? ' active' : '') + ' other'
          }
          onClick={() => handleLetterClick('Other')}
          disabled={
            companyAndTenants.filter((tenant) =>
              /^[^a-zA-Z]$/.test(tenant.name[0]),
            ).length == 0
          }
        >
          Other
        </button>
      </div>
    )
  }

  const filterCompanyButton = () => {
    return (
      <button
        onClick={handleFilterClick}
        className={'filter-button' + (showAlphabet ? ' show' : '')}
      >
        {t('filter_by_alphabet')}
      </button>
    )
  }

  return (
    <div>
      <div css={CurrentStepStyles(company)}>
        <WelcomeText
          visitor={visitor}
          stepBackFunction={() => goToFirstStep()}
        />
        <h2 className='selection'>{t('tenant_selection')}?</h2>
        {companyAndTenants &&
          companyAndTenants.length > 9 &&
          filterCompanyButton()}

        {companyAndTenants &&
          companyAndTenants.length > 9 &&
          letterGridDisplay()}
        <div className='tenant-grid'>
          {(filteredTenants.length == 0
            ? companyAndTenants
            : filteredTenants
          ).map((company, index) => {
            return (
              <button
                onClick={() => handleCompanyClick(company)}
                key={index}
                className='tenant-preview'
              >
                <div className='tenant-logo-preview'>
                  <img className='logo-preview' src={company.logo} />
                </div>
                <p className='tenant-title' key={index}>
                  {company.name}
                </p>
              </button>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Step2

const CurrentStepStyles = (company?: Company) => css`
  margin-bottom: 4rem;
  background-color: ${Theme(company).colors.backgrounds.inner};
  color: #807c79;
  font-size: 2rem;
  line-height: 2.4rem;
  margin-bottom: 5.1rem;
  margin-top: 1.6rem;

  @media ${Theme(company).breakpoints.medium} {
    margin-bottom: 11.5rem;
  }

  h2.selection {
    margin-bottom: 8.5rem;
    color: #070707;
    font-size: 3rem;
    font-weight: 400;
    letter-spacing: -0.039rem;
  }

  button.change {
    border: none;
    outline: none;
    background: none;
  }

  .filter-button {
    outline: none;
    border: none;
    background-color: ${Theme(company).colors.fontcolors.primary};
    padding: 1.3rem 1.6rem;
    border-radius: 0rem;
    color: white;
    margin-bottom: 50rem;

    &.show {
      background-color: white;
      color: black;
    }

    &:hover {
      opacity: 0.85;
      cursor: pointer;
    }

    @media ${Theme(company).breakpoints.small} {
      display: none;
    }
  }

  .letter-grid {
    display: grid;
    gap: 3rem 1.8rem;
    padding-bottom: 6.7rem;
    width: 100%;
    max-width: 108rem;

    button.letter {
      outline: none;
      width: 5rem;
      height: 5rem;
      color: black;
      background-color: white;
      border: none;
      border-radius: 0rem;
      cursor: pointer;
    }

    button.letter.other {
      width: 11.9rem;
    }

    button.letter:disabled {
      color: #b2b2b2;
      background-color: white;
    }

    button.letter.active {
      outline: none;
      color: white;
      background-color: ${Theme(company).colors.fontcolors.primary};
      border-radius: 0rem;
    }

    @media ${Theme(company).breakpoints.largeDesktop} {
      grid-template-columns: repeat(14, 1fr);

      button.letter.other {
        grid-column: 13 / span 2;
      }
    }

    @media ${Theme(company).breakpoints.notLargeDesktop} {
      grid-template-columns: repeat(12, 1fr);

      button.letter.other {
        grid-column: 3 / span 2;
      }
    }

    @media ${Theme(company).breakpoints.notDesktop} {
      grid-template-columns: repeat(8, 1fr);

      button.letter.other {
        grid-column: 3 / span 2;
      }
    }

    @media ${Theme(company).breakpoints.smallDown} {
      grid-template-columns: repeat(5, 1fr);
      display: none;

      &.show {
        display: grid;
      }

      button.letter.other {
        grid-column: 2 / span 2;
      }
    }
  }

  .tenant-grid {
    display: grid;
    grid-gap: 3rem 9.6rem;
    width: 100%;
    margin: auto;
    max-width: 108rem;

    .tenant-preview {
      outline: none;
      border: none;
      background-color: ${Theme(company).colors.backgrounds.inner};
      height: 15.6rem;
      text-align: center;
      cursor: pointer;
    }

    .tenant-logo-preview {
      border-radius: 0.75rem;
      height: 12.144rem;
      width: 100%;
      box-shadow: 0 0 1.8rem 0 rgba(92, 88, 86, 0.15);
      background-color: white;
      padding: 1.5rem 3.3rem 1.5rem 3.3rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img.logo-preview {
      max-height: 100%;
      max-width: 100%;
      margin: 0rem auto;
    }

    p.tenant-title {
      height: 3rem;
      width: 100%;
      color: #070707;
      font-family: 'GT Eesti Pro Display';
      font-size: 2rem;
      font-weight: 400;
      letter-spacing: -0.026rem;
      line-height: 3rem;
      margin: 0rem auto;
      margin-top: 0.896rem;
    }

    @media ${Theme(company).breakpoints.largeDesktop} {
      grid-template-columns: repeat(3, 1fr);
    }

    @media ${Theme(company).breakpoints.notLargeDesktop} {
      grid-template-columns: repeat(2, 1fr);
    }

    @media ${Theme(company).breakpoints.mediumDown} {
      grid-template-columns: 1fr;
    }
  }

  @media ${Theme(company).breakpoints.medium} {
    margin-top: 7.6rem;
  }
`
