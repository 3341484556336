/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useLayoutEffect } from 'react'
import {
  Switch,
  Route,
  RouteComponentProps,
  withRouter,
} from 'react-router-dom'

import { useRecoilState } from 'recoil'
import { userState } from '../../Session'

import { Main, Header, Footer } from '../../components'
import {
  AdminAppointments,
  AdminSettings,
  AdminUsers,
  AdminVisitors,
  AdminReviews,
  PageNotFound,
} from '../../pages'

const AdminIndex = (props: RouteComponentProps) => {
  const [user] = useRecoilState(userState)

  useLayoutEffect(() => {
    if (!user) props.history.push('/users/sign_in')
  }, [])

  return (
    <Main>
      <Header />

      <Switch>
        <Route exact path='/admin/appointments' component={AdminAppointments} />
        <Route exact path='/admin/visitors' component={AdminVisitors} />
        {user?.account_type !== 'individual' && (
          <Route exact path='/admin/users' component={AdminUsers} />
        )}
        {user?.account_type !== 'individual' && (
          <Route exact path='/admin/reviews' component={AdminReviews} />
        )}
        {user?.account_type === 'admin' && (
          <Route exact path='/admin/settings' component={AdminSettings} />
        )}
        <Route exact path='/admin' component={AdminAppointments} />
        <Route component={PageNotFound} />
      </Switch>

      <Footer />
    </Main>
  )
}

export default withRouter(AdminIndex)
