/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import Theme from '../../styles/theme'
import React from 'react'

interface CaptionOrErrorProps {
  caption?: string | JSX.Element
  error?: string
}

const FieldCaptionOrError = (props: CaptionOrErrorProps) => {
  const text = props.error ? props.error : props.caption

  const styles = [CaptionOrErrorStyles]
  if (props.error) {
    styles.push(ErrorStyles)
  }

  return <span css={styles}>{text}</span>
}

export default FieldCaptionOrError

const CaptionOrErrorStyles = css`
  display: block;
  font-weight: normal;
  margin-top: -0.3rem;
  font-size: 1.3rem;
  height: 2rem;
`

const ErrorStyles = css`
  color: ${Theme().colors.fontcolors.error};
`
