/** @jsx jsx */
import { jsx, css } from '@emotion/core'

import { Footer } from '../../components'
import Header from './Header'
import Hero from './Hero'
// import SellingPoints from './SellingPoints'
// import Benefits from './Benefits'
// import CallToActionBanner from './CallToActionBanner'
// import About from './About'

import Theme from '../../styles/theme'

const Home = (): JSX.Element => {
  return (
    <section css={HomeStyles}>
      <Header />
      <Hero />
      {/* <SellingPoints /> */}
      {/* <Benefits /> */}
      {/* <CallToActionBanner /> */}
      {/* <About /> */}
      <Footer />
    </section>
  )
}

export default Home

const HomeStyles = css`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: white;

  .page-width {
    padding: 0 9rem;
    @media ${Theme().breakpoints.mediumDown} {
      padding: 0 3rem;
    }

    .language-selector {
      .language {
        margin: 0 0 0 2.2rem;
      }
    }
  }
`
