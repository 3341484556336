/** @jsx jsx */ import { jsx, css } from '@emotion/core'
import React from 'react'
import Theme from '../styles/theme'

import { useRecoilValue } from 'recoil'
import { companyState } from '../Session'
import { Company } from '../types/Interfaces'

const Main = (props: React.PropsWithChildren<unknown>) => {
  const company = useRecoilValue(companyState)

  return (
    <section className='main' css={MainStyles(company)}>
      {props.children}
    </section>
  )
}

export default Main

const MainStyles = (company?: Company) => css`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 100%;

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  > section {
    padding: 6rem 8rem 12rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    @media ${Theme(company).breakpoints.largeDesktop} {
      flex-direction: row;
    }
    @media ${Theme(company).breakpoints.mediumDown} {
      padding: 4rem 2rem;
    }
  }
`
