import { atom } from 'recoil'
import { SessionData } from './types/Interfaces'

const sessionData: SessionData = JSON.parse(
  (
    document.getElementById('session') || document.createElement('slot')
  ).getAttribute('data') || '{}',
)

export const companyState = atom({
  key: 'company',
  default: sessionData.company,
})

export const userState = atom({
  key: 'user',
  default: sessionData.user,
})

export const flashState = atom({
  key: 'flash',
  default: sessionData.flash,
})

export const toggledLanguagesState = atom({
  key: 'toggledLanguages',
  default: [''],
})
