/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useRecoilState } from 'recoil'
import { companyState } from '../Session'

import { Company } from '../types/Interfaces'

interface Props {
  url?: string
  style?: 'black' | 'white'
}

const Logo = (props: Props) => {
  const [company] = useRecoilState(companyState)

  return (
    <figure className='logo' css={FigureStyles}>
      <img
        css={LogoStyles(company)}
        src={
          props.url ||
          `/logo${
            props.style
              ? props.style == 'white'
                ? '-white'
                : ''
              : company?.theme == 'dark'
              ? '-white'
              : ''
          }.svg`
        }
        onError={(e: any) => {
          e.target.onerror = null
          e.target.src = '/logo.svg'
        }}
      />
    </figure>
  )
}

export default Logo

const FigureStyles = css`
  display: flex;
  justify-content: center;
`

const LogoStyles = (company?: Company) => css`
  width: auto;
  height: auto;
  max-height: 15rem;
  max-width: 100%;
`
