/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

import { useRecoilValue } from 'recoil'
import { companyState } from '../Session'
import { Company } from '../types/Interfaces'

import Theme from '../styles/theme'

import { Logo, PageWidth } from '../components'
import { supportedLanguages, useLanguage } from '../hooks/language'

const languages = {
  nl: 'Nederlands',
  en: 'English',
}

const Footer = (): JSX.Element => {
  const { t } = useTranslation('footer')
  const company = useRecoilValue(companyState)
  const [selected, setLanguage] = useLanguage()

  return (
    <footer css={FooterStyles(company)}>
      <PageWidth css={FooterContentStyles(company)}>
        <div className='top'>
          <div className='left'>
            Emmaplein 2
            <br />
            1075 AW Amsterdam
          </div>
          <div className='left'>
            <a href='tel:+31206422820'> +31 20 642 28 20</a>
            <br />
            <a href='mailto:arrived@arrived.at'>arrived@arrived.at</a>
          </div>
          <div className='right'>
            <Logo style='white' />
            <span>
              {t('bouwens_product')}{' '}
              <a href='https://bouwensen.nl' target='_blank' rel='noreferrer'>
                bouwens&
              </a>
            </span>
          </div>
        </div>
        <div className='bottom'>
          <div className='left'>
            <a
              href='https://bouwensen.nl/gebruikersvoorwaarden'
              target='_blank'
              rel='noreferrer'
            >
              {t('terms_of_use')}
            </a>
            <a href='/privacy-policy' target='_blank' rel='noreferrer'>
              {t('privacy_policy')}
            </a>
          </div>
          <div className='left'>
            <a
              href='https://bouwensen.nl/cookie-verklaring'
              target='_blank'
              rel='noreferrer'
            >
              {t('cookies')}
            </a>
            <a
              href='https://bouwensen.nl/disclaimer'
              target='_blank'
              rel='noreferrer'
            >
              {t('disclaimer')}
            </a>
          </div>
          <div className='right'>
            {supportedLanguages.map(
              (lang) =>
                lang != selected && (
                  <a
                    href='#'
                    key={lang}
                    onClick={(event) => {
                      event.preventDefault()
                      setLanguage(lang)
                    }}
                  >
                    {languages[lang]}
                  </a>
                ),
            )}
          </div>
        </div>
      </PageWidth>
    </footer>
  )
}

export default Footer

const FooterStyles = (company?: Company) => css`
  min-height: 10rem;
  width: 100%;
  padding: 7rem 3rem;
  background-color: ${Theme(company).colors.backgrounds.dark};
  font-weight: normal;
  color: white;
`

const FooterContentStyles = (company?: Company) => css`
  line-height: 3rem;

  .top {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 2.5rem;

    .right {
      color: #aba9a8;

      .logo {
        max-width: 18.4rem;
        margin-left: auto;
      }
    }

    a {
      color: #fafafa;
    }
  }

  .bottom {
    display: flex;
    flex-wrap: wrap;
    border-top: 0.1rem solid #8b8887;
    padding-top: 2.5rem;
    align-items: flex-end;

    .left a {
      display: block;
      color: #aba9a8;
    }

    .right a {
      display: block;
      color: #aba9a8;
      text-transform: uppercase;
    }
  }

  .left {
    flex: 1;
    width: 25%;
    max-width: 25rem;
    min-width: 20rem;

    @media ${Theme(company).breakpoints.mediumDown} {
      text-align: right;
    }
  }

  .right {
    flex: 2;
    justify-self: stretch;
    text-align: right;
    min-width: 20rem;

    @media ${Theme(company).breakpoints.mediumDown} {
      margin-top: 3rem;
    }
  }
`
