import React from 'react'
import {
  Font,
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
  pdf,
} from '@react-pdf/renderer'

import { WifiIcon, SvgChart } from '../hooks/svgChart'

import { saveAs } from 'file-saver'

import Theme from '../styles/theme'

import { Company } from '../types/Interfaces'

Font.register({
  family: 'GT Eesti Pro Display Light',
  src: '/fonts/GTEestiProDisplay-Light.ttf',
})

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    padding: '50 80',
    fontWeight: 'normal',
    fontSize: '27',
    fontFamily: 'GT Eesti Pro Display Light',
  },
  section: {
    margin: 10,
    padding: 10,
    textAlign: 'center',
  },
  svgContainer: {
    padding: '15 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  pngContainer: {
    padding: '15 100',
  },
  qrContainer: {
    width: '300',
    height: '300',
    margin: '0 48',
    backgroundColor: 'white',
    borderRadius: '50',
  },
  qrImage: {
    width: '272',
    height: '272',
    margin: '14',
  },
  linkContainer: {
    width: '300',
    height: '50',
    margin: '0 48',
    backgroundColor: Theme().colors.backgrounds.outer,
    borderRadius: '20',
    fontSize: '14',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
})

const QrPoster = async (company?: Company) => {
  const svg =
    company?.logo && company?.logo_content_type == 'image/svg+xml'
      ? await fetch(company.logo).then((response) => response.text())
      : undefined

  try {
    await generatePdf(<PdfDocument svg={svg} company={company} />)
  } catch {
    await generatePdf(
      <PdfDocument svg={svg} svgFallback={true} company={company} />,
    )
  }
}

const generatePdf = async (doc: JSX.Element) => {
  const blobPdf = pdf(doc)
  blobPdf.updateContainer(doc)
  const result = await blobPdf.toBlob()
  saveAs(result, 'poster.pdf')
}

const PdfDocument = (props: {
  svg?: string
  company?: Company
  svgFallback?: boolean
}) => {
  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={styles.section}>
          {props.svg && (
            <View
              style={
                props.svgFallback ? styles.pngContainer : styles.svgContainer
              }
            >
              {props.svgFallback && (
                <Image
                  src={props.company?.jpg_logo || '/logo-a4@2x.png'}
                  style={{ maxHeight: '80', objectFit: 'contain' }}
                />
              )}
              {!props.svgFallback && <SvgChart svg={props.svg} />}
            </View>
          )}
          {!props.svg && (
            <View style={styles.pngContainer}>
              <Image
                src={props.company?.logo || '/logo-a4@2x.png'}
                style={{ maxHeight: '80', objectFit: 'contain' }}
              />
            </View>
          )}

          <Text>Scan en registreer je bezoek</Text>

          <View style={{ padding: '30 170' }}>
            <WifiIcon color={Theme(props.company).colors.fontcolors.primary} />
          </View>

          <View style={styles.qrContainer}>
            <Image style={styles.qrImage} src={'/qr.png'} />
          </View>

          <Text style={{ padding: '30' }}>of ga naar de website</Text>

          <View style={styles.linkContainer}>
            <Image
              style={{ width: '26', height: '26', marginRight: '10' }}
              src={'/pdf/globe.png'}
            />
            <Text style={{ textAlign: 'left', fontSize: '20' }}>
              {window.location.host.includes('acceptance') ? 'acceptance.' : ''}
              arrived.at/
              <Text
                style={{
                  color: Theme(props.company).colors.fontcolors.primary,
                }}
              >
                {props.company?.domain}
              </Text>
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default QrPoster
