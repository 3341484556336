/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, css } from '@emotion/core'
import React, { useEffect, useState } from 'react'
import useStateSafe from '../../helpers/use-state-safe'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import moment from 'moment-timezone'
import Theme from '../../styles/theme'
import Modal from 'react-modal'
import { useTranslation } from 'react-i18next'

import Calendar from 'react-calendar'
import {
  PageWidth,
  Button,
  Spinner,
  InlineAlert,
  ReviewScore,
} from '../../components'
import HealthCheckIcon from '../../components/HealthCheckIcon'
import AppointmentForm from '../../components/admin/AppointmentForm'
import AppointmentService from '../../services/AppointmentService'
import 'react-calendar/dist/Calendar.css'
import {
  Appointment,
  AppointmentFormFields,
  Company,
} from '../../types/Interfaces'

import { useRecoilValue } from 'recoil'
import { companyState } from '../../Session'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ModalStyles } from '../../styles/modal-styles'
import { CalendarStyles } from '../../styles/calendar-styles'
import { EmptyTableStyles } from '../../styles/global-styles'

import * as Validator from '../../shared/Validations'
import { useLanguage } from '../../hooks/language'

const AdminAppointments = (props: RouteComponentProps): JSX.Element => {
  const { t } = useTranslation('admin_appointments')
  const [language] = useLanguage()
  const [appointments, setAppointments] = useStateSafe<Appointment[]>([])
  const [addingNewAppointment, setAddingNewAppointment] = useState(false)
  const [
    editingAppointment,
    setEditingAppointment,
  ] = useState<Appointment | null>(null)
  const [selectedDate, setSelectedDate] = useState<Date | undefined>()
  const [selectingDate, setSelectingDate] = useState(false)
  const [loaded, setLoaded] = useStateSafe(false)
  const [error, setError] = useStateSafe(false)
  const company = useRecoilValue(companyState)

  moment.locale(language)

  useEffect(() => {
    if (window.location.search) {
      const params = new URLSearchParams(window.location.search)
      setSelectedDate(moment(params.get('date'), 'DD-MM-YYYY').toDate())
    } else {
      setSelectedDate(new Date())
    }
  }, [])

  useEffect(() => {
    if (selectedDate) {
      retrieveAppointments(moment(selectedDate).format('DD-MM-YYYY'))
    }
  }, [selectedDate])

  function updateDate(date: Date) {
    setLoaded(false)
    setSelectedDate(date)
    setSelectingDate(false)
    props.history.push(`?date=${moment(date).format('DD-MM-YYYY')}`)
  }

  async function retrieveAppointments(formattedDate?: string) {
    setError(false)
    const startTime = Date.now()

    try {
      return await AppointmentService.fetchAll(undefined, formattedDate).then(
        (response) => {
          setTimeout(() => {
            setAppointments(response.appointments)
            setLoaded(true)
          }, Math.max(500 - (Date.now() - startTime), 0))
        },
      )
    } catch {
      setLoaded(true)
      setError(true)
    }
  }

  function healthy(
    appointment: Appointment,
  ): string | boolean | null | undefined {
    if (appointment.visits[0]) {
      return appointment.visits[0].healthy
    } else {
      return null
    }
  }

  function validAppointments(): Appointment[] {
    return appointments.filter(
      (appointment) => !!appointment.visits[0] && !!appointment.user,
    )
  }

  function editingAppointmentData(): AppointmentFormFields | undefined {
    // Ideally we wouldn't have to flatten the appointment structure here
    // but it's the simplest solution since we aren't actually supporting
    // multiple visitors / visits in a single appointment
    if (editingAppointment) {
      return Object.assign(
        {},
        editingAppointment,
        editingAppointment.visitors[0],
        editingAppointment.visits[0],
        {
          user_id: editingAppointment.user.id,
          scheduled_at_date: moment(editingAppointment.scheduled_at).format(
            'DD-MM-YYYY',
          ),
          scheduled_at_time: editingAppointment.unplanned
            ? ''
            : moment(editingAppointment.scheduled_at)
                .tz('Europe/Amsterdam')
                .format('HH:mm'),
          arrived_at_time: editingAppointment.visits[0].arrived_at
            ? moment(editingAppointment.visits[0].arrived_at)
                .tz('Europe/Amsterdam')
                .format('HH:mm')
            : '',
          healthy:
            healthy(editingAppointment) === null
              ? ''
              : healthy(editingAppointment),
        },
      )
    }
  }

  const BaseContent = (
    props: React.PropsWithChildren<unknown>,
  ): JSX.Element => {
    return (
      <section css={DashboardStyles(company)}>
        <PageWidth>
          <header>
            <h1>{t('appointments')}</h1>
            <Button
              type='default'
              size='default'
              value=''
              onClick={() => setAddingNewAppointment(true)}
            >
              <div>
                {t('add_new_appointment')}
                <FontAwesomeIcon icon={['fas', 'calendar-check']} />
              </div>
            </Button>
          </header>
          <div
            className='date-selector'
            onClick={() => setSelectingDate(!selectingDate)}
          >
            {moment(selectedDate).format('dddd D MMMM')}
            {selectingDate ? (
              <FontAwesomeIcon icon={['fas', 'angle-up']} />
            ) : (
              <FontAwesomeIcon icon={['fas', 'angle-down']} />
            )}
          </div>
          <div
            className='react-calendar-container popup-calendar'
            css={CalendarStyles(company)}
          >
            {selectingDate && (
              <Calendar
                locale={language}
                onChange={(date) => updateDate(date as Date)}
                value={selectedDate}
              />
            )}
          </div>
          {props.children}
        </PageWidth>

        <Modal
          isOpen={addingNewAppointment || !!editingAppointment}
          onRequestClose={() => {
            setAddingNewAppointment(false)
            setEditingAppointment(null)
          }}
          ariaHideApp={false}
          style={ModalStyles(company)}
          parentSelector={() => document.body}
        >
          <AppointmentForm
            onAppointmentChange={async () => {
              await retrieveAppointments(
                moment(selectedDate).format('DD-MM-YYYY'),
              )
              setAddingNewAppointment(false)
              setEditingAppointment(null)
            }}
            selectedDate={selectedDate || new Date()}
            appointmentData={editingAppointmentData()}
          />
        </Modal>
      </section>
    )
  }

  if (!loaded) {
    return (
      <BaseContent>
        <Spinner size='large' />
      </BaseContent>
    )
  } else if (error) {
    return (
      <BaseContent>
        <InlineAlert
          type='error'
          show={true}
          message={Validator.unexpectedError}
        />
      </BaseContent>
    )
  } else if (validAppointments().length) {
    return (
      <BaseContent>
        <div className='table-container'>
          <table>
            <thead>
              <tr>
                <th>{t('field:name')}</th>
                <th>{t('field:phone')}</th>
                <th>{t('appointment_with')}</th>
                <th className='centered'>{t('planned')}</th>
                <th className='centered'>{t('arrived')}</th>
                <th>{t('feedback')}</th>
                <th className='centered'>{t('health_check')}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {validAppointments().map((appointment) => {
                return (
                  <tr
                    key={appointment.id}
                    className={`${
                      healthy(appointment) === false
                        ? 'unhealthy'
                        : healthy(appointment) === true
                        ? 'healthy'
                        : !appointment.visits[0].arrived_at
                        ? 'not-arrived'
                        : ''
                    }`}
                  >
                    {!!appointment.visitors[0] && (
                      <React.Fragment>
                        <td>
                          {appointment.visitors[0].first_name +
                            ' ' +
                            appointment.visitors[0].last_name}
                          <a href={`mailto:${appointment.visitors[0].email}`}>
                            <FontAwesomeIcon icon={['fas', 'envelope']} />
                          </a>
                        </td>
                        <td className='phone'>
                          {appointment.visitors[0].phone}
                        </td>
                      </React.Fragment>
                    )}
                    {!appointment.visitors[0] && (
                      <React.Fragment>
                        <td style={{ opacity: 0.5, fontStyle: 'italic' }}>
                          {t('unknown')}
                        </td>
                        <td className='phone' />
                      </React.Fragment>
                    )}
                    <td className='user'>
                      <a onClick={() => setEditingAppointment(appointment)}>
                        {!appointment.user.id && (
                          <FontAwesomeIcon
                            color={Theme(company).colors.interaction.warning}
                            icon={['far', 'exclamation-circle']}
                          />
                        )}
                        {appointment.user.name}
                      </a>
                    </td>
                    <td className='centered'>
                      {appointment.unplanned
                        ? '-'
                        : moment(appointment.scheduled_at)
                            .tz('Europe/Amsterdam')
                            .format('HH:mm')}
                    </td>
                    <td className='centered'>
                      {appointment.visits[0].arrived_at
                        ? moment(appointment.visits[0].arrived_at)
                            .tz('Europe/Amsterdam')
                            .format('HH:mm')
                        : '-'}
                    </td>
                    <td>
                      {appointment.review_score === null ? (
                        '-'
                      ) : appointment.review_score === 0 ? (
                        t('no_feedback_reply')
                      ) : (
                        <ReviewScore
                          score={appointment.review_score as number}
                          tooltip={true}
                          remarks={appointment.review_remarks}
                        />
                      )}
                    </td>
                    <td className='health-check'>
                      <HealthCheckIcon healthy={healthy(appointment)} />
                    </td>
                    <td className='actions'>
                      <FontAwesomeIcon
                        icon={['fas', 'cog']}
                        onClick={() => setEditingAppointment(appointment)}
                      />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </BaseContent>
    )
  } else {
    return (
      <BaseContent>
        <div css={EmptyTableStyles}>
          <h4>{t('no_visitors_on_this_day')}</h4>
          <span>{t('no_visitors_on_this_day_description')}</span>
        </div>
      </BaseContent>
    )
  }
}

export default withRouter(AdminAppointments)

const DashboardStyles = (company?: Company) => css`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6rem;

    @media ${Theme(company).breakpoints.mobileDown} {
      flex-direction: column;
      margin-bottom: 4rem;
    }

    h1 {
      margin: 0;
      @media ${Theme(company).breakpoints.mediumDown} {
        margin-bottom: 3rem;
      }
    }

    button {
      font-size: 1.6rem;
    }
  }

  .date-selector {
    text-transform: capitalize;
    font-size: 2.6rem;
    letter-spacing: -0.034rem;
    line-height: 2.4rem;
    cursor: pointer;

    svg {
      position: relative;
      top: 0.2rem;
      font-size: 2.4rem;
      margin-left: 1.6rem;
    }
  }

  .react-calendar-container {
    position: relative;
    margin-top: 2rem;

    .react-calendar {
      position: absolute;
      width: 100%;
      padding: 1rem 1rem 0.5rem;
      border: none;
      border-radius: 0.7rem;
      overflow: hidden;
      box-shadow: ${Theme(company).colors.borders.mediumContainerShadow};
      @media ${Theme(company).breakpoints.medium} {
        width: 32.5rem;
      }
    }
  }

  .table-container {
    @media ${Theme(company).breakpoints.notLargeDesktop} {
      overflow-x: scroll;
    }
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 1.3rem;
    font-size: 1.6rem;

    td,
    th {
      &.centered {
        text-align: center;
      }
    }

    thead {
      th {
        white-space: nowrap;
        text-align: left;
        padding: 1.5rem;
        font-weight: normal;

        &:first-of-type {
          padding-left: 0;
        }

        &:last-of-type {
          text-align: center;
        }
      }
    }

    tbody {
      tr {
        background: ${Theme(company).colors.backgrounds.inner};
        box-shadow: ${Theme(company).colors.borders.mediumContainerShadow};
        &.unhealthy {
          background-color: rgba(216, 35, 42, 0.1);
          color: ${Theme(company).colors.interaction.failure};

          td a {
            color: ${Theme(company).colors.interaction.failure};
          }
        }
        &.not-arrived {
        }

        td {
          padding: 1.5rem;
          white-space: nowrap;

          &:first-of-type {
            font-weight: normal;
          }

          &.health-check,
          &.actions {
            text-align: center;
          }

          &.user {
            svg {
              margin-right: 1rem;
            }
          }

          &.actions {
            svg {
              cursor: pointer;
              vertical-align: middle;
              opacity: 0.8;
              &:hover {
                opacity: 1;
              }
            }
          }

          &.health-check {
            svg {
              vertical-align: middle;
              font-size: 1.8rem;
            }
          }

          a {
            color: ${Theme(company).colors.fontcolors.body};

            > svg {
              margin-left: 1.2rem;
            }
          }

          &.phone {
            letter-spacing: 0.05rem;
          }
        }
      }
    }
  }
`
