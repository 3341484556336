import axios from 'axios'

import { Appointment, AppointmentFormFields } from '../types/Interfaces'

class AppointmentService {
  async fetchAll(email?: string, date?: string) {
    return axios
      .get(`/api/appointments`, { params: { email: email, date: date } })
      .then((response) => response.data)
  }

  async create(
    visitorEmail: string | null,
    visitorId: number | null,
    userId: string,
    scheduledTime?: Date,
    send_review_request?: boolean,
    language?: 'en' | 'nl',
    values?: DeepPartial<Appointment>,
  ) {
    return axios
      .post(
        `/api/appointments`,
        Object.assign(
          {
            user_id: userId,
            scheduled_at: scheduledTime || new Date(),
            send_review_request: send_review_request,
            language: language,
            ...(values || {}),
          },
          visitorEmail ? { email: visitorEmail } : { visitor_id: visitorId },
        ),
      )
      .then((response) => response.data)
  }

  async update(values: AppointmentFormFields) {
    return axios
      .put(`/api/appointments/${values.appointment_id}`, {
        appointment: values,
      })
      .then((response) => response.data)
  }

  async delete(id: number) {
    return axios.delete(`/api/appointments/${id}`)
  }
}

export default new AppointmentService()
