/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useLayoutEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRecoilState } from 'recoil'
import { companyState, flashState, userState } from '../../../Session'

import {
  PageStyles,
  ContainerStyles,
  MainStyles,
  ActionBarStyles,
  FooterStyles,
  SubmissionSucceededStyles,
} from '../../../styles/container-styles'

import { InlineAlert, Button, InputField, Logo } from '../../../components'
import AuthenticationService from '../../../services/AuthenticationService'

import * as Validator from '../../../shared/Validations'
import { useTranslation } from 'react-i18next'

const UserResetPassword = (props: RouteComponentProps) => {
  const { t } = useTranslation('user_authentication')
  const [company] = useRecoilState(companyState)
  const [flash] = useRecoilState(flashState)
  const [user] = useRecoilState(userState)
  const [submissionError, setSubmissionError] = useState<string | undefined>()

  useLayoutEffect(() => {
    if (user) props.history.push('/admin')
  }, [])

  const newFormValues = {
    email: '',
  }

  return (
    <section css={PageStyles(company)}>
      <div css={ContainerStyles('small', company)}>
        <div css={MainStyles}>
          <Logo url={company?.logo} />

          {flash && (
            <div css={SubmissionSucceededStyles(company)}>
              <h2>{t('reset_password_requested')}</h2>
              <img src='/email.svg' />
              <span>{t('reset_password_email_sent')}</span>
            </div>
          )}

          {!flash && (
            <React.Fragment>
              <h4>{t('reset_password')}</h4>
              <p>{t('reset_password_request_details')}</p>

              <Formik
                initialValues={newFormValues}
                onSubmit={async (values, { setSubmitting }) => {
                  setSubmissionError(undefined)
                  try {
                    const response = await AuthenticationService.requestPasswordReset(
                      values,
                    )

                    if (response.success) {
                      window.location.href = '/users/password/new'
                    } else {
                      setSubmissionError(Validator.formatErrors(response))
                    }
                  } catch {
                    setSubmissionError(Validator.unexpectedError)
                  }
                  setSubmitting(false)
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <InputField type='text' label='E-mail' name='email' />

                    <div css={ActionBarStyles}>
                      <a onClick={() => window.history.go(-1)}>
                        <FontAwesomeIcon icon={['far', 'angle-left']} />
                        {t('action:previous_page')}
                      </a>
                      <Button
                        type='submit'
                        value={t('action:submit')}
                        loading={isSubmitting}
                      />
                    </div>

                    <InlineAlert
                      type='error'
                      show={!!submissionError}
                      message={submissionError}
                    />
                  </Form>
                )}
              </Formik>
            </React.Fragment>
          )}
        </div>

        {company && company.domains.length > 0 && (
          <div css={FooterStyles(company)}>
            <span>
              {t('no_account_yet')}{' '}
              <NavLink to='/users/sign_up'>{t('create_account_now')}</NavLink>.
            </span>
          </div>
        )}
      </div>
    </section>
  )
}

export default withRouter(UserResetPassword)
