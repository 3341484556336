import axios from 'axios'

import { User, NewCompany, Tenant } from '../types/Interfaces'

class AuthenticationService {
  async register(values: DeepPartial<User>) {
    return axios
      .post(`/users`, { user: values })
      .then((response) => response.data)
  }

  async registerCompany(values: DeepPartial<NewCompany>) {
    return axios
      .post(`/company/sign_up`, { user: values })
      .then((response) => response.data)
  }

  async invite(values: DeepPartial<User>) {
    return axios
      .post(`/users/invite`, { user: values })
      .then((response) => response.data)
  }

  async confirmInvitation(values: DeepPartial<User>) {
    return axios
      .put(`/users/confirm_invitation`, { user: values })
      .then((response) => response.data)
  }

  async signIn(values: DeepPartial<User>) {
    return axios
      .post(`/users/sign_in`, { user: values })
      .then((response) => response.data)
  }

  async signOut() {
    return axios
      .delete(`/users/sign_out`)
      .then(() => (window.location.href = '/users/sign_in'))
  }

  async requestPasswordReset(values: DeepPartial<User>) {
    return axios
      .post(`/users/password`, { user: values })
      .then((response) => response.data)
  }

  async changePassword(values: DeepPartial<User>) {
    return axios
      .put(`/users/password`, { user: values })
      .then((response) => response.data)
  }
}

export default new AuthenticationService()
