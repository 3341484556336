/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState } from 'react'
import { Formik, Form } from 'formik'
import { useAlert } from 'react-alert'
import { useTranslation } from 'react-i18next'

import { User, FormResponse } from '../../../types/Interfaces'

import * as Validator from '../../../shared/Validations'

import { ActionBarStyles } from '../../../styles/container-styles'

import { verifyUserAccountType } from '../../../helpers/user'

import {
  Button,
  InlineAlert,
  InputField,
  InputGroup,
  SelectFieldWrapper,
  StyledSelect,
} from '../../../components'
import AuthenticationService from '../../../services/AuthenticationService'

import { useRecoilState, useRecoilValue } from 'recoil'
import { userState } from '../../../Session'
import { companyState } from '../../../Session'

interface Props {
  onNewUser: () => void
}
const UserInvitation = (props: Props) => {
  const { t } = useTranslation('user_invitation')
  const [user] = useRecoilState(userState)
  const [attemptedSubmission, setAttemptedSubmission] = useState(false)
  const [submissionError, setSubmissionError] = useState<string | undefined>()
  const alert = useAlert()
  const company = useRecoilValue(companyState)
  const [disabled, setDisabled] = useState(false)

  const newFormValues: DeepPartial<User> = {
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    account_type: undefined,
    tenant_id: undefined,
    phone: '',
  }

  const handleAccountTypeSelection = (value: number | string) => {
    value === 'global' ? setDisabled(true) : setDisabled(false)
  }

  return (
    <section>
      <div>
        <div>
          <Formik
            initialValues={newFormValues}
            validateOnChange={attemptedSubmission}
            validateOnBlur={attemptedSubmission}
            validate={(values) => {
              let error: string | undefined
              const errors: any = {}
              if ((error = Validator.visitorEmail(values.email?.trim())))
                errors.email = error

              if ((error = Validator.firstName(values.first_name)))
                errors.first_name = error

              if ((error = Validator.lastName(values.last_name)))
                errors.last_name = error

              if (
                (error = Validator.accountType(values.account_type)) &&
                verifyUserAccountType('company_admin', user)
              )
                errors.account_type = error
              return errors
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmissionError(undefined)
              try {
                const response: FormResponse = await AuthenticationService.invite(
                  values,
                )
                if (response.success) {
                  alert.show(t('user_invited'))
                  props.onNewUser()
                  // throw 'false'
                } else {
                  setSubmissionError(Validator.formatErrors(response))
                  setSubmitting(false)
                }
              } catch {
                setSubmissionError(Validator.unexpectedError)
                setSubmitting(false)
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <h4>{t('invite_user')}</h4>
                {verifyUserAccountType('company_admin', user) && (
                  <SelectFieldWrapper
                    name='account_type'
                    label={t('field:account_type')}
                    defaultValue={{
                      label: t('account_type:individual'),
                      value: 'individual',
                    }}
                    customOnChange={handleAccountTypeSelection}
                  >
                    <StyledSelect
                      options={['individual', 'global', 'admin'].map(
                        (value) => ({
                          label: t('account_type:' + value),
                          value,
                        }),
                      )}
                      name='account_type'
                      isSearchable={false}
                    />
                  </SelectFieldWrapper>
                )}

                {company?.is_multi_tenant &&
                  !disabled &&
                  verifyUserAccountType('global_or_admin', user) && (
                    <SelectFieldWrapper name='tenant_id' label={t('tenant')}>
                      <StyledSelect
                        options={company?.tenants.map((tenant) => ({
                          label: tenant.name,
                          value: tenant.id,
                        }))}
                        placeholder={t('tenant')}
                        name='tenant_id'
                        isSearchable={true}
                        disabled={disabled}
                        className='user-invitation'
                      />
                    </SelectFieldWrapper>
                  )}
                <InputGroup>
                  <InputField
                    type='text'
                    label={t('field:first_name')}
                    name='first_name'
                  />
                  <InputField
                    type='text'
                    label={t('field:last_name')}
                    name='last_name'
                  />
                </InputGroup>
                <InputField type='text' label={t('field:email')} name='email' />
                <div css={ActionBarStyles}>
                  <Button
                    type='submit'
                    value={t('invite')}
                    loading={isSubmitting}
                    onClick={() => setAttemptedSubmission(true)}
                  />
                </div>
                <InlineAlert
                  type='error'
                  show={!!submissionError}
                  message={submissionError}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </section>
  )
}

export default UserInvitation
