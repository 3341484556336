/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Dispatch, SetStateAction } from 'react'
import { useAlert } from 'react-alert'
import { useTranslation } from 'react-i18next'

import { Company, FormResponse, Tenant } from '../types/Interfaces'

import Theme from '../styles/theme'
import ValidatedDomainsForm from './admin/ValidatedDomainsForm'
import CustomMessagesForm from './admin/CustomMessagesForm'

import { useRecoilState } from 'recoil'
import { companyState } from '../Session'
import { userState } from '../Session'

import TenantService from '../services/TenantService'

import LogoDropZone from '../shared/LogoDropZone'

import * as Validator from '../shared/Validations'

interface Props {
  propagateResult: (response: Company) => void
  active: boolean
  expandedTenantIds: number[]
  setExpandedTenantIds: Dispatch<SetStateAction<number[]>>
  tenant: Tenant
  onAdminPage: boolean
}

const TenantsAdminShow = (props: Props): JSX.Element => {
  const { t } = useTranslation('admin_settings')
  const [company, setCompany] = useRecoilState(companyState)
  const alert = useAlert()
  const [user] = useRecoilState(userState)

  const handleUpdateResult = async (
    request: Promise<FormResponse>,
    successMessage?: string,
  ) => {
    try {
      const response = await request

      if (response.success) {
        props.propagateResult(response.body as Company)
        alert.show(successMessage || t('settings_updated'))
      } else {
        alert.show(Validator.formatErrors(response), {
          type: 'error',
          timeout: 0,
        })
      }
    } catch {
      alert.show(Validator.unexpectedError, { type: 'error', timeout: 0 })
    }
  }

  const upload = async (tenant: Tenant, [file]: File[]) => {
    if (tenant) {
      const response = await TenantService.updateLogo(file, tenant)
      setCompany(response.body)
    }
  }

  return (
    <div key={props.tenant.id} css={TenantStyles(company, props.onAdminPage)}>
      {user?.tenant_id && <h2>{props.tenant.name}</h2>}
      <div
        className={`columns ${
          props.expandedTenantIds.includes(props.tenant.id) && 'open'
        }`}
      >
        <div className='left'>
          <ValidatedDomainsForm
            handleSubmit={handleUpdateResult}
            entity={props.tenant}
            type='tenant'
          />
        </div>
        <div className='right'>
          <LogoDropZone
            uploadLogo={(file: File[]) => upload(props.tenant, file)}
            company={props.tenant}
          />
          <CustomMessagesForm
            handleSubmit={handleUpdateResult}
            service={TenantService}
            entity={props.tenant}
          />
        </div>
      </div>
    </div>
  )
}

export default TenantsAdminShow

const TenantStyles = (company?: Company, onAdminPage?: boolean) => css`
  background: ${Theme(company).colors.backgrounds.inner};
  box-shadow: ${!onAdminPage &&
  Theme(company).colors.borders.mediumContainerShadow};
  margin-top: 1.5rem;

  header {
    padding: 1.5rem 2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      display: flex;
      font-weight: normal;

      svg {
        margin-right: 0.9rem;
        width: 0.625em !important;
        font-size: 2rem;
      }
    }

    > a {
      cursor: pointer;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
  }

  h2 {
    color: ${Theme(company).colors.fontcolors.body};
    font-size: 2.6rem;
    font-weight: 300;
    letter-spacing: -0.034rem;
    padding-left: 4rem;
    padding-top: 4rem;
  }

  .columns {
    padding: 2rem 4rem;
    display: none;
    &.open {
      display: flex;
    }
  }
`
