import './translations'
import { useRecoilState } from 'recoil'
import { companyState, flashState } from './Session'

import React, { useEffect } from 'react'
import { useAlert } from 'react-alert'
import { Switch, Route } from 'react-router-dom'

import {
  VisitorForm,
  AdminDashboard,
  PageNotFound,
  FeedbackForm,
  UserForgotPassword,
  UserLogin,
  UserRegistration,
  UserResetPassword,
  VisitorRegistration,
  UserCompleteInvitation,
  CompanyRegistration,
  PrivacyPolicy,
  Home,
} from './pages'

import axios from 'axios'

axios.defaults.xsrfCookieName = 'CSRF-TOKEN'
axios.defaults.xsrfHeaderName = 'X-CSRF-Token'
axios.defaults.withCredentials = true

const App = () => {
  const alert = useAlert()
  const [company] = useRecoilState(companyState)
  const [flash, setFlash] = useRecoilState(flashState)

  useEffect(() => {
    document.querySelector('slot')?.remove()

    if (flash) {
      alert.show(flash.message, {
        type: flash.type == 'notice' ? 'success' : 'error',
      })

      setFlash(undefined)
    }
  }, [])

  return (
    <Switch>
      <Route path='/admin' component={AdminDashboard} />

      <Route exact path='/users/sign_in' component={UserLogin} />
      <Route exact path='/users/confirmation' component={UserLogin} />
      <Route
        exact
        path='/users/complete_invitation'
        component={UserCompleteInvitation}
      />

      {company && company.domains.length > 0 && (
        <Route exact path='/users/sign_up' component={UserRegistration} />
      )}

      {!company && (
        <Route exact path='/company/sign_up' component={CompanyRegistration} />
      )}
      <Route exact path='/register' component={VisitorRegistration} />
      <Route exact path='/feedback' component={FeedbackForm} />

      <Route exact path='/users/password/new' component={UserForgotPassword} />
      <Route exact path='/users/password/edit' component={UserResetPassword} />
      <Route exact path='/privacy-policy' component={PrivacyPolicy} />
      <Route exact path='/' component={company ? VisitorForm : Home} />
      <Route component={PageNotFound} />
    </Switch>
  )
}

export default App
