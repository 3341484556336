/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { NavLink, Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans, useTranslation } from 'react-i18next'

import { PageStyles, ContainerStyles } from '../styles/container-styles'

import { Button, Logo, LanguageFlagSelector } from '../components'

import { useRecoilState } from 'recoil'
import { companyState } from '../Session'

import Theme from '../styles/theme'

import { Company } from '../types/Interfaces'

const VisitorForm = () => {
  const [company] = useRecoilState(companyState)
  const { t } = useTranslation('visitor_form')

  return (
    <section css={[PageStyles(company), TransparentPageStyles(company)]}>
      <div css={FloatingCtaStyles(company)}>
        <NavLink to='/users/sign_in' style={{ textDecoration: 'none' }}>
          <Button type='inverted' value={t('employee_login')} />
        </NavLink>
      </div>

      <div css={ContainerStyles('large', company)}>
        <div css={MainStyles(company)}>
          <Logo url={company?.logo} />

          <h1>{t('welcome_to', { company: company?.name })}</h1>

          <p className='introduction'>{t('visitor_registration')}</p>
          <LanguageFlagSelector />

          <p className='small'>
            <Trans
              ns='visitor_form'
              i18nKey='you_agree_to_terms_and_conditions'
              components={{
                a: (
                  <a href='/privacy-policy' target='_blank' rel='noreferrer' />
                ),
              }}
            />
          </p>

          <Link to='/register' style={{ textDecoration: 'none' }}>
            <Button type='default' size='large' value=''>
              <div>
                {t('start_registration')}
                <FontAwesomeIcon icon={['far', 'angle-right']} />
              </div>
            </Button>
          </Link>

          <p>
            {t('not_a_visitor_but_employee')}{' '}
            <NavLink to='/users/sign_in'>{t('click_here')}.</NavLink>
          </p>
        </div>
      </div>
    </section>
  )
}

export default VisitorForm

const MainStyles = (company?: Company) => css`
  padding: 5rem 4rem;
  text-align: center;
  @media ${Theme(company).breakpoints.mediumDown} {
    padding: 3rem 2rem;
  }

  h1 > span {
    display: inline-block;
  }

  @media ${Theme(company).breakpoints.mobileDown} {
    h1 {
      font-size: 3rem;
      line-height: 3.5rem;
    }
  }

  figure.logo {
    margin-bottom: 5rem;
    @media ${Theme(company).breakpoints.mediumDown} {
      margin-bottom: 3rem;
    }

    img {
      margin: 0 auto;
      max-width: 50%;
    }
  }

  p.introduction {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  p.small {
    font-size: 1.4rem;
  }

  p {
    max-width: 32rem;
    margin: 0 auto;
  }

  button {
    margin: 3rem auto;

    div {
      width: 31rem;
      max-width: 65vw;
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        margin-left: 1rem;
      }
    }
  }
`

const TransparentPageStyles = (company?: Company) => css`
  flex-grow: 1;
  background: ${Theme(company).colors.backgrounds.outer};
`

const FloatingCtaStyles = (company?: Company) => css`
  position: absolute;
  top: 2rem;
  right: 3rem;
  @media ${Theme(company).breakpoints.mediumDown} {
    display: none;
  }
`
