import 'babel-polyfill'
import 'element-remove'
import React from 'react'
import { RecoilRoot } from 'recoil'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import Wrapper from '../base/Wrapper'

document.addEventListener('DOMContentLoaded', () => {
  const root = document.createElement('div')
  root.className = 'root'

  ReactDOM.render(
    <BrowserRouter>
      <RecoilRoot>
        <Wrapper />
      </RecoilRoot>
    </BrowserRouter>,
    document.body.appendChild(root),
  )
})
