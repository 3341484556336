/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useLayoutEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import qs from 'qs'
import { useTranslation } from 'react-i18next'

import { useRecoilState } from 'recoil'
import { companyState, userState } from '../../../Session'

import {
  PageStyles,
  ContainerStyles,
  MainStyles,
  ActionBarStyles,
} from '../../../styles/container-styles'

import { InlineAlert, Button, InputField, Logo } from '../../../components'
import AuthenticationService from '../../../services/AuthenticationService'

import { FormResponse, User } from '../../../types/Interfaces'

import * as Validator from '../../../shared/Validations'

const UserCompleteInvitation = (props: RouteComponentProps) => {
  const { t } = useTranslation('user_complete_invitation_form')
  const [company] = useRecoilState(companyState)
  const [user] = useRecoilState(userState)
  const [attemptedSubmission, setAttemptedSubmission] = useState(false)
  const [submissionError, setSubmissionError] = useState<string | undefined>()

  useLayoutEffect(() => {
    if (user) props.history.push('/admin')
  }, [])

  return (
    <section css={PageStyles(company)}>
      <div css={ContainerStyles('small', company)}>
        <div css={MainStyles}>
          <Logo url={company?.logo} />
          <h4>{t('create_account')}</h4>
          <p>{t('create_account_details')}</p>

          <Formik
            initialValues={{
              password: '',
              phone: '',
              confirmation_token: qs.parse(props.location.search, {
                ignoreQueryPrefix: true,
              }).confirmation_token,
            }}
            validateOnChange={attemptedSubmission}
            validateOnBlur={attemptedSubmission}
            validate={(values) => {
              let error: string | undefined
              const errors: DeepPartial<User> = {}
              if ((error = Validator.password(values.password)))
                errors.password = error

              if ((error = Validator.phone(values.phone))) errors.phone = error

              return errors
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmissionError(undefined)
              try {
                const response: FormResponse = await AuthenticationService.confirmInvitation(
                  values,
                )

                if (response.success) {
                  window.location.href = '/admin'
                } else {
                  setSubmissionError(Validator.unexpectedError)
                }
              } catch {
                setSubmissionError(Validator.unexpectedError)
              }

              setSubmitting(false)
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <InputField type='hidden' label='' name='confirmation_token' />
                <InputField
                  type='password'
                  label={t('field:password')}
                  name='password'
                  autoComplete='new-password'
                />

                <InputField
                  type='text'
                  label={t('field:mobile')}
                  name='phone'
                  caption={t('you_will_receive_text_message')}
                />

                <div css={ActionBarStyles}>
                  <Button
                    type='submit'
                    value={t('action:submit')}
                    loading={isSubmitting}
                    onClick={() => setAttemptedSubmission(true)}
                  />
                </div>

                <InlineAlert
                  type='error'
                  show={!!submissionError}
                  message={submissionError}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </section>
  )
}

export default withRouter(UserCompleteInvitation)
