import { css } from '@emotion/core'

export const fonts = css`
  @font-face {
    font-family: 'Source Sans Variable';
    src: url('/fonts/source-sans-variable-roman-subset.woff2')
      format('woff2' supports variations);
    src: url('/fonts/source-sans-variable-roman-subset.woff2') format('woff2');
    font-weight: 200 900;
  }

  @font-face {
    font-family: 'GT Eesti Pro Display';
    src: url('/fonts/GTEestiProDisplay-Bold.eot');
    src: local('GT Eesti Pro Display Bold'), local('GTEestiProDisplay-Bold'),
      url('/fonts/GTEestiProDisplay-Bold.eot?#iefix')
        format('embedded-opentype'),
      url('/fonts/GTEestiProDisplay-Bold.woff2') format('woff2'),
      url('/fonts/GTEestiProDisplay-Bold.woff') format('woff'),
      url('/fonts/GTEestiProDisplay-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'GT Eesti Pro Display';
    src: url('/fonts/GTEestiProDisplay-MediumItalic.eot');
    src: local('GT Eesti Pro Display Medium Italic'),
      local('GTEestiProDisplay-MediumItalic'),
      url('/fonts/GTEestiProDisplay-MediumItalic.eot?#iefix')
        format('embedded-opentype'),
      url('/fonts/GTEestiProDisplay-MediumItalic.woff2') format('woff2'),
      url('/fonts/GTEestiProDisplay-MediumItalic.woff') format('woff'),
      url('/fonts/GTEestiProDisplay-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: 'GT Eesti Pro Display';
    src: url('/fonts/GTEestiProDisplay-Thin.eot');
    src: local('GT Eesti Pro Display Thin'), local('GTEestiProDisplay-Thin'),
      url('/fonts/GTEestiProDisplay-Thin.eot?#iefix')
        format('embedded-opentype'),
      url('/fonts/GTEestiProDisplay-Thin.woff2') format('woff2'),
      url('/fonts/GTEestiProDisplay-Thin.woff') format('woff'),
      url('/fonts/GTEestiProDisplay-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'GT Eesti Pro Display';
    src: url('/fonts/GTEestiProDisplay-Light.eot');
    src: local('GT Eesti Pro Display Light'), local('GTEestiProDisplay-Light'),
      url('/fonts/GTEestiProDisplay-Light.eot?#iefix')
        format('embedded-opentype'),
      url('/fonts/GTEestiProDisplay-Light.woff2') format('woff2'),
      url('/fonts/GTEestiProDisplay-Light.woff') format('woff'),
      url('/fonts/GTEestiProDisplay-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'GT Eesti Pro Display';
    src: url('/fonts/GTEestiProDisplay-UltraBold.eot');
    src: local('GT Eesti Pro Display UltraBold'),
      local('GTEestiProDisplay-UltraBold'),
      url('/fonts/GTEestiProDisplay-UltraBold.eot?#iefix')
        format('embedded-opentype'),
      url('/fonts/GTEestiProDisplay-UltraBold.woff2') format('woff2'),
      url('/fonts/GTEestiProDisplay-UltraBold.woff') format('woff'),
      url('/fonts/GTEestiProDisplay-UltraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'GT Eesti Pro Display';
    src: url('/fonts/GTEestiProDisplay-UltraBoldItalic.eot');
    src: local('GT Eesti Pro Display UltraBold Italic'),
      local('GTEestiProDisplay-UltraBoldItalic'),
      url('/fonts/GTEestiProDisplay-UltraBoldItalic.eot?#iefix')
        format('embedded-opentype'),
      url('/fonts/GTEestiProDisplay-UltraBoldItalic.woff2') format('woff2'),
      url('/fonts/GTEestiProDisplay-UltraBoldItalic.woff') format('woff'),
      url('/fonts/GTEestiProDisplay-UltraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
  }

  @font-face {
    font-family: 'GT Eesti Pro Display';
    src: url('/fonts/GTEestiProDisplay-Medium.eot');
    src: local('GT Eesti Pro Display Medium'), local('GTEestiProDisplay-Medium'),
      url('/fonts/GTEestiProDisplay-Medium.eot?#iefix')
        format('embedded-opentype'),
      url('/fonts/GTEestiProDisplay-Medium.woff2') format('woff2'),
      url('/fonts/GTEestiProDisplay-Medium.woff') format('woff'),
      url('/fonts/GTEestiProDisplay-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'GT Eesti Pro Display';
    src: url('/fonts/GTEestiProDisplay-UltraLight.eot');
    src: local('GT Eesti Pro Display UltraLight'),
      local('GTEestiProDisplay-UltraLight'),
      url('/fonts/GTEestiProDisplay-UltraLight.eot?#iefix')
        format('embedded-opentype'),
      url('/fonts/GTEestiProDisplay-UltraLight.woff2') format('woff2'),
      url('/fonts/GTEestiProDisplay-UltraLight.woff') format('woff'),
      url('/fonts/GTEestiProDisplay-UltraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: 'GT Eesti Pro Display';
    src: url('/fonts/GTEestiProDisplay-RegularItalic.eot');
    src: local('GT Eesti Pro Display Regular Italic'),
      local('GTEestiProDisplay-RegularItalic'),
      url('/fonts/GTEestiProDisplay-RegularItalic.eot?#iefix')
        format('embedded-opentype'),
      url('/fonts/GTEestiProDisplay-RegularItalic.woff2') format('woff2'),
      url('/fonts/GTEestiProDisplay-RegularItalic.woff') format('woff'),
      url('/fonts/GTEestiProDisplay-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: 'GT Eesti Pro Display';
    src: url('/fonts/GTEestiProDisplay-LightItalic.eot');
    src: local('GT Eesti Pro Display Light Italic'),
      local('GTEestiProDisplay-LightItalic'),
      url('/fonts/GTEestiProDisplay-LightItalic.eot?#iefix')
        format('embedded-opentype'),
      url('/fonts/GTEestiProDisplay-LightItalic.woff2') format('woff2'),
      url('/fonts/GTEestiProDisplay-LightItalic.woff') format('woff'),
      url('/fonts/GTEestiProDisplay-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: 'GT Eesti Pro Display';
    src: url('/fonts/GTEestiProDisplay-UltraLightItalic.eot');
    src: local('GT Eesti Pro Display UltraLight Italic'),
      local('GTEestiProDisplay-UltraLightItalic'),
      url('/fonts/GTEestiProDisplay-UltraLightItalic.eot?#iefix')
        format('embedded-opentype'),
      url('/fonts/GTEestiProDisplay-UltraLightItalic.woff2') format('woff2'),
      url('/fonts/GTEestiProDisplay-UltraLightItalic.woff') format('woff'),
      url('/fonts/GTEestiProDisplay-UltraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
  }

  @font-face {
    font-family: 'GT Eesti Pro Display';
    src: url('/fonts/GTEestiProDisplay-BoldItalic.eot');
    src: local('GT Eesti Pro Display Bold Italic'),
      local('GTEestiProDisplay-BoldItalic'),
      url('/fonts/GTEestiProDisplay-BoldItalic.eot?#iefix')
        format('embedded-opentype'),
      url('/fonts/GTEestiProDisplay-BoldItalic.woff2') format('woff2'),
      url('/fonts/GTEestiProDisplay-BoldItalic.woff') format('woff'),
      url('/fonts/GTEestiProDisplay-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
  }

  @font-face {
    font-family: 'GT Eesti Pro Display';
    src: url('/fonts/GTEestiProDisplay-Regular.eot');
    src: local('GT Eesti Pro Display Regular'),
      local('GTEestiProDisplay-Regular'),
      url('/fonts/GTEestiProDisplay-Regular.eot?#iefix')
        format('embedded-opentype'),
      url('/fonts/GTEestiProDisplay-Regular.woff2') format('woff2'),
      url('/fonts/GTEestiProDisplay-Regular.woff') format('woff'),
      url('/fonts/GTEestiProDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'GT Eesti Pro Display';
    src: url('/fonts/GTEestiProDisplay-ThinItalic.eot');
    src: local('GT Eesti Pro Display Thin Italic'),
      local('GTEestiProDisplay-ThinItalic'),
      url('/fonts/GTEestiProDisplay-ThinItalic.eot?#iefix')
        format('embedded-opentype'),
      url('/fonts/GTEestiProDisplay-ThinItalic.woff2') format('woff2'),
      url('/fonts/GTEestiProDisplay-ThinItalic.woff') format('woff'),
      url('/fonts/GTEestiProDisplay-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
  }
`
