import axios from 'axios'

import { Visitor } from '../types/Interfaces'

class VisitorService {
  async fetchAll(tenant_id?: number) {
    return axios
      .get(`/api/visitors`, { params: { tenant_id } })
      .then((response) => response.data)
  }

  async create(values: DeepPartial<Visitor>) {
    return axios
      .post(`/api/visitors`, { visitor: values })
      .then((response) => response.data)
  }

  async update(values: DeepPartial<Visitor>) {
    return axios
      .put(`/api/visitors/${values.id}`, {
        visitor: values,
      })
      .then((response) => response.data)
  }

  async delete(id: number) {
    return axios.delete(`/api/visitors/${id}`)
  }
}

export default new VisitorService()
