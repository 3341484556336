/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useState } from 'react'
import { Formik, Form } from 'formik'
import { useAlert } from 'react-alert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Tenant, FormResponse, Company } from '../../types/Interfaces'

import * as Validator from '../../shared/Validations'

import Theme from '../../styles/theme'
import { ActionBarStyles } from '../../styles/container-styles'
import { DeleteEntityStyles } from '../../styles/modal-styles'

import { useRecoilValue } from 'recoil'
import { companyState } from '../../Session'

import { Button, InlineAlert, InputField } from '../../components'
import TenantService from '../../services/TenantService'
import { useTranslation } from 'react-i18next'

interface Props {
  onTenantChange: (response: Company) => void
  tenantData?: Tenant
}

const TenantForm = (props: Props) => {
  const { t } = useTranslation('admin_settings')
  const [attemptedSubmission, setAttemptedSubmission] = useState(false)
  const [submissionError, setSubmissionError] = useState<string | undefined>()
  const company = useRecoilValue(companyState)
  const alert = useAlert()

  const newFormValues: DeepPartial<Tenant> = {
    name: '',
  }

  return (
    <section css={TenantModalStyles}>
      <div>
        <div>
          <Formik
            initialValues={props.tenantData || newFormValues}
            validateOnChange={attemptedSubmission}
            validateOnBlur={attemptedSubmission}
            validate={(values) => {
              let error: string | undefined
              const errors: DeepPartial<Tenant> = {}
              if ((error = Validator.emptyField(values.name)))
                errors.name = error

              return errors
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmissionError(undefined)
              try {
                const response: FormResponse = await (props.tenantData
                  ? TenantService.update(values)
                  : TenantService.create(values))
                if (response.success) {
                  alert.show(
                    props.tenantData ? t('tenant_updated') : t('tenant_added'),
                  )
                  props.onTenantChange(response.body as Company)
                } else {
                  setSubmissionError(Validator.formatErrors(response))
                  setSubmitting(false)
                }
              } catch {
                setSubmissionError(Validator.unexpectedError)
                setSubmitting(false)
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <h4>
                  {props.tenantData ? t('update_tenant') : t('add_tenant')}
                </h4>

                <InputField
                  type='text'
                  label={t('field:company_name')}
                  name='name'
                />

                <div css={ActionBarStyles}>
                  {props.tenantData && (
                    <div css={DeleteEntityStyles}>
                      <span
                        onClick={async () => {
                          if (confirm(t('tenant_delete_confirmation'))) {
                            try {
                              const response: FormResponse = await TenantService.delete(
                                props.tenantData as Tenant,
                              )
                              if (response.success) {
                                alert.show(t('tenant_deleted'))
                                props.onTenantChange(response.body as Company)
                              } else {
                                setSubmissionError(
                                  Validator.formatErrors(response),
                                )
                              }
                            } catch {
                              setSubmissionError(Validator.unexpectedError)
                            }
                          }
                        }}
                      >
                        <FontAwesomeIcon
                          color={Theme(company).colors.fontcolors.body}
                          className='trash'
                          icon={['fas', 'trash-alt']}
                        />
                        {t('action:delete')}
                      </span>
                    </div>
                  )}

                  <Button
                    type='submit'
                    value={
                      props.tenantData ? t('action:update') : t('action:add')
                    }
                    loading={isSubmitting}
                    onClick={() => setAttemptedSubmission(true)}
                  />
                </div>

                <InlineAlert
                  type='error'
                  show={!!submissionError}
                  message={submissionError}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </section>
  )
}

export default TenantForm

const TenantModalStyles = css`
  width: 90vw;
  max-width: 35rem;
`
