import i18n from '../translations'

import { FormResponse, Company } from '../types/Interfaces'

const mailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/i
const phoneRegex = /^(?:\+\d{1,3}|0\d{1,3}|00\d{1,2})?(?:\s?\(\d+\))?(?:[-\/\s.]|\d)+$/i
const colorRegex = /^#[0-9A-F]{6}$/i

const missing = i18n.t('error_messages:required_field')
const invalidEmail = i18n.t('error_messages:invalid_email')
const invalidColor = i18n.t('error_messages:invalid_color')

type Validator = (value: any) => string | undefined

export function validate<K extends string>(
  validators: Record<K, Validator>,
): (values: Record<K, any>) => Partial<Record<K, string>> {
  return (values) => {
    const errors: Partial<Record<K, string>> = {}

    const entries = Object.entries(validators) as [K, Validator][]
    for (const [key, validator] of entries) {
      const error = validator(values[key])
      if (error) errors[key] = error
    }

    return errors
  }
}

export const unexpectedError =
  'Oeps! Er is iets misgegaan. Probeer het opnieuw.'

export const email = (
  value: string | undefined,
  company?: Company,
): string | undefined => {
  if (!value) {
    return missing
  } else if (!mailRegex.test(value)) {
    return invalidEmail
  } else if (
    company?.domains &&
    !company.domains.find((domain) => value.includes(domain.name))
  ) {
    return `Gebruik één van deze domeinen: ${company.domains
      .map((domain) => domain.name)
      .join(', ')}`
  }
}

export const companySignupEmail = (
  value: string | undefined,
): string | undefined => {
  if (!value) {
    return missing
  } else if (!mailRegex.test(value)) {
    return invalidEmail
  }
}

export const visitorEmail = (value: string | undefined): string | undefined => {
  if (!value) {
    return missing
  } else if (!mailRegex.test(value)) {
    return invalidEmail
  }
}

export const password = (value: string | undefined): string | undefined => {
  if (!value) {
    return missing
  } else if (!passwordRegex.test(value)) {
    return i18n.t('error_messages:invalid_password_format')
  }
}

export const phone = (value: string | undefined): string | undefined => {
  if (!value) {
    return missing
  } else if (!phoneRegex.test(value) || value.length < 10) {
    return i18n.t('error_messages:invalid_phone')
  }
}

export const color = (value: string | undefined): string | undefined => {
  if (!value) {
    return missing
  } else if (!colorRegex.test(`#${value.replace('#', '')}`)) {
    return invalidColor
  }
}

export const emptyField = (value: string | undefined): string | undefined => {
  if (!value) return missing
}

export const firstName = (value: string | undefined): string | undefined => {
  if (!value) return missing
}

export const lastName = (value: string | undefined): string | undefined => {
  if (!value) return missing
}

export const userId = (
  value: string | number | undefined,
): string | undefined => {
  if (!value) return missing
}

export const accountType = (value: string | undefined): string | undefined => {
  if (!value) return missing
}

export const time = (value: string | undefined): string | undefined => {
  if (!value) return missing
}

export const healthCheck = (
  value: string | boolean | undefined,
): string | undefined => {
  if (!value || value === '') return missing
}

export const formatErrors = (formResponse: FormResponse) => {
  const errors: string[] = []

  for (const [field, error] of Object.entries(formResponse.errors)) {
    if (isNaN(parseInt(field))) {
      errors.push(`${field[0].toUpperCase() + field.slice(1)} ${error}`)
    } else {
      errors.push(`${error}`)
    }
  }

  return `Er is iets misgegaan. ${errors.join('. ')}.`
}
