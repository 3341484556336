import { useRecoilState } from 'recoil'
import { companyState } from './Session'

import React from 'react'

import { GlobalStyles } from './styles/global-styles'
import { Provider as AlertProvider } from 'react-alert'
import AlertTemplate from './components/AlertTemplate'

import App from './App'

const Wrapper = () => {
  const [company] = useRecoilState(companyState)

  return (
    <AlertProvider {...AlertTemplate}>
      <GlobalStyles company={company} />
      <App />
    </AlertProvider>
  )
}

export default Wrapper
