/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React, { useState, useLayoutEffect } from 'react'
import { jsx, css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import qs from 'qs'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { useLanguage } from '../hooks/language'

import { Button, Logo, TextareaField, InlineAlert } from '../components'

import * as Validator from '../shared/Validations'

import { useRecoilState } from 'recoil'
import { companyState } from '../Session'

import Theme from '../styles/theme'

import { Company, Tenant } from '../types/Interfaces'

import ReviewService from '../services/ReviewService'

const FeedbackForm = (props: RouteComponentProps) => {
  const [company] = useRecoilState(companyState)
  const { t } = useTranslation('feedback_form')
  const [submissionSucceeded, setSubmissionSucceeded] = useState(false)
  const [submissionError, setSubmissionError] = useState<string | undefined>()
  const [selected, setLanguage] = useLanguage()

  const reference =
    (qs.parse(props.location.search, {
      ignoreQueryPrefix: true,
    }).reference as string) || ''

  const score =
    (qs.parse(props.location.search, {
      ignoreQueryPrefix: true,
    }).score as string) || ''

  const language =
    (qs.parse(props.location.search, {
      ignoreQueryPrefix: true,
    }).language as string) || selected

  useLayoutEffect(() => {
    setLanguage(language as 'nl' | 'en')
  }, [])

  const scope = (): Company | Tenant | undefined => {
    const tenant_id = qs.parse(props.location.search, {
      ignoreQueryPrefix: true,
    }).tenant as string

    if (tenant_id) {
      return (
        company?.tenants.find((tenant) => tenant.id == parseInt(tenant_id)) ||
        company
      )
    } else {
      return company
    }
  }

  return (
    <section css={FeedbackFormStyles(company)}>
      <div>
        <Logo url={scope()?.logo} />

        <Formik
          initialValues={{
            remarks: '',
            score: score ? parseInt(score) : undefined,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(false)

            setSubmissionError(undefined)
            try {
              const response = await ReviewService.update(reference, values)

              if (response.success) {
                setSubmissionSucceeded(true)
              } else {
                setSubmissionError(Validator.formatErrors(response))
              }
            } catch (err) {
              setSubmissionError(Validator.unexpectedError)
            }
            setSubmitting(false)
          }}
        >
          {({ values, isSubmitting, setFieldValue }) => (
            <Form>
              {submissionSucceeded && (
                <>
                  <h1>{t('thank_you')}</h1>
                  <p className='introduction'>
                    {t('feedback_submitted_message')}
                  </p>
                </>
              )}
              {!submissionSucceeded && (
                <>
                  {score && (
                    <>
                      <h1>{t('thank_you')}</h1>
                      <p className='introduction'>{t('description')}</p>
                    </>
                  )}

                  {!score && (
                    <>
                      <h1>
                        {t('score_header').replace(
                          '%{location}',
                          scope()?.name || '',
                        )}
                      </h1>
                      <div css={ScoreStyles(company)}>
                        {[1, 2, 3, 4, 5].map((score) => (
                          <div
                            key={score}
                            onClick={() => setFieldValue('score', score)}
                            className={values.score == score ? 'active' : ''}
                          >
                            <div className='score'>{score}</div>
                            <div className='description'>
                              {t(`score_${score}`)}
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}

                  <h4>
                    {t('remarks_title')}
                    <span>({t('optional')})</span>
                  </h4>
                  <TextareaField
                    name='remarks'
                    placeholder={t('remarks_placeholder')}
                  />
                  <Button
                    type='submit'
                    value={t('submit_feedback')}
                    loading={isSubmitting}
                  />

                  {submissionError && (
                    <InlineAlert
                      type='error'
                      show={true}
                      message={submissionError}
                    />
                  )}
                </>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </section>
  )
}

export default withRouter(FeedbackForm)

const FeedbackFormStyles = (company?: Company) => css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${Theme(company).colors.backgrounds.inner};

  @media ${Theme(company).breakpoints.medium} {
    min-height: 100vh;
  }

  > div {
    max-width: 60rem;
    margin: 3rem;
    padding: 0.5rem;
    width: 100%;
    overflow: hidden;
  }

  figure.logo {
    max-width: 20rem;
    margin-bottom: 5rem;
  }

  h4 {
    margin: 3rem 0 0;

    span {
      margin-left: 1rem;
      font-size: 1.6rem;
      font-weight: 300;
      color: ${Theme(company).colors.fontcolors.primary};
    }
  }
`

const ScoreStyles = (company?: Company) => css`
  padding: 3rem;
  display: flex;
  justify-content: space-between;
  text-align: center;
  background-color: ${Theme(company).colors.backgrounds.outer};
  border-radius: 0.5rem;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .score {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 4rem;
      height: 4rem;
      font-weight: normal;
      background-color: ${Theme(company).colors.backgrounds.inner};
      color: ${Theme(company).colors.fontcolors.primary};
    }

    .description {
      margin-top: 1rem;
      font-size: 1.3rem;
    }

    &:hover,
    &.active {
      .score {
        color: ${Theme(company).colors.fontcolors.inverted};
        background-color: ${Theme(company).colors.fontcolors.primary};
      }
    }
  }
`
