/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/core'
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-router-dom'
import Modal from 'react-modal'

import Theme from '../styles/theme'
import { ModalStyles } from '../styles/modal-styles'
import { Logo, PageWidth, Portrait } from '../components'
import ChangeProfile from '../pages/authentication/user/ChangeProfile'

import { useRecoilState } from 'recoil'
import { userState, companyState } from '../Session'

import AuthenticationService from '../services/AuthenticationService'

import { FormResponse, Company } from '../types/Interfaces'
import { useTranslation } from 'react-i18next'

const Header = () => {
  const { t } = useTranslation('admin_header')
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [changeProfileModalOpen, setChangeProfileModalOpen] = useState(false)
  const [user, setUser] = useRecoilState(userState)
  const [company] = useRecoilState(companyState)

  return (
    <header css={headerStyles(company)}>
      <PageWidth wide={true}>
        <NavLink activeClassName='active' exact to='/admin/appointments'>
          <Logo />
        </NavLink>
        <div
          className='mobile-menu'
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <FontAwesomeIcon icon={['fas', 'bars']} />
        </div>
        <nav
          className={mobileMenuOpen ? 'mobile-menu-open' : 'mobile-menu-closed'}
        >
          <NavLink
            activeClassName='active'
            onClick={() => setMobileMenuOpen(false)}
            to='/admin/appointments'
            isActive={(_, location) =>
              ['/admin', '/admin/appointments'].includes(location.pathname)
            }
          >
            {t('appointments')}
          </NavLink>
          <NavLink
            activeClassName='active'
            onClick={() => setMobileMenuOpen(false)}
            exact
            to='/admin/visitors'
          >
            {t('visitors')}
          </NavLink>

          {['global', 'admin'].includes(user?.account_type || '') && (
            <>
              <NavLink
                activeClassName='active'
                onClick={() => setMobileMenuOpen(false)}
                exact
                to='/admin/users'
              >
                {t('users')}
              </NavLink>
              <NavLink
                activeClassName='active'
                onClick={() => setMobileMenuOpen(false)}
                exact
                to='/admin/reviews'
              >
                {t('reviews')}
              </NavLink>
            </>
          )}

          {user?.account_type == 'admin' && (
            <NavLink
              activeClassName='active'
              onClick={() => setMobileMenuOpen(false)}
              exact
              to='/admin/settings'
            >
              {t('settings')}
            </NavLink>
          )}
        </nav>

        <div className='login'>
          <Portrait image={user?.portrait} size='medium' />
          <div className='user-info'>
            <span className='name'>{`${user?.first_name} ${user?.last_name}`}</span>
            <span className='caption'>
              <span className='title'>
                {user ? t('account_type:' + user.account_type) : ''}
              </span>
              <span className='actions'>
                <a onClick={() => setChangeProfileModalOpen(true)}>
                  {t('change')}
                </a>{' '}
                /{' '}
                <a onClick={() => AuthenticationService.signOut()}>
                  {t('sign_out')}
                </a>
              </span>
              <Modal
                isOpen={changeProfileModalOpen}
                onRequestClose={() => setChangeProfileModalOpen(false)}
                ariaHideApp={false}
                style={ModalStyles(company)}
                parentSelector={() => document.body}
              >
                <ChangeProfile
                  onChange={(response: FormResponse) => {
                    setUser(response.user)
                    setChangeProfileModalOpen(false)
                  }}
                />
              </Modal>
            </span>
          </div>
        </div>
      </PageWidth>
    </header>
  )
}

export default Header

const headerStyles = (company?: Company) => css`
  position: relative;
  background: ${Theme(company).colors.backgrounds.inner};
  @media print {
    display: none;
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    @media ${Theme(company).breakpoints.medium} {
      padding: 0 3rem;
    }
  }

  figure.logo {
    margin: 2rem 2rem 2rem 0;
    img {
      width: 17rem;
      height: 3rem;
    }
  }

  .mobile-menu {
    display: block;
    font-size: 2.2rem;
    @media ${Theme(company).breakpoints.medium} {
      display: none;
    }
  }

  nav {
    @media ${Theme(company).breakpoints.mediumDown} {
      &.mobile-menu-open {
        display: block;
        box-shadow: 0 0.6rem 0.8rem #e4e4e4;
      }

      &.mobile-menu-closed {
        display: none;
      }
    }
    @media ${Theme(company).breakpoints.mediumDown} {
      position: absolute;
      width: 100%;
      left: 0;
      top: 100%;
      background: ${Theme(company).colors.backgrounds.inner};
      z-index: 10;
    }

    /* display: none; */
    align-self: stretch;
    @media ${Theme(company).breakpoints.medium} {
      display: flex;
    }

    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 1rem;
      font-size: 1.8rem;
      position: relative;
      height: 100%;
      color: ${Theme(company).colors.fontcolors.body};
      &.active {
        color: ${Theme(company).colors.fontcolors.primary};
        font-weight: normal;
        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 0.3rem;
          background: ${Theme(company).colors.fontcolors.primary};
        }
      }

      @media ${Theme(company).breakpoints.mediumDown} {
        padding: 2rem 0;
        margin: 0 2rem;
      }
    }
  }

  .login {
    position: relative;
    align-items: center;
    padding: 1.5rem 0;
    display: none;
    @media ${Theme(company).breakpoints.medium} {
      display: flex;
    }

    .user-info {
      min-width: 11rem;

      span {
        display: block;
        line-height: 1.8rem;
        &.name {
          font-size: 1.6rem;
          font-weight: 500;
        }
        &.caption {
          position: relative;
          font-size: 1.4rem;
          opacity: 0.7;

          span.title {
            text-transform: capitalize;
          }

          span.actions {
            position: absolute;
            top: 0;
            display: none;
          }
        }
      }
    }

    &:hover {
      .user-info span.caption {
        opacity: 1;

        span.title {
          visibility: hidden;
        }
        span.actions {
          display: block;
        }
      }
    }
  }
`
