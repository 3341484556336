/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import Dropzone from 'react-dropzone'
import { Trans } from 'react-i18next'
import { Tenant, Company } from '../types/Interfaces'
import { useTranslation } from 'react-i18next'
import Theme from '../styles/theme'
import { useRecoilState } from 'recoil'
import { companyState } from '../Session'
import { useAlert } from 'react-alert'

interface Props {
  company: Tenant | Company
  uploadLogo: (file: File[]) => Promise<void>
}

const LogoDropZone = (props: Props) => {
  const { t } = useTranslation('admin_settings')
  const [company] = useRecoilState(companyState)
  const alert = useAlert()

  return (
    <div css={DropZoneStyles(company)}>
      {props.company.hasOwnProperty('is_multi_tenant') ? (
        <h3>{t('corporate_identity')}</h3>
      ) : (
        <h4>{t('corporate_identity')}</h4>
      )}
      <p>{t('corporate_identity_details')}</p>
      <div>
        <Dropzone
          onDrop={props.uploadLogo}
          multiple={false}
          onDropAccepted={() => alert.show(t('settings_updated'))}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()} className='logo-input-field'>
                <input {...getInputProps()} />
                <div className='dropzone'>
                  <img src={props.company.logo} draggable={false} />
                  <p className='logo-text'>
                    {
                      <Trans
                        ns='admin_settings'
                        i18nKey='upload_logo_or_click_here'
                        components={{
                          s: <span />,
                        }}
                      />
                    }
                  </p>
                </div>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    </div>
  )
}

export default LogoDropZone

const DropZoneStyles = (company?: Company) => css`
  .logo-input-field:focus {
    outline: none;
  }

  .dropzone {
    height: 25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 4rem 3rem;
    border: 0.1rem dashed ${Theme(company).colors.borders.light};
    border-radius: 1rem;
    margin-bottom: 2.7rem;
    cursor: pointer;

    img {
      max-width: 30rem;
      max-height: 15rem;
      margin: 0 auto;
      margin-bottom: 1rem;
    }

    p.logo-text {
      font-size: 1.5rem;
      max-width: 18rem;
      line-height: 1.8rem;
      margin: 0 auto;
      font-weight: normal;

      span {
        color: ${Theme(company).colors.fontcolors.primary};
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`
