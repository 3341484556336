/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Theme from '../styles/theme'

import { useRecoilValue } from 'recoil'
import { companyState } from '../Session'
import { Company } from '../types/Interfaces'

interface Props {
  label: string
  value?: string
  onChange: (value: string) => void
}

// NOTE TODO: This should be combined with the styles for the regular <Input />
const Search = (props: Props) => {
  const company = useRecoilValue(companyState)

  return (
    <div
      css={inputStyles(company)}
      className={props.value ? 'filled' : 'empty'}
    >
      <label>{props.label}</label>
      <input
        type='text'
        placeholder={props.label}
        value={props.value || ''}
        onChange={(event) => props.onChange(event.target.value)}
      />
      <FontAwesomeIcon
        color={Theme(company).colors.borders.primary}
        icon={['fas', 'search']}
      />
    </div>
  )
}

export default Search

const inputStyles = (company?: Company) => css`
  position: relative;
  max-width: 40rem;

  svg {
    position: absolute;
    right: 0;
    top: 3rem;
  }

  input {
    margin: 1.8rem 0 2.3rem;
    font-size: 1.6rem;
    font-weight: 300;
    border: 0;
    color: ${Theme(company).colors.fontcolors.body};
    border-bottom: 0.1rem solid ${Theme(company).colors.borders.primary};
    border-width: 0.1rem;
    background: ${Theme(company).colors.backgrounds.input};
    width: 100%;
    height: 4.1rem;
    transition: 0.2s padding ease-in-out;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: #070707 !important;
    }

    &:hover,
    &:focus {
      outline: 0;
    }
    &::placeholder {
      color: ${Theme(company).colors.fontcolors.primary};
      font-weight: normal;
    }
  }

  label {
    position: absolute;
    opacity: 0;
    font-weight: normal;
    top: 1.2rem;
    font-size: 1.3rem;
    color: ${Theme(company).colors.fontcolors.primary};
    transition: opacity 0.3s ease-in-out;
  }

  &.empty {
    input {
      padding: 0.5rem 0;
    }
  }

  &.filled {
    label {
      opacity: 1;
    }

    input {
      padding: 1rem 0 0;
    }
  }
`
