/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useRecoilState } from 'recoil'
import { companyState } from '../Session'
import Tooltip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap.css'

import Theme from '../styles/theme'

import { Company } from '../types/Interfaces'

import { useTranslation } from 'react-i18next'

interface Props {
  score: number
  tooltip?: boolean
  remarks?: string
}

const Logo = (props: Props) => {
  const { t } = useTranslation('reviews')
  const [company] = useRecoilState(companyState)

  const score = () => (
    <div css={ReviewScoreStyles(company)}>
      {[1, 2, 3, 4, 5].map((score) => (
        <div
          key={score}
          className={props.score >= score ? 'filled' : 'empty'}
        />
      ))}
    </div>
  )

  if (props.tooltip) {
    return (
      <Tooltip
        placement='top'
        overlay={
          <div>
            {props.remarks ? (
              <span>{props.remarks}</span>
            ) : (
              <span className='empty'>{t('no_remarks')}</span>
            )}
          </div>
        }
        overlayInnerStyle={{ maxWidth: '25rem' }}
      >
        {score()}
      </Tooltip>
    )
  } else {
    return score()
  }
}

export default Logo

const ReviewScoreStyles = (company?: Company) => css`
  display: flex;
  justify-content: space-between;
  height: 2.3rem;
  max-width: 10rem;
  align-items: center;

  > div {
    border-radius: 50%;
    border: 0.1rem solid ${Theme(company).colors.fontcolors.primary};
    width: 1.4rem;
    height: 1.4rem;
    margin: 0 0.2rem;
    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
    &.filled {
      background-color: ${Theme(company).colors.fontcolors.primary};
    }
    &.empty {
      filter: grayscale();
      opacity: 0.4;
    }
  }
`
