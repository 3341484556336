/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useState } from 'react'
import { Formik, Form } from 'formik'
import { CSSTransition } from 'react-transition-group'
import qs, { ParsedQs } from 'qs'

import { useRecoilValue } from 'recoil'
import { companyState } from '../../../Session'

import { Visitor, Company } from '../../../types/Interfaces'

import * as Validator from '../../../shared/Validations'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Button, InputField } from '../../../components'
import VisitorRegistrationService from '../../../services/VisitorRegistrationService'

import Theme from '../../../styles/theme'
import { useTranslation } from 'react-i18next'

interface Props {
  setAppointments: (visitorEmail: string) => void
  visitor?: Visitor
}

const newUserFormValues = {
  email: '',
  first_name: '',
  last_name: '',
  phone: '',
}

function urlContent(): ParsedQs {
  return qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  })
}

const Step1 = (props: Props) => {
  const [showFullForm, _setShowFullForm] = useState(false)
  const [transitioning, setTransitioning] = useState(false)
  const [visitorEmail, setVisitorEmail] = useState('')
  const company = useRecoilValue(companyState)
  const [phoneNumber, setPhoneNumber] = useState('')

  const setShowFullForm = (value: boolean) => {
    setTransitioning(true)
    _setShowFullForm(value)
  }

  const { t } = useTranslation('visitor_form')

  // Had to split it into 2 different forms because Formik wasnt playing
  // nicely with dynamic fields -- probably need to abstract them

  return (
    <div>
      <div css={CurrentStepStyles(company)}>
        <h2>{t('your_details')}</h2>

        <CSSTransition
          in={!transitioning && !showFullForm}
          timeout={300}
          unmountOnExit
          classNames='step'
          onExited={() => setTransitioning(false)}
        >
          <Formik
            initialValues={
              props.visitor || { email: (urlContent().email as string) || '' }
            }
            validate={Validator.validate({ email: Validator.visitorEmail })}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={async (values, { setSubmitting }) => {
              const response = await VisitorRegistrationService.fetchVisitorDetails(
                values.email,
              )
              setSubmitting(false)

              if (response.success && !response.missing.length) {
                props.setAppointments(values.email)
              } else {
                setShowFullForm(true)
                setVisitorEmail(values.email)
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div css={StepContainerStyles(company)}>
                  <InputField
                    type='text'
                    label={t('field:email')}
                    name='email'
                  />
                </div>
                <div css={ButtonStyles(company)}>
                  <Button
                    type='submit'
                    size='large'
                    value=''
                    loading={isSubmitting}
                  >
                    <div>
                      {t('continue')}
                      <FontAwesomeIcon icon={['far', 'angle-right']} />
                    </div>
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </CSSTransition>

        <CSSTransition
          in={!transitioning && showFullForm}
          timeout={300}
          unmountOnExit
          classNames='step'
        >
          <Formik
            initialValues={
              props.visitor ||
              Object.assign(newUserFormValues, {
                email: visitorEmail || urlContent().email,
                first_name: urlContent().first_name || '',
                last_name: urlContent().last_name || '',
                phone: urlContent().phone || phoneNumber,
              })
            }
            validate={Validator.validate({
              email: Validator.visitorEmail,
              phone: Validator.phone,
              first_name: Validator.firstName,
              last_name: Validator.lastName,
            })}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={async (values, { setSubmitting }) => {
              setPhoneNumber(values.phone)
              const response = await VisitorRegistrationService.register(values)
              props.setAppointments(response.email)
              setSubmitting(false)
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div css={StepContainerStyles(company)}>
                  <div css={FormStepsStyles(company)}>
                    <InputField
                      type='text'
                      label={t('field:email')}
                      name='email'
                      disabled={true}
                    />
                    <InputField
                      type='tel'
                      label={t('field:mobile')}
                      name='phone'
                    />
                    <InputField
                      type='text'
                      label={t('field:first_name')}
                      name='first_name'
                    />
                    <InputField
                      type='text'
                      label={t('field:last_name')}
                      name='last_name'
                    />
                  </div>
                </div>
                <div css={ButtonStyles(company)}>
                  <Button
                    type='submit'
                    size='large'
                    value=''
                    loading={isSubmitting}
                  >
                    <div>
                      {t('continue')}
                      <FontAwesomeIcon icon={['far', 'angle-right']} />
                    </div>
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </CSSTransition>
      </div>
    </div>
  )
}

export default Step1

const StepContainerStyles = (company?: Company) => css`
  margin-bottom: 4rem;
  background-color: ${Theme(company).colors.backgrounds.inner};
  @media ${Theme(company).breakpoints.medium} {
    margin-bottom: 11.5rem;
  }
`

const FormStepsStyles = (company?: Company) => css`
  @media ${Theme(company).breakpoints.medium} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > div {
      max-width: 48%;
      width: 100%;
    }
  }
`

const ButtonStyles = (company?: Company) => css`
  > div {
    @media ${Theme(company).breakpoints.mediumDown} {
      justify-content: center;
    }

    button {
      min-width: 25rem;
      @media ${Theme(company).breakpoints.mediumDown} {
        width: 100%;
        min-width: auto;
        padding: 2.3rem 4.5rem;
      }

      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
`
const CurrentStepStyles = (company?: Company) => css`
  color: #807c79;
  font-size: 2rem;
  line-height: 2.4rem;
  margin-bottom: 5.1rem;
  margin-top: 1.6rem;
  @media ${Theme(company).breakpoints.medium} {
    margin-top: 21.2rem;
  }
`
