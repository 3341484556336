export const convertToFormdata = (
  json: Record<string, any>,
  wrapperName?: string,
) => {
  const data = new FormData()
  const wrapped = (name: string) =>
    wrapperName ? `${wrapperName}[${name}]` : name

  for (const [name, value] of Object.entries(json)) {
    if (value instanceof File) {
      data.append(wrapped(name), new Blob([value]), value.name)
    } else if (value instanceof Object) {
      data.append(wrapped(name), JSON.stringify(value))
    } else if (value !== undefined) {
      data.append(wrapped(name), value)
    }
  }

  return data
}
