/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useState } from 'react'
import { Formik, Form } from 'formik'
import { useAlert } from 'react-alert'

import { User, FormResponse } from '../../../types/Interfaces'

import * as Validator from '../../../shared/Validations'

import { ActionBarStyles } from '../../../styles/container-styles'
import UploadPhoto from './UploadPhoto'
import CropProfilePic from './CropProfilePic'

import {
  Button,
  InlineAlert,
  InputField,
  InputGroup,
} from '../../../components'
import UserService from '../../../services/UserService'

import { useRecoilState } from 'recoil'
import { userState } from '../../../Session'
import { useTranslation } from 'react-i18next'

interface Props {
  onChange: (response: FormResponse) => void
}

const ChangeProfile = (props: Props) => {
  const { t } = useTranslation('change_profile_form')
  const [user] = useRecoilState(userState)
  const [cropPhoto, updateCropPhoto] = useState(false)
  const [attemptedSubmission, setAttemptedSubmission] = useState(false)
  const [submissionError, setSubmissionError] = useState<string | undefined>()
  const alert = useAlert()
  const [image, setImage] = useState<File>()

  const newFormValues: DeepPartial<User> = {
    first_name: user?.first_name,
    last_name: user?.last_name,
    phone: user?.phone,
    id: user?.id,
  }

  const triggerCropPhoto = () => {
    updateCropPhoto(!cropPhoto)
  }

  return (
    <section>
      <div css={ChangeProfileStyles}>
        {cropPhoto ? (
          <CropProfilePic image={image} triggerCropPhoto={triggerCropPhoto} />
        ) : (
          <div>
            <UploadPhoto
              triggerCropPhoto={triggerCropPhoto}
              setImage={setImage}
            />
            <h4>{t('update_profile')}</h4>

            <Formik
              initialValues={newFormValues}
              validateOnChange={attemptedSubmission}
              validateOnBlur={attemptedSubmission}
              validate={(values) => {
                let error: string | undefined
                const errors: DeepPartial<User> = {}
                if ((error = Validator.firstName(values.first_name)))
                  errors.first_name = error

                if ((error = Validator.lastName(values.last_name)))
                  errors.last_name = error

                if ((error = Validator.phone(values.phone)))
                  errors.phone = error

                return errors
              }}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmissionError(undefined)
                try {
                  const response: FormResponse = await UserService.update(
                    values,
                  )
                  alert.show(t('profile_updated'))
                  props.onChange(response)
                } catch {
                  setSubmissionError(Validator.unexpectedError)
                  setSubmitting(false)
                }
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <InputGroup>
                    <InputField
                      type='text'
                      label={t('field:first_name')}
                      name='first_name'
                    />
                    <InputField
                      type='text'
                      label={t('field:last_name')}
                      name='last_name'
                    />
                  </InputGroup>

                  <InputField
                    type='text'
                    label={t('field:mobile')}
                    name='phone'
                  />

                  <div css={ActionBarStyles}>
                    <Button
                      type='submit'
                      value={t('action:update')}
                      loading={isSubmitting}
                      onClick={() => setAttemptedSubmission(true)}
                    />
                  </div>

                  <InlineAlert
                    type='error'
                    show={!!submissionError}
                    message={submissionError}
                  />
                </Form>
              )}
            </Formik>
          </div>
        )}
      </div>
    </section>
  )
}

const ChangeProfileStyles = css`
  padding-top: 4.5rem;
  min-width: 48.9rem;
  text-align: center;
`

export default ChangeProfile
