import axios from 'axios'
import { User } from '../types/Interfaces'
import { convertToFormdata } from '../shared/FormDataConverter'

class UserService {
  async fetchAll(tenant_id?: number) {
    return axios
      .get(`/api/users`, { params: { tenant_id } })
      .then((response) => response.data)
  }

  async delete(user: User) {
    return axios
      .delete(`/api/users/${user.id}`)
      .then((response) => response.data)
  }

  async update(values: DeepPartial<User>) {
    return axios
      .patch(`/api/users/${values.id}`, { user: values })
      .then((response) => response.data)
  }

  async updateProfilePic(file: File, user: User) {
    return axios
      .patch(
        `/api/users/${user.id}`,
        convertToFormdata({ portrait: file }, 'user'),
      )
      .then((response) => response.data)
  }
}

export default new UserService()
