/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useField } from 'formik'
import Theme from '../../styles/theme'
import React, { useEffect } from 'react'

import HealthCheckIcon from '../HealthCheckIcon'
import FieldCaptionOrError from './FieldCaptionOrError'
import FieldLabel from './FieldLabel'

import { useRecoilValue } from 'recoil'
import { companyState } from '../../Session'

import { Company } from '../../types/Interfaces'

interface Props {
  caption?: string | JSX.Element
  children: React.ReactElement
  defaultValue?: any
  disabled?: boolean
  label?: string
  name: string
  size?: string
  withIcons?: boolean
  customOnChange?: (value: string | number) => void
}

const SelectFieldWrapper = (props: Props) => {
  const company = useRecoilValue(companyState)
  const [field, meta, helpers] = useField(props.name)
  const { setValue } = helpers

  const handleChange = (selectedOption: any) => {
    // Sets the related Formik Field's value in react-select's onChange handler. They don't play nice with each other without help.
    // Related issue: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/32553
    if (selectedOption != undefined) {
      setValue(selectedOption.value)
      props.customOnChange && props.customOnChange(selectedOption.value)
    }
  }

  const childWithChangeHandler = () => {
    const addedProps = {
      onChange: handleChange,
      ...props,
    }
    return React.cloneElement(props.children, addedProps)
  }

  useEffect(() => {
    setValue(props.defaultValue)
  }, [])

  return (
    <div
      css={InputStyles(props, company)}
      // Have to use classes, conditional Styles cause issues
      className={`
        ${field.value || field.value === false ? 'filled' : 'empty'}
        ${props.caption ? 'has-caption' : ''}
        ${meta.error ? 'has-error' : ''}
      `}
    >
      <FieldLabel>{props.label}</FieldLabel>

      {childWithChangeHandler()}

      <FieldCaptionOrError caption={props.caption} error={meta.error} />

      {props.withIcons && (
        <div className='select-icon'>
          <HealthCheckIcon healthy={field.value} />
        </div>
      )}
    </div>
  )
}

export default SelectFieldWrapper

const InputStyles = (props: Props, company?: Company) => css`
  position: relative;
  padding-top: 0.1rem; // TODO: Figure out why setting this to 0 messes with label positioning.

  label {
    position: absolute;
    top: 1.3rem;
    opacity: 0;
  }

  .select-icon {
    position: absolute;
    user-select: none;
    right: 0.5rem;
    top: 3.5rem;
    font-size: 2rem;
    background-color: ${Theme(company).colors.backgrounds.inner};
  }

  &.empty {
    select {
      padding: 0.5rem 0;
      color: ${Theme(company).colors.fontcolors.primary};
    }
  }

  &.filled {
    label {
      opacity: 1;
    }

    select {
      padding: 1rem 0 0;
    }
  }

  &.has-caption,
  &.has-error {
    select {
      margin: 1.8rem 0 0.3rem;
    }
  }
  &.has-error {
    select {
      border-bottom-color: ${Theme(company).colors.borders.error};
      &::placeholder {
        color: ${Theme(company).colors.fontcolors.error};
      }
    }

    label {
      color: ${Theme(company).colors.fontcolors.error};
    }
  }

  select {
    &:disabled {
      color: ${Theme(company).colors.fontcolors.disabled};
      border-bottom-color: ${Theme(company).colors.fontcolors.disabled};
    }
  }
`
