/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Global } from '@emotion/core'
import emotionNormalize from 'emotion-normalize'
import emotionReset from 'emotion-reset'
import Theme from './theme'

import { fontawesome } from './fontawesome'
import { fonts } from './fonts'

import { Company } from '../types/Interfaces'

export const GlobalStyles = (props: { company?: Company }) => {
  return (
    <Global
      styles={css`
        ${emotionReset}
        ${emotionNormalize}
        ${fontawesome}
        ${fonts}

        html {
          font-size: 10px;
          line-height: 1.6rem;
          box-sizing: border-box;
          height: 100%;
        }

        *,
        *:before,
        *:after {
          box-sizing: inherit;
        }

        body {
          overflow-x: hidden;
          overflow-y: scroll;
          display: flex;
          flex-direction: column;
          min-height: 100%;
          color: ${Theme(props.company).colors.fontcolors.body};
          font-family: ${Theme(props.company).fonts.body};
          font-size: 1.6rem;
          line-height: 2.6rem;
          font-weight: 300;
          background: ${Theme(props.company).colors.backgrounds.outer};
          @media ${Theme(props.company).breakpoints.desktop} {
            font-size: 1.6rem;
            line-height: 2.3rem;
          }
          @media ${Theme(props.company).breakpoints.mediumDown} {
            background: ${Theme(props.company).colors.backgrounds.inner};
          }
          @media print {
            background: white;
          }

          > .root {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 100%;
          }
        }

        h1,
        h2,
        h3,
        h4 {
          margin: 0;
          font-family: ${Theme(props.company).fonts.body};
          color: ${Theme(props.company).colors.fontcolors.heading};
        }

        h1 {
          margin: 0 0 2rem;
          font-size: 3.8rem;
          line-height: 4.5rem;
          font-weight: normal;
          letter-spacing: -0.05rem;
        }

        h2 {
          margin: 0 0 2.5rem;
          font-size: 2.4rem;
          line-height: 3.5rem;
          font-weight: 300;
        }

        h3 {
          margin: 1rem 0 1.5rem;
          font-size: 2rem;
          line-height: 2.8rem;
          font-weight: normal;
        }

        h4 {
          margin: 1rem 0 2rem;
          font-size: 2rem;
          line-height: 2.2rem;
          font-weight: normal;
          letter-spacing: 0.1;
        }

        h5 {
          margin: 0 0 1rem;
          font-size: 1.8rem;
          line-height: 2.4rem;
          font-weight: normal;
          color: ${Theme(props.company).colors.fontcolors.heading};
          @media ${Theme(props.company).breakpoints.desktop} {
            font-size: 1.7rem;
            line-height: 2.2rem;
            font-weight: 500;
          }
        }

        p {
          margin: 0;
          margin: 0 0 1.5rem;
          font-size: 1.6rem;
          font-family: ${Theme(props.company).fonts.body};
          & + ul {
            margin-top: -1rem;
          }
        }

        em,
        i {
          font-style: italic;
        }

        strong,
        b {
          font-weight: 600;
        }

        a {
          color: ${Theme(props.company).colors.fontcolors.primary};
          text-decoration: none;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }

        ul,
        ol {
          margin-bottom: 1.5rem;

          li {
            margin-bottom: 0.5rem;
          }
        }

        figure {
          margin: 0;
        }

        img {
          display: block;
        }

        img[src=''],
        img:not([src]) {
          opacity: 0;
        }
      `}
    />
  )
}

export const CheckboxStyles = css`
  > label {
    display: flex;
    align-items: center;
    margin-bottom: 0.7rem;
    font-weight: normal;

    input,
    svg {
      margin-right: 2rem;
    }
  }
`

export const EmptyTableStyles = css`
  text-align: center;
  display: block;
  margin-top: 12rem;
  font-weight: normal;

  h4 {
    margin: 1rem 0;
  }
`
