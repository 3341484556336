import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import messages from './messages'
import { supportedLanguages } from './hooks/language'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: messages,
    fallbackLng: 'nl',
    debug: true,
    supportedLngs: [...supportedLanguages],
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
