/* eslint-disable react/jsx-key */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, css } from '@emotion/core'
import React, { useEffect, useMemo, useState } from 'react'
import useStateSafe from '../../helpers/use-state-safe'
import Theme from '../../styles/theme'
import { useTranslation } from 'react-i18next'
import { Row } from 'react-table'
import moment from 'moment'

import {
  PageWidth,
  InlineAlert,
  Spinner,
  Table,
  ReviewScore,
} from '../../components'
import ReviewService from '../../services/ReviewService'
import { Review, Company } from '../../types/Interfaces'

import { useRecoilState } from 'recoil'
import { companyState } from '../../Session'

import * as Validator from '../../shared/Validations'

import { useLanguage } from '../../hooks/language'

import { EmptyTableStyles } from '../../styles/global-styles'

const AdminReviews = (): JSX.Element => {
  const { t } = useTranslation('reviews')
  const [language] = useLanguage()
  const [reviews, setReviews] = useStateSafe<Review[]>([])
  const [loaded, setLoaded] = useStateSafe(false)
  const [error, setError] = useStateSafe(false)
  const [company] = useRecoilState(companyState)
  const [showLastThirtyDays, setLastThirtyDays] = useState(false)

  moment.locale(language)

  useEffect(() => {
    fetchReviews()
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: t('field:name'),
        accessor: 'full_name',
        // NOTE: Sort by last name, but it at the moment it will sort
        // first on prefixes like 'de', 'van', etc.
        sortType: (
          { original: a }: Row<Review>,
          { original: b }: Row<Review>,
        ) => {
          return a.last_name.localeCompare(b.last_name, language)
        },
        Cell({ row: { original: row } }: { row: Row<Review> }) {
          return (
            <>
              <div css={row.visitor_removed ? RemovedVisitorStyles : {}}>
                {row.full_name}
              </div>
              <div css={InfoStyles}>
                {t('review_appointment_info')
                  .replace('%{user}', row.user_name)
                  .replace(
                    '%{date}',
                    moment(row.appointment_at).format('D MMMM YYYY'),
                  )}
              </div>
            </>
          )
        },
      } as const,
      {
        Header: t('field:score'),
        accessor: 'score',
        Cell({ row: { original: row } }: { row: Row<Review> }) {
          return (
            <>
              <ReviewScore score={row.score} />
              {row.remarks && row.remarks.length && (
                <div css={RemarksStyles}>{row.remarks}</div>
              )}
            </>
          )
        },
      } as const,
      {
        Header: t('field:responded_at'),
        accessor: 'responded_at',
        sortType: (
          { original: a }: Row<Review>,
          { original: b }: Row<Review>,
        ) => {
          return moment(b.responded_at).unix() - moment(a.responded_at).unix()
        },
        Cell({ value }: { row: Row<Review>; value: string }) {
          return moment(value).format('D MMMM YYYY')
        },
      } as const,
    ],
    [language],
  )

  const filteredReviews = (): Review[] => {
    if (showLastThirtyDays) {
      return reviews.filter(
        (review) => moment(review.responded_at) > moment().subtract(30, 'days'),
      )
    } else {
      return reviews
    }
  }

  async function fetchReviews() {
    const startTime = Date.now()
    setError(false)

    try {
      const response = await ReviewService.fetchAll()

      setTimeout(() => {
        setReviews(response.reviews)
        setLoaded(true)
      }, Math.max(500 - (Date.now() - startTime), 0))
    } catch {
      setError(true)
      setLoaded(true)
    }
  }

  function averageScore() {
    const average =
      filteredReviews().reduce((total, next) => total + next.score, 0) /
      filteredReviews().length
    if (isNaN(average)) {
      return '-'
    } else {
      return average.toPrecision(2)
    }
  }

  const BaseContent = (
    props: React.PropsWithChildren<unknown>,
  ): JSX.Element => {
    return (
      <section css={DashboardStyles(company)}>
        <PageWidth>
          <header>
            <div>
              <h1>{t('reviews')}</h1>
              <div css={FilterStyles(company)}>
                <a
                  className={showLastThirtyDays ? '' : 'active'}
                  onClick={() => setLastThirtyDays(false)}
                >
                  {t('show_all_reviews')}
                </a>{' '}
                /{' '}
                <a
                  className={showLastThirtyDays ? 'active' : ''}
                  onClick={() => setLastThirtyDays(true)}
                >
                  {t('show_last_thirty_days')}
                </a>
              </div>
            </div>
            {loaded && filteredReviews().length > 0 && (
              <div css={AverageScoreBlockStyles(company)}>
                <div className='title'>{t('average_score')}</div>
                <div className='score'>{averageScore()}</div>
                <div className='total'>
                  {t('number_of_reviews').replace(
                    '%{amount}',
                    filteredReviews().length.toString(),
                  )}
                </div>
              </div>
            )}
          </header>
          {props.children}
        </PageWidth>
      </section>
    )
  }

  if (!loaded) {
    return (
      <BaseContent>
        <Spinner size='large' />
      </BaseContent>
    )
  } else if (error) {
    return (
      <BaseContent>
        <InlineAlert
          type='error'
          show={true}
          message={Validator.unexpectedError}
        />
      </BaseContent>
    )
  } else if (!filteredReviews().length) {
    return (
      <BaseContent>
        <div css={EmptyTableStyles}>
          <h4>{t('no_reviews')}</h4>
          <span>{t('no_reviews_description')}</span>
        </div>
      </BaseContent>
    )
  } else {
    return (
      <BaseContent>
        <Table
          rows={filteredReviews()}
          sortBy={[{ id: 'responded_at' }]}
          columns={columns}
          hiddenColumns={[]}
          search={false}
          grid='3.5fr 10fr 3fr'
        />
      </BaseContent>
    )
  }
}

export default AdminReviews

const DashboardStyles = (company?: Company) => css`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  header {
    display: flex;
    justify-content: space-between;
    align-items: top;
    margin-bottom: 1rem;

    @media ${Theme(company).breakpoints.mobileDown} {
      flex-direction: column;
      margin-bottom: 4rem;
    }

    h1 {
      margin: 0;
      @media ${Theme(company).breakpoints.mediumDown} {
        margin-bottom: 3rem;
      }
    }

    button {
      font-size: 1.6rem;
    }
  }
`

const RemovedVisitorStyles = css`
  opacity: 0.5;
  font-style: italic;
`

const AverageScoreBlockStyles = (company?: Company) => css`
  padding: 2rem 2rem 1.5rem;
  border-radius: 0.5rem;
  background-color: ${Theme(company).colors.fontcolors.primary};
  color: ${Theme(company).colors.fontcolors.inverted};
  font-weight: normal;

  .score {
    margin: 1rem 0;
    font-size: 3.8rem;
    font-weight: normal;
  }

  .total {
    font-size: 1.4rem;
  }
`

const InfoStyles = css`
  opacity: 0.7;
  font-weight: 300;
  font-size: 1.3rem;
  white-space: normal;
  line-height: 1.9rem;
  margin-top: 1rem;
`

const RemarksStyles = css`
  margin-top: 0.9rem;
  white-space: pre-wrap;
  opacity: 0.7;
`

const FilterStyles = (company?: Company) => css`
  margin-top: 2rem;

  a {
    color: ${Theme(company).colors.fontcolors.body};
    opacity: 0.5;
    font-weight: 400;
    &.active,
    &:hover {
      color: ${Theme(company).colors.fontcolors.primary};
      opacity: 1;
      text-decoration: none;
    }
  }
`
