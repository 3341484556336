/** @jsx jsx */
import { css } from '@emotion/core'
import Theme from './theme'

import { Company } from '../types/Interfaces'

export const CalendarStyles = (company?: Company) => css`
  margin-bottom: 2rem;

  .react-calendar {
    margin: 0 auto;
    z-index: 1;
    font-family: ${Theme(company).fonts.body};
    font-size: 1.6rem;
    border: 0;
    background: ${Theme(company).colors.backgrounds.inner};

    .react-calendar__navigation button {
      font-size: 2rem;
      color: ${Theme(company).colors.fontcolors.body};
      &.react-calendar__navigation__label {
        user-select: none;
        pointer-events: none;

        .react-calendar__navigation__label__labelText {
          font-size: 1.6rem;
        }
      }
      &:disabled {
        background: none;
        color: ${Theme(company).colors.fontcolors.disabled};
      }
      &:not(:disabled):hover,
      &:focus {
        background-color: ${Theme(company).colors.backgrounds.inner};
      }
    }

    .react-calendar__month-view__weekdays__weekday {
      color: ${Theme(company).colors.fontcolors.gray};
      abbr {
        text-transform: lowercase;
        font-size: 1.6rem;
        text-decoration: none;
        font-weight: 375;
      }
    }

    .react-calendar__tile {
      position: relative;
      color: ${Theme(company).colors.fontcolors.body};
      overflow: visible !important;
      &:disabled {
        background: none;
        color: ${Theme(company).colors.fontcolors.disabled} !important;
        &:after {
          background: none !important;
        }
      }

      abbr {
        position: relative;
        z-index: 2;
        transition: color 150ms ease-in-out;
      }

      &.react-calendar__month-view__days__day--weekend {
        color: ${Theme(company).colors.fontcolors.body};
      }
      &.react-calendar__month-view__days__day--neighboringMonth {
        color: ${Theme(company).colors.fontcolors.gray};
      }

      &.react-calendar__month-view__days__day.react-calendar__tile--active,
      &.react-calendar__month-view__days__day.react-calendar__tile--now,
      &:hover {
        position: relative;
        font-weight: 700;
        background: none;
        transition: background 150ms ease-in-out;
        &:after {
          content: '';
          position: absolute;
          z-index: 1;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: block;
          width: 3.1rem;
          height: 3.1rem;
          border-radius: 50%;
          transition: all 150ms ease-in-out;
        }
      }
      &.react-calendar__month-view__days__day:hover,
      &.react-calendar__month-view__days__day.react-calendar__tile--now {
        color: ${Theme(company).colors.fontcolors.body};
        &:after {
          background: ${Theme(company).colors.backgrounds.inner};
        }
      }

      &.react-calendar__month-view__days__day.react-calendar__tile--active,
      &.react-calendar__month-view__days__day.react-calendar__tile--active:hover {
        &:after {
          background: ${Theme(company).colors.fontcolors.primary};
          box-shadow: 0 0.4rem 0.4rem 0 rgba(0, 0, 0, 0.13);
        }

        abbr {
          color: rgb(221 221 221);
        }
      }
    }
  }

  &.popup-calendar {
    .react-calendar {
      .react-calendar__tile {
        &.react-calendar__month-view__days__day:hover,
        &.react-calendar__month-view__days__day.react-calendar__tile--now {
          &:after {
            background: ${Theme(company).colors.backgrounds.outer};
          }
        }

        &.react-calendar__month-view__days__day.react-calendar__tile--active,
        &.react-calendar__month-view__days__day.react-calendar__tile--active:hover {
          &:after {
            background: ${Theme(company).colors.fontcolors.primary};
          }
        }
      }
    }
  }
`
