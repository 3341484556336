/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'
import { Field, FieldProps } from 'formik'
import Theme from '../../styles/theme'

import FieldCaptionOrError from './FieldCaptionOrError'
import FieldLabel from './FieldLabel'

import { useRecoilValue } from 'recoil'
import { companyState } from '../../Session'
import { Company } from '../../types/Interfaces'

interface Props {
  label: string
  name: string
  type: string
  caption?: string | JSX.Element
  disabled?: boolean
  // Go ahead and add more, see:
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
  autoComplete?: 'new-password' | 'current-password'
  onChange?: any
}

const InputField = (props: Props) => {
  const company = useRecoilValue(companyState)
  const customOnChange = {
    ...(props.onChange && { onChange: props.onChange }),
  }

  return (
    <Field name={props.name} {...customOnChange}>
      {({ field, meta }: FieldProps) => (
        <div
          css={
            props.type == 'hidden'
              ? HiddenInputStyles
              : InputStyles(props, company)
          }
          // Have to use classes, conditional Styles causes issues
          className={`
            ${field.value || props.type == 'time' ? 'filled' : 'empty'}
            ${props.caption ? 'has-caption' : ''}
            ${meta.touched && meta.error ? 'has-error' : ''}
            ${props.type}
          `}
        >
          <FieldLabel>{props.label}</FieldLabel>
          <input
            type={props.type}
            autoComplete={props.autoComplete}
            {...field}
            placeholder={props.type == 'time' ? '' : props.label}
            disabled={!!props.disabled}
          />
          <FieldCaptionOrError caption={props.caption} error={meta.error} />
        </div>
      )}
    </Field>
  )
}

export default InputField

const InputStyles = (props: Props, company?: Company) => css`
  position: relative;

  input {
    margin: 1.8rem 0 0.3rem;
    font-size: 1.6rem;
    font-weight: 300;
    color: ${Theme(company).colors.fontcolors.body};
    border: 0;
    border-bottom: 0.1rem solid ${Theme(company).colors.borders.primary};
    border-width: 0.1rem;
    background: ${Theme(company).colors.backgrounds.input};
    width: 100%;
    height: 4.1rem;
    transition: 0.2s padding ease-in-out;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: #070707 !important;
    }

    &:hover,
    &:focus {
      outline: 0;
    }
    &::placeholder {
      color: ${Theme(company).colors.fontcolors.primary};
      font-weight: normal;
    }

    &[type='time'] {
      width: 8rem;
    }
  }

  label {
    position: absolute;
    opacity: 0;
  }

  &.empty {
    input {
      padding: 0.5rem 0;
    }
  }

  &.filled {
    label {
      opacity: 1;
    }

    input {
      padding: 1rem 0 0;
    }
  }

  &.has-error {
    input {
      border-bottom-color: ${Theme(company).colors.borders.error};
      &::placeholder {
        color: ${Theme(company).colors.fontcolors.error};
      }
    }

    label {
      color: ${Theme(company).colors.fontcolors.error};
    }
  }
`

const HiddenInputStyles = css`
  display: none;
`
