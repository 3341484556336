/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'
import { Formik, Form } from 'formik'

import { InputField } from '../../components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Theme from '../../styles/theme'

import { Company, Tenant } from '../../types/Interfaces'

import { useRecoilValue } from 'recoil'
import { companyState } from '../../Session'

import CompanyService from '../../services/CompanyService'
import { useTranslation } from 'react-i18next'

interface Props {
  handleSubmit: (
    request: Promise<any>,
    successMessage?: string,
    callback?: () => void,
  ) => void
  entity: Company | Tenant
  type: 'company' | 'tenant'
}

const ValidatedDomainsForm = (props: Props) => {
  const company = useRecoilValue(companyState)
  const { t } = useTranslation('admin_settings')

  function relevantDomains() {
    return props.type === 'company'
      ? props.entity.domains.filter((domain) => !domain.belongs_to_tenant)
      : props.entity.domains
  }

  return (
    <div css={ValidatedDomainStyles(company)}>
      <h4>{t('validated_domains')}</h4>
      <p>{t('validated_domains_details')}</p>
      <div className='validated-domains'>
        {relevantDomains().map((domain) => (
          <div key={domain.id}>
            <span>
              {domain.name}{' '}
              <FontAwesomeIcon
                color={Theme(company).colors.interaction.success}
                icon={['fas', 'check-circle']}
              />
            </span>
            <a
              onClick={() => {
                props.handleSubmit(
                  CompanyService.deleteDomain(domain.id),
                  t('domain_deleted'),
                )
              }}
            >
              <FontAwesomeIcon
                color={Theme(company).colors.fontcolors.body}
                className='trash'
                icon={['fas', 'trash-alt']}
              />
            </a>
          </div>
        ))}
      </div>

      <Formik
        initialValues={{ domain: '' }}
        onSubmit={async (values, { resetForm }) => {
          props.handleSubmit(
            CompanyService.addDomain(
              props.type,
              props.entity.id,
              values.domain,
            ),
            t('domain_added'),
            resetForm,
          )
        }}
      >
        {() => (
          <Form className='domains-form'>
            <InputField type='text' label={t('add_domain')} name='domain' />
            <input type='submit' tabIndex={-1} />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ValidatedDomainsForm

const ValidatedDomainStyles = (company?: Company) => css`
  .domains-form {
    input[type='submit'] {
      position: absolute;
      visibility: hidden;
    }
  }

  .validated-domains {
    padding: 1.8rem 2.2rem;
    border-radius: 1rem;
    font-size: 1.4rem;
    background-color: ${Theme(company).colors.backgrounds.outer};
    margin-top: 1rem;
    font-weight: normal;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span svg {
        margin-left: 1rem;
      }

      .trash {
        cursor: pointer;
      }
    }
  }
`
