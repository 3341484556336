/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import Theme from '../../styles/theme'
import React from 'react'

import { useRecoilValue } from 'recoil'
import { companyState } from '../../Session'
import { Company } from '../../types/Interfaces'

interface LabelProps {
  size?: string
  children?: React.ReactNode
}

const FieldLabel = (props: LabelProps) => {
  const company = useRecoilValue(companyState)

  return <label css={LabelStyles(props, company)}>{props.children}</label>
}

export default FieldLabel

const LabelStyles = (props: LabelProps, company?: Company) => css`
  font-weight: normal;
  pointer-events: none;
  top: 1.2rem;
  font-size: 1.3rem;
  color: ${Theme(company).colors.fontcolors.primary};
  transition: opacity 0.3s ease-in-out;
`
