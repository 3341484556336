/** @jsx jsx */
import { useTranslation } from 'react-i18next'
import { jsx, css } from '@emotion/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  stepBack: () => void
}
const StepBackButton = (props: Props) => {
  const { t } = useTranslation('visitor_form')

  return (
    <div css={ButtonStyles}>
      <button className='step-back' onClick={() => props.stepBack()}>
        <FontAwesomeIcon className='back' icon={['fas', 'chevron-left']} />
        <h4>{t('previous_step')}</h4>
      </button>
    </div>
  )
}

export default StepBackButton

const ButtonStyles = css`
  button.step-back {
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    background: none;
    color: #807c79;
    font-weight: 300;
    margin-bottom: 2rem;
    padding-left: 0rem;
    cursor: pointer;

    h4 {
      color: #807c79;
      font-weight: 300;
      margin: 0rem;
    }

    .back {
      margin-right: 1rem;
      font-size: 1.5rem;
      position: relative;
      top: -0.1rem;
    }
  }
`
