/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import ReactCrop, { Crop } from 'react-image-crop'
import 'react-image-crop/lib/ReactCrop.scss'

import { useState, useMemo } from 'react'
import { useRecoilState } from 'recoil'
import { userState } from '../../../Session'
import UserService from '../../../services/UserService'
import { ActionBarStyles } from '../../../styles/container-styles'
import { Button, InlineAlert } from '../../../components'
import { useTranslation } from 'react-i18next'

interface Props {
  image?: File
  triggerCropPhoto: () => void
}

const CropProfilePic = (props: Props) => {
  const { t } = useTranslation('change_profile_form')
  const [user, setUser] = useRecoilState(userState)
  const [crop, setCrop] = useState<Crop>({
    aspect: 1 / 1,
    unit: '%',
    width: 100,
    x: 0,
    y: 0,
  })
  const [imgRef, setImgRef] = useState<HTMLImageElement>()

  const imgSrc = useMemo(
    () => props.image && URL.createObjectURL(props.image),
    [props.image],
  )

  async function handleCropCompletion() {
    props.triggerCropPhoto()
    if (imgRef && props.image) {
      const croppedImgObject = await getCroppedImg(
        imgRef,
        crop,
        props.image.name,
      )
      if (user) {
        const response = await UserService.updateProfilePic(
          croppedImgObject,
          user,
        )
        setUser(response.user)
      }
    }
  }

  return (
    <div>
      {imgSrc && (
        <ReactCrop
          src={imgSrc}
          crop={crop}
          onImageLoaded={(image: HTMLImageElement) => setImgRef(image)}
          onChange={(crop: Crop) => setCrop(crop)}
          imageStyle={{
            maxHeight: '300px',
            height: 'auto',
          }}
        />
      )}

      <div css={ActionBarStyles}>
        <Button
          type='submit'
          value={t('continue')}
          onClick={() => handleCropCompletion()}
        />
      </div>
    </div>
  )
}

const getCroppedImg = async (
  image: HTMLImageElement,
  crop: Crop,
  fileName: string,
): Promise<File> => {
  if (
    crop.x === undefined ||
    crop.y === undefined ||
    !crop.width ||
    !crop.height
  ) {
    throw 'this is not a supported crop'
  }
  const canvas = document.createElement('canvas')
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  canvas.width = crop.width
  canvas.height = crop.height
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    throw 'ctx is not defined'
  }
  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  )

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      if (blob) {
        const file = new File([blob], fileName, {
          type: blob.type,
          lastModified: Date.now(),
        })
        resolve(file)
      }
    })
  })
}

export default CropProfilePic
