/** @jsx jsx */
import { css } from '@emotion/core'
import Theme from './theme'
import { Company } from '../types/Interfaces'

export const PageStyles = (company?: Company) => css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .step-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .step-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .step-exit {
    opacity: 1;
  }
  .step-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
`

export const ContainerStyles = (
  size: 'small' | 'large',
  company?: Company,
) => css`
  max-width: ${size == 'large' ? '60' : '50'}rem;
  margin: 3rem;
  padding: 0.5rem;
  width: 100%;
  overflow: hidden;
  box-shadow: ${size == 'large'
    ? 'none'
    : Theme(company).colors.borders.containerShadow};
  background-color: ${size == 'large'
    ? 'transparent'
    : Theme(company).colors.backgrounds.inner};

  @media ${Theme(company).breakpoints.mediumDown} {
    box-shadow: none;
  }

  figure.logo {
    max-width: 50%;
    margin: 0 auto 2.5rem;
  }

  h4 {
    margin-bottom: 3rem;
    text-align: center;
  }
`

export const MainStyles = css`
  padding: 5rem 4rem;
`

export const VisitorRegistrationFormStyles = (company?: Company) => css`
  min-height: 100vh;
  padding: 5rem 10rem;
  background: ${Theme(company).colors.backgrounds.inner};

  .registration-container {
    max-width: 112rem;
    margin: 0 auto;
  }
  @media ${Theme(company).breakpoints.largeDesktop} {
    padding: 5rem 15rem;
  }

  @media ${Theme(company).breakpoints.smallDown} {
    padding: 5rem 4rem;
  }

  h2 {
    font-size: 3.8rem;
  }
`

export const ActionBarStyles = css`
  margin: 3rem 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > * {
    margin-left: auto;
    flex-grow: 1;
    font-size: 1.6rem;
  }

  a,
  span {
    svg {
      margin-right: 1rem;
    }
  }
`

export const FooterStyles = (company?: Company) => css`
  padding: 2.5rem;
  font-size: 1.6rem;
  text-align: center;
  font-weight: normal;
  background-color: ${Theme(company).colors.backgrounds.primary};
`

export const SubmissionSucceededStyles = (company?: Company) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;

  h2 {
    color: ${Theme(company).colors.fontcolors.body};
    font-size: 2.6rem;
    font-weight: 300;
    letter-spacing: -0.034rem;
  }

  img {
    margin-top: 4rem;
  }

  span {
    margin-top: 2rem;
    font-weight: 500;
    font-size: 1.8rem;

    &.email {
      margin-top: 1rem;
      font-size: 1.6rem;
      color: ${Theme(company).colors.fontcolors.primary};
    }
  }

  button {
    margin-top: 5rem;
  }
`
