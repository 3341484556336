// Adapted from Beta react-pdf example

import React from 'react'
import camelcase from 'camelcase'
import { fromPairs } from 'ramda'
import ReactPDF from '@react-pdf/renderer'

const { Rect, Svg, G, Path, Text, Tspan } = ReactPDF

const maxHeight = 80
const maxWidth = 250

function isElement(node) {
  return node.nodeType === Node.ELEMENT_NODE
}

function attributesToProps(attributes) {
  const attributePairs = Array.from(attributes).map((attr) => [
    camelcase(attr.nodeName),
    attr.value,
  ])
  return fromPairs(attributePairs)
}

function nodeToComponent(node, key) {
  const nodeName = camelcase(node.nodeName, { pascalCase: true })
  const Component = ReactPDF[nodeName]

  if (!Component || !isElement(node)) return null

  const props = attributesToProps(node.attributes)
  const children = traverseNodes(node.childNodes)

  return (
    <Component key={key} {...props}>
      {children.length ? children : node.textContent}
    </Component>
  )
}

function traverseNodes(nodes) {
  return Array.from(nodes)
    .map(nodeToComponent)
    .filter((node) => !!node)
}

export const SvgChart = ({ svg }) => {
  if (!svg) {
    return null
  }
  const parser = new DOMParser()
  const doc = parser.parseFromString(svg, 'image/svg+xml')
  const children = traverseNodes(doc.documentElement.childNodes)

  let actualHeight = parseInt(doc.documentElement.getAttribute('height'))
  let width = parseInt(doc.documentElement.getAttribute('width'))

  width = width / (actualHeight / maxHeight)
  let height = maxHeight

  if (width > maxWidth) {
    height = height / (width / maxWidth)
    width = maxWidth
  }

  return (
    <Svg
      height={height || maxHeight}
      width={width || maxWidth}
      viewBox={doc.documentElement.getAttribute('viewBox')}
      style={{ maxHeight: maxHeight, maxWidth: maxWidth }}
    >
      {children}
    </Svg>
  )
}

export const WifiIcon = ({ color }) => (
  <Svg viewBox='0 0 640 512'>
    <Path
      fill={color}
      d='M634.91 154.88C457.74-8.99 182.19-8.93 5.09 154.88c-6.66 6.16-6.79 16.59-.35 22.98l34.24 33.97c6.14 6.1 16.02 6.23 22.4.38 145.92-133.68 371.3-133.71 517.25 0 6.38 5.85 16.26 5.71 22.4-.38l34.24-33.97c6.43-6.39 6.3-16.82-.36-22.98zM320 352c-35.35 0-64 28.65-64 64s28.65 64 64 64 64-28.65 64-64-28.65-64-64-64zm202.67-83.59c-115.26-101.93-290.21-101.82-405.34 0-6.9 6.1-7.12 16.69-.57 23.15l34.44 33.99c6 5.92 15.66 6.32 22.05.8 83.95-72.57 209.74-72.41 293.49 0 6.39 5.52 16.05 5.13 22.05-.8l34.44-33.99c6.56-6.46 6.33-17.06-.56-23.15z'
    />
  </Svg>
)
