/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'

interface Props {
  wide?: boolean
  className?: string
  visitorRegistration?: boolean
}

export default class PageWidth extends React.Component<Props, unknown> {
  render() {
    return (
      <div
        className={`page-width ${this.props.className}`}
        css={PageWidthStyles(this.props)}
      >
        {this.props.children}
      </div>
    )
  }
}

const PageWidthStyles = (props: Props) => css`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: ${!props.visitorRegistration &&
  (props.wide ? '142rem' : '112rem')};
  padding: ${props.visitorRegistration ? '0 0rem' : '0 3rem'};
  @media print {
    padding: 0;
  }
  &.grow {
    flex-grow: 1;
  }
`
