/** @jsx jsx */
import { css } from '@emotion/core'
import Theme from '../styles/theme'

import { Company } from '../types/Interfaces'

export const ToggleStyles = css`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  label {
    margin-left: 2rem;
    font-weight: 400;
    cursor: pointer;
    line-height: 1.6rem;
  }

  .react-toggle {
    .react-toggle-track {
      width: 2.8rem;
      height: 1.8rem;
    }

    .react-toggle-thumb {
      width: 1.6rem;
      height: 1.6rem;
    }

    &.react-toggle--checked {
      .react-toggle-thumb {
        left: 1.1rem;
      }
    }

    .react-toggle-track-x,
    .react-toggle-track-check {
      display: none;
    }
  }
`

export const ButtonToggleStyles = (company?: Company) => css`
  display: inline-block;
  margin-right: 1rem;
  padding: 0.2rem 1rem;
  min-width: 9rem;
  border-radius: 0.5rem;
  font-size: 1.3rem;
  text-align: center;
  font-weight: 400;
  color: ${Theme(company).colors.fontcolors.inverted};
  background-color: ${Theme(company).colors.buttoncolors.default};
  box-shadow: 0 0.5px 0.1rem 0 rgba(0, 0, 0, 0.12);
  &:hover {
    text-decoration: none;
  }
  &:not(.active) {
    background-color: ${Theme(company).colors.backgrounds.inner};
    color: ${Theme(company).colors.fontcolors.body};
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
`
