import axios from 'axios'
import i18n from 'i18next'

import { AppointmentFormFields } from '../types/Interfaces'

class VisitService {
  async create(appointmentId: string, healthy: boolean, email: string) {
    return axios
      .post(`/api/visits`, {
        email: email,
        visit: {
          appointment_id: appointmentId,
          healthy: healthy,
          language: i18n.language,
        },
      })
      .then((response) => response.data)
  }

  async update(values: AppointmentFormFields) {
    return axios
      .put(`/api/visits/${values.id}`, {
        visit: values,
      })
      .then((response) => response.data)
  }
}

export default new VisitService()
