/* eslint-disable react/jsx-key */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, css } from '@emotion/core'
import React, { useEffect, useMemo } from 'react'
import useStateSafe from '../../helpers/use-state-safe'
import Theme from '../../styles/theme'
import Modal from 'react-modal'
import moment from 'moment-timezone'
import { useTranslation } from 'react-i18next'
import { Row } from 'react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  PageWidth,
  Button,
  InlineAlert,
  Spinner,
  Table,
} from '../../components'
import VisitorService from '../../services/VisitorService'
import { Visitor, Company } from '../../types/Interfaces'
import VisitorForm from '../../components/admin/VisitorForm'

import { useRecoilState } from 'recoil'
import { companyState } from '../../Session'
import { userState } from '../../Session'

import { verifyUserAccountType } from '../../helpers/user'

import * as Validator from '../../shared/Validations'

import { ModalStyles } from '../../styles/modal-styles'
import { EmptyTableStyles } from '../../styles/global-styles'
import { useLanguage } from '../../hooks/language'

const AdminVisitors = (): JSX.Element => {
  const { t } = useTranslation('admin_visitors')
  const [visitors, setVisitors] = useStateSafe<Visitor[]>([])
  const [addingNewVisitor, setAddingNewVisitor] = useStateSafe(false)
  const [editingVisitor, setEditingVisitor] = useStateSafe<Visitor | null>(null)
  const [loaded, setLoaded] = useStateSafe(false)
  const [error, setError] = useStateSafe(false)
  const [company] = useRecoilState(companyState)
  const [language] = useLanguage()
  const [user] = useRecoilState(userState)

  moment.locale(language)

  useEffect(() => {
    fetchVisitors()
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: t('field:name'),
        accessor: 'full_name',
        // NOTE: Sort by last name, but it at the moment it will sort
        // first on prefixes like 'de', 'van', etc.
        sortType: (
          { original: a }: Row<Visitor>,
          { original: b }: Row<Visitor>,
        ) => {
          return a.last_name.localeCompare(b.last_name, language)
        },
      } as const,
      {
        Header: t('field:email'),
        accessor: 'email',
      } as const,
      {
        Header: t('field:phone'),
        accessor: 'phone',
      } as const,
      {
        Header: t('field:last_visit'),
        accessor: 'last_visit',
        sortType: (
          { original: a }: Row<Visitor>,
          { original: b }: Row<Visitor>,
        ) => {
          return moment(b.last_visit).unix() - moment(a.last_visit).unix()
        },
        Cell({ value }: { row: Row<Visitor>; value: string }) {
          const date = moment(value)
          return date.isValid() ? moment(value).format('D MMMM YYYY') : '-'
        },
      } as const,
      {
        id: 'options',
        Header: '',
        Cell({ row: { original: visitor } }: { row: Row<Visitor> }) {
          return (
            <FontAwesomeIcon
              icon={['fas', 'cog']}
              onClick={() => setEditingVisitor(visitor)}
            />
          )
        },
      } as const,
    ],
    [language, visitors],
  )

  const fetchVisitors = async (delay = true) => {
    const startTime = Date.now()
    setError(false)
    try {
      const response = await VisitorService.fetchAll()

      if (delay) {
        setTimeout(() => {
          setVisitors(response.visitors)
          setLoaded(true)
        }, Math.max(500 - (Date.now() - startTime), 0))
      } else {
        setVisitors(response.visitors)
        setLoaded(true)
      }
    } catch {
      setError(true)
      setLoaded(true)
    }
  }

  const BaseContent = (
    props: React.PropsWithChildren<unknown>,
  ): JSX.Element => {
    return (
      <section css={DashboardStyles(company)}>
        <PageWidth>
          <header>
            <h1>{t('visitors')}</h1>
            <Button
              type='default'
              size='default'
              value=''
              onClick={() => setAddingNewVisitor(true)}
            >
              <div>
                {t('add_new_visitor')}
                <FontAwesomeIcon icon={['fas', 'user-plus']} />
              </div>
            </Button>
          </header>
          {props.children}
        </PageWidth>

        <Modal
          isOpen={addingNewVisitor || !!editingVisitor}
          onRequestClose={() => {
            setAddingNewVisitor(false)
            setEditingVisitor(null)
          }}
          ariaHideApp={false}
          style={ModalStyles(company)}
          parentSelector={() => document.body}
        >
          <VisitorForm
            onVisitorChange={async () => {
              await fetchVisitors(false)
              setAddingNewVisitor(false)
              setEditingVisitor(null)
            }}
            visitor={editingVisitor}
          />
        </Modal>
      </section>
    )
  }

  if (!loaded) {
    return (
      <BaseContent>
        <Spinner size='large' />
      </BaseContent>
    )
  } else if (error) {
    return (
      <BaseContent>
        <InlineAlert
          type='error'
          show={true}
          message={Validator.unexpectedError}
        />
      </BaseContent>
    )
  } else if (!visitors.length) {
    return (
      <BaseContent>
        <div css={EmptyTableStyles}>
          <h4>{t('no_visitors_registered')}</h4>
          <span>{t('no_visitors_registered_description')}</span>
        </div>
      </BaseContent>
    )
  } else {
    return (
      <BaseContent>
        <Table
          rows={visitors}
          sortBy={[{ id: 'full_name' }]}
          columns={columns}
          hiddenColumns={[]}
          search={true}
          grid={`3fr 3fr 2fr 2fr 1fr`}
        />
      </BaseContent>
    )
  }
}

export default AdminVisitors

const DashboardStyles = (company?: Company) => css`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6rem;

    @media ${Theme(company).breakpoints.mobileDown} {
      flex-direction: column;
      margin-bottom: 4rem;
    }

    h1 {
      margin: 0;
      @media ${Theme(company).breakpoints.smallDown} {
        margin-bottom: 3rem;
      }
    }

    button {
      font-size: 1.6rem;
    }
  }
`
