/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'

import Theme from '../styles/theme'

import { useRecoilValue } from 'recoil'
import { companyState } from '../Session'
import { Company } from '../types/Interfaces'

import { Logo } from '../styles/icons'

interface Props {
  size: 'medium' | 'large'
}

const Spinner = (props: Props) => {
  const company = useRecoilValue(companyState)

  return (
    <div css={LoaderContainerStyles(props, Logo(company))}>
      <div css={LoaderStyles(props, company)} />
    </div>
  )
}

export default Spinner

const LoaderContainerStyles = (props: Props, svg: string) => css`
  display: flex;
  margin: ${props.size == 'medium' ? '0' : '5rem'} auto;
  width: ${props.size == 'medium' ? '4rem' : '10rem'};
  height: ${props.size == 'medium' ? '4rem' : '10rem'};
  background-image: url('data:image/svg+xml;utf8,${svg}');
  background-position: center;
  background-size: 70%;
  background-repeat: no-repeat;
`

const LoaderStyles = (props: Props, company?: Company) => css`
  @keyframes loader-rotate {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  width: 100%;
  height: 100%;
  position: relative;
  animation: loader-rotate 0.5s ease-in-out infinite;
  top: 50%;
  margin: -50% auto 0 auto;
  &:after {
    content: '';
    width: 15%;
    height: 15%;
    border-radius: 50%;
    background: ${Theme(company).colors.buttoncolors.default};
    position: absolute;
    top: -5%;
    left: 50%;
    margin-left: -5%;
  }
`
