/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Dispatch, SetStateAction, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import { Link } from 'react-router-dom'

import { Button } from '../../components'
import TenantForm from '../../components/admin/TenantForm'

import { Company, Tenant } from '../../types/Interfaces'

import Theme from '../../styles/theme'
import { ModalStyles } from '../../styles/modal-styles'

import { useRecoilState } from 'recoil'
import { companyState } from '../../Session'

import TenantsAdminShow from '../../components/TenantsAdminShow'

interface Props {
  propagateResult: (response: Company) => void
  tenants: Tenant[]
  active: boolean
  expandedTenantIds: number[]
  setExpandedTenantIds: Dispatch<SetStateAction<number[]>>
}

const AdminTenants = (props: Props): JSX.Element => {
  const { t } = useTranslation('admin_settings')
  const [addingNewTenant, setAddingNewTenant] = useState(false)
  const [editingTenant, setEditingTenant] = useState<number | null>(null)
  const [company] = useRecoilState(companyState)

  function toggleTenantRow({ id }: Tenant) {
    if (props.expandedTenantIds.includes(id)) {
      props.setExpandedTenantIds(
        props.expandedTenantIds.filter((x) => x !== id),
      )
    } else {
      props.setExpandedTenantIds(props.expandedTenantIds.concat([id]))
    }
  }

  const noUsersErrorMessage = (
    <Link to='/admin/users'>{t('no_user_warning')}</Link>
  )

  return (
    <div css={AdminTenantStyles} className={props.active ? '' : 'inactive'}>
      {
        <header>
          <h2>{t('tenants')}</h2>
          <Button
            type='default'
            size='default'
            value=''
            onClick={() => setAddingNewTenant(true)}
          >
            <div>
              {t('add_new_tenant')}
              <FontAwesomeIcon icon={['fas', 'building']} />
            </div>
          </Button>
        </header>
      }

      {props.tenants.length === 0 && (
        <div css={NoTenantsStyles}>
          <h4>{t('no_tenants_found')}</h4>
          <span>{t('no_tenants_found_description')}</span>
        </div>
      )}

      {props.tenants.map((tenant) => {
        return (
          <div key={tenant.id} css={TenantStyles(company)}>
            <header onClick={() => toggleTenantRow(tenant)}>
              <div className='tenant-title'>
                <FontAwesomeIcon
                  icon={[
                    'fas',
                    props.expandedTenantIds.includes(tenant.id)
                      ? 'caret-down'
                      : 'caret-right',
                  ]}
                />
                <span>{tenant.name}</span>
                {!tenant.has_users && noUsersErrorMessage}
              </div>

              <a
                onClick={(event) => {
                  event.stopPropagation()
                  setEditingTenant(tenant.id)
                }}
              >
                <FontAwesomeIcon
                  color={Theme(company).colors.fontcolors.body}
                  className='trash'
                  icon={['fas', 'cog']}
                />
              </a>
            </header>
            <div
              className={`columns ${
                props.expandedTenantIds.includes(tenant.id) && 'open'
              }`}
            >
              <TenantsAdminShow
                onAdminPage={true}
                tenant={tenant}
                propagateResult={props.propagateResult}
                active={props.active}
                expandedTenantIds={props.expandedTenantIds}
                setExpandedTenantIds={props.setExpandedTenantIds}
              />
            </div>
          </div>
        )
      })}

      <Modal
        isOpen={addingNewTenant || !!editingTenant}
        onRequestClose={() => {
          setAddingNewTenant(false)
          setEditingTenant(null)
        }}
        ariaHideApp={false}
        style={ModalStyles(company)}
        parentSelector={() => document.body}
      >
        <TenantForm
          tenantData={
            props.tenants.find((tenant) => tenant.id === editingTenant) ||
            undefined
          }
          onTenantChange={(response: Company) =>
            props.propagateResult(response)
          }
        />
      </Modal>
    </div>
  )
}

export default AdminTenants

const AdminTenantStyles = css`
  &.inactive {
    pointer-events: none;
    filter: grayscale();
    opacity: 0.3;
  }

  > header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 3rem 0 2.5rem;
  }
`

const TenantStyles = (company?: Company) => css`
  background: ${Theme(company).colors.backgrounds.inner};
  box-shadow: ${Theme(company).colors.borders.mediumContainerShadow};
  margin-top: 1.5rem;

  .tenant-title {
    width: 100%;
    margin-right: 2rem;

    a {
      margin-left: auto;
      margin-right: 0rem;
      border-radius: 0.3rem;
      padding: 0rem 1rem;
      font-size: 1.3rem;
      background-color: ${Theme(company).colors.buttoncolors.default};
      color: white;
      text-decoration: none;
    }
  }

  header {
    padding: 1.5rem 2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      display: flex;
      font-weight: normal;

      svg {
        margin-right: 0.9rem;
        width: 0.625em !important;
        font-size: 2rem;
      }
    }

    > a {
      cursor: pointer;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
  }

  .columns {
    padding: 2rem 4rem;
    display: none;
    &.open {
      display: flex;
    }
  }
`

const NoTenantsStyles = css`
  text-align: center;
  display: block;
  margin: 6rem 0 4rem;
  font-weight: normal;

  h4 {
    margin: 1rem 0;
  }
`
