/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'

interface Props {
  alignment?: 'center' | 'right'
  children?: React.ReactNode
}

export default class InputGroup extends React.Component<Props, unknown> {
  render() {
    return <div css={InputGroupStyles(this.props)}>{this.props.children}</div>
  }
}

const InputGroupStyles = (props: Props) => css`
  display: flex;
  align-items: center;
  justify-content: ${props.alignment === 'right'
    ? 'flex-end'
    : 'space-between'};

  > * {
    flex-grow: 1;
    &:not(:first-of-type) {
      margin-left: 2rem;
    }
    &.time,
    &.checkbox {
      flex-grow: 0;
    }
    &.checkbox {
      margin-right: 2rem;

      input {
        margin-right: 1rem;
      }
    }
  }
`
