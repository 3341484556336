/* eslint-disable react/jsx-key */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import {
  Column,
  useSortBy,
  useTable,
  useGlobalFilter,
  HeaderGroup,
} from 'react-table'
import Search from './Search'
import { useTranslation } from 'react-i18next'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRecoilState } from 'recoil'
import { companyState } from '../Session'

import Theme from '../styles/theme'

import { Company, Review, User, Visitor } from '../types/Interfaces'

interface TableProps {
  rows: Review[] | User[] | Visitor[]
  columns: Column<any>[]
  sortBy: { id: string }[]
  hiddenColumns: string[]
  search: boolean
  grid: string
}

const Table = ({
  rows: data,
  columns: columns,
  sortBy: sortBy,
  hiddenColumns: hiddenColumns,
  search: search,
  grid: grid,
}: TableProps) => {
  const { t } = useTranslation('admin_users')
  const [company] = useRecoilState(companyState)
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    rows,
    setGlobalFilter,
    state: { globalFilter },
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
      initialState: {
        sortBy: sortBy,
        hiddenColumns: hiddenColumns,
      },
    },
    useGlobalFilter,
    useSortBy,
  )

  return (
    <div css={TableStyles(grid, company)}>
      {search && (
        <Search
          label={t('search_user')}
          value={globalFilter}
          onChange={setGlobalFilter}
        />
      )}

      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')} {sortIcon(column)}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default Table

// eslint-disable-next-line @typescript-eslint/ban-types
const sortIcon = <T extends object>(column: HeaderGroup<T>) => {
  const [company] = useRecoilState(companyState)

  return column.isSorted ? (
    <FontAwesomeIcon
      color={Theme(company).colors.borders.gray}
      icon={['fas', column.isSortedDesc ? 'chevron-up' : 'chevron-down']}
    />
  ) : (
    // Show transparent icon.
    // This is a HACK to make sure column widths don't change when sorting.
    <FontAwesomeIcon color='transparent' icon={['fas', 'chevron-up']} />
  )
}

const TableStyles = (grid: string, company?: Company) => css`
  @media ${Theme(company).breakpoints.notLargeDesktop} {
    overflow-x: scroll;
  }

  table {
    width: 100%;
    min-width: 85rem;
    border-collapse: separate;
    font-size: 1.6rem;

    tr {
      display: grid;
      grid-template-columns: ${grid};
      margin-bottom: 1.3rem;
    }

    th,
    td {
      overflow: hidden;
    }

    thead {
      th {
        white-space: nowrap;
        text-align: left;
        padding: 1.5rem 2.1rem;
        font-weight: normal;

        &:first-of-type {
          padding-left: 0;
        }

        &:last-of-type {
          text-align: right;
        }
      }
    }

    tbody {
      tr {
        background: ${Theme(company).colors.backgrounds.inner};
        box-shadow: ${Theme(company).colors.borders.mediumContainerShadow};

        &.unhealthy {
          background-color: rgba(216, 35, 42, 0.1);
          color: ${Theme(company).colors.interaction.failure};

          td a {
            color: ${Theme(company).colors.interaction.failure};
          }
        }

        td {
          white-space: nowrap;
          padding: 1.5rem 2.1rem;
          text-overflow: ellipsis;

          select {
            color: ${Theme(company).colors.fontcolors.body};
            background-color: ${Theme(company).colors.backgrounds.inner};
            border: none;

            &:focus {
              outline: none;
            }
          }

          > span {
            opacity: 0.5;
          }

          &:first-of-type {
            font-weight: normal;
          }

          &:last-of-type {
            text-align: right;

            svg.trash {
              cursor: pointer;
            }

            svg.fa-cog {
              cursor: pointer;
              vertical-align: middle;
              opacity: 0.8;
              &:hover {
                opacity: 1;
              }
            }
          }

          a {
            color: ${Theme(company).colors.fontcolors.body};

            > svg {
              margin-left: 1.2rem;
            }
          }

          &.phone {
            letter-spacing: 0.05rem;
          }

          &.account-type {
            font-weight: normal;

            select {
              margin-left: -0.4rem;
            }
          }
        }
      }
    }
  }
`
