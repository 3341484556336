/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { User } from '../../../types/Interfaces'
import Theme from '../../../styles/theme'
import { useState } from 'react'

import { useRecoilValue } from 'recoil'
import { companyState } from '../../../Session'
import { Company } from '../../../types/Interfaces'

interface Props {
  user: User
  handleUserClick?: (userId: number) => void
}
const UserCard = (props: Props) => {
  const company = useRecoilValue(companyState)
  const returnImg = (
    <img src={props.user.portrait ? props.user.portrait : '/silhouette.png'} />
  )

  const returnImgButton = (
    <button
      onClick={() => {
        props.handleUserClick
          ? props.handleUserClick(props.user.id as number)
          : undefined
      }}
    >
      {returnImg}
    </button>
  )

  return (
    <div css={UserCardStyles(company, !!props.handleUserClick)}>
      {returnImgButton}
      <p>{`${props.user?.first_name} ${props.user?.last_name}`}</p>
    </div>
  )
}

export default UserCard

const UserCardStyles = (company?: Company, isButton?: boolean) => css`
  text-align: center;

  img {
    height: 13rem;
    border-radius: 50%;
    border: white solid 0.5rem;
    box-shadow: 0 0 1.8rem 0 rgba(92, 88, 86, 0.15);
    margin: 1rem;
    cursor: ${isButton ? 'pointer' : ''};

    @media ${Theme(company).breakpoints.smallDown} {
      height: 11rem;
    }

    @media ${Theme(company).breakpoints.mobileDown} {
      height: 9rem;
    }
  }

  p {
    height: 3rem;
    width: 13.5rem;
    color: #5c5856;
    font-family: 'GT Eesti Pro Display';
    font-size: 2rem;
    letter-spacing: -0.026rem;
    line-height: 3rem;
    margin: auto;

    @media ${Theme(company).breakpoints.smallDown} {
      font-size: 2rem;
      width: auto;
    }

    @media ${Theme(company).breakpoints.mobileDown} {
      font-size: 1.8rem;
    }
  }

  button {
    border: none;
    background: none;
    outline: none;
    padding: 0rem;
  }
`
