/** @jsx jsx */
import { jsx } from '@emotion/core'

const DutchPrivacyPolicy = (): JSX.Element => {
  return (
    <div>
      <h1>Privacy Policy</h1>
      <h2>Welke gegevens worden verzameld als u ARRIVED gebruikt?</h2>

      <p>
        We verwerken de persoonsgegevens die noodzakelijk zijn voor de
        dienstverlening ARRIVED, meer specifiek gaat het om de volgende
        peroonsgegevens:
      </p>
      <ul>
        <li>Voornaam en achternaam;</li>
        <li>Telefoonnummer;</li>
        <li>E-mailadres;</li>
        <li>Bedrijfsnaam;</li>
      </ul>

      <p>
        Daarnaast worden de datum en tijd vastgelegd, de persoon met wie u de
        afspraak heeft, en uw toestemming voor de verwerking van deze gegevens.
      </p>
      <h2>Wat is de wettelijke basis voor het verwerken van de gegevens? </h2>
      <p>
        Uw gegevens worden gebruikt om uw bezoek te registreren en te plannen.
        De registratie is onder meer ten behoeve van bron- en contactonderzoek
        door de GGD bij een besmetting. U werkt hier op vrijwillige basis aan
        mee. Als u geen toestemming wilt geven, kan de toegang tot het gebouw
        niet geweigerd worden. Er vindt ook een gezondheidscheck - ook wel
        triage genoemd - plaats waarbij u onder meer gevraagd wordt of u
        verkouden bent of koorts heeft.
      </p>

      <p>
        Indien u een account heeft, dan gebruiken wij uw gegevens ook om dit te
        beheren. Daarnaast kunnen wij u transactionele e-mails en sms-berichten
        sturen, bijvoorbeeld om u te herinneren aan een bezoek of om een
        beoordeling van uw bezoek te vragen.
      </p>

      <h2>Worden de gegevens gedeeld met derden?</h2>

      <p>
        Wij delen de gegevens met de locatie van uw bezoek, zoals huurders en
        recepties in kantoren, ter uitvoering van de dienstverlening ter
        plaatse. Ook delen we de gegevens in geval van bron- en contactonderzoek
        door de GGD.
      </p>

      <h2>Hoe wordt de informatie gebruikt?</h2>

      <p>
        Informatie verstrekt voor registraties wordt voor dat doel, alsmede voor
        beoordelingen van bezoeken gebruikt. We kunnen u e-mails of
        sms-berichten sturen met betrekking tot uw registratie, zoals de
        volgende stappen, ontbrekende informatie, bevestigingen en annuleringen.
        De informatie wordt ook gebruikt om eventuele vragen te beantwoorden.
        Ten slotte leggen we informatie vast voor het opstellen van
        gebruikersstatistieken en voor het beveiligen van de website.
      </p>

      <h2>Wie verzamelt de gegevens?</h2>

      <p>
        ARRIVED verzamelt uw gegevens. Door onze interacties met u op de
        websites en applicaties kunnen we software van derden gebruiken, die ook
        (beperkte) toegang hebben tot uw gegevens. Alle gegevens worden
        beschermd met de hoogst beschikbare beveiliging, versleuteling en
        tweestapsverificatie (indien beschikbaar).
      </p>

      <p>
        Wij gaan respectvol met uw gegevens om en zorgen ervoor dat we alleen de
        benodigde gegevens vragen en nooit informatie vragen die niet nodig is
        om ARRIVED goed te laten functioneren.
      </p>

      <p>
        De identiteit en de contactgegevens van ARRIVED zijn als volgt: <br />
        Bouwens Receptiediensten B.V. <br />
        Emmaplein 2<br /> 1075 AW Amsterdam <br />
        <a href='mailto:info@bouwensen.nl'> info@bouwensen.nl</a> <br />
        020-6422820 <br />
      </p>

      <h2>Hoe lang bewaren wij uw persoonsgegevens? </h2>
      <p>
        Uw persoonsgegevens worden niet langer bewaard dan noodzakelijk is. De
        gegevens voor de gezondheidscheck worden maximaal 14 dagen bewaard. Na
        afloop van genoemde termijn worden de gegevens voor de gezondheidscheck
        definitief verwijderd.
      </p>

      <h2>Inzage en/of wijziging van uw gegevens</h2>
      <p>
        Als u inzage wenst in de over u geregistreerde persoonsgegevens en/of
        deze gegevens wilt wijzigen of verwijderen, dan kunt u een verzoek
        daartoe versturen naar{' '}
        <a href='mailto:info@bouwensen.nl'> info@bouwensen.nl</a>. Het is ook
        mogelijk om via dit e-mailadres bezwaar te maken tegen het verwerken van
        de door u geregistreerde persoonsgegevens.
      </p>

      <h2>Klachten</h2>

      <p>
        Heeft u vragen, opmerkingen of klachten over de bescherming van uw
        persoonsgegevens door ARRIVED, dan kunt u via e-mail contact met ons
        opnemen via <a href='mailto:info@bouwensen.nl'> info@bouwensen.nl</a>.
        ARRIVED helpt u graag bij het vinden van een oplossing. Mocht dat niet
        lukken, dan heeft u altijd het recht een klacht in te dienen bij de bij
        de Autoriteit Persoonsgegevens op
        <a href='https://autoriteitpersoonsgegevens.nl/'>
          https://autoriteitpersoonsgegevens.nl/
        </a>
        .
      </p>
      <h2>Wijzigingen</h2>
      <p>
        ARRIVED kan veranderingen, aanvullingen of wijzigingen aanbrengen in
        haar privacy verklaring. De meest actuele privacy verklaring is te allen
        tijde in te zien op de website van ARRIVED. Deze versie is opgesteld in
        oktober 2020.
      </p>
    </div>
  )
}

export default DutchPrivacyPolicy
