/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

import { User } from '../../../types/Interfaces'

import { useRecoilState } from 'recoil'
import { userState, companyState } from '../../../Session'

import * as Validator from '../../../shared/Validations'

import {
  PageStyles,
  ContainerStyles,
  MainStyles,
  ActionBarStyles,
  SubmissionSucceededStyles,
} from '../../../styles/container-styles'

import {
  Logo,
  Button,
  InlineAlert,
  InputField,
  InputGroup,
} from '../../../components'
import AuthenticationService from '../../../services/AuthenticationService'

const newFormValues = {
  email: '',
  password: '',
  first_name: '',
  last_name: '',
  phone: '',
}

const UserRegistration = (props: RouteComponentProps) => {
  const { t } = useTranslation('user_registration')
  const [company] = useRecoilState(companyState)
  const [user] = useRecoilState(userState)
  const [attemptedSubmission, setAttemptedSubmission] = useState(false)
  const [submissionSucceeded, setSubmissionSucceeded] = useState(false)
  const [submissionError, setSubmissionError] = useState<undefined | string>()

  useEffect(() => {
    if (user && !window.location.pathname.includes('admin')) {
      props.history.push('/admin')
    }
  }, [])

  return (
    <section css={PageStyles(company)}>
      <div css={ContainerStyles('small', company)}>
        <div css={MainStyles}>
          <Logo url={company?.logo} />

          <Formik
            initialValues={newFormValues}
            validateOnChange={attemptedSubmission}
            validateOnBlur={attemptedSubmission}
            validate={(values) => {
              let error: string | undefined
              const errors: DeepPartial<User> = {}

              // Object.keys(values).forEach(value => {})

              // TODO: How can we make this DRY while keeping TypeScript happy?
              if ((error = Validator.email(values.email, company)))
                errors.email = error

              if ((error = Validator.password(values.password)))
                errors.password = error

              if ((error = Validator.phone(values.phone))) errors.phone = error

              if ((error = Validator.firstName(values.first_name)))
                errors.first_name = error

              if ((error = Validator.lastName(values.last_name)))
                errors.last_name = error

              return errors
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmissionError(undefined)
              try {
                const response = await AuthenticationService.register(values)
                if (response.success) {
                  setSubmissionSucceeded(true)
                } else {
                  setSubmissionError(Validator.formatErrors(response))
                }
              } catch (err) {
                setSubmissionError(Validator.unexpectedError)
              }
              setSubmitting(false)
            }}
          >
            {({ values, isSubmitting }) => (
              <Form>
                {!submissionSucceeded && (
                  <>
                    <h4>{t('create_account')}</h4>
                    <p>{t('create_account_details')}</p>

                    <InputGroup>
                      <InputField
                        type='text'
                        label={t('field:first_name')}
                        name='first_name'
                      />
                      <InputField
                        type='text'
                        label={t('field:last_name')}
                        name='last_name'
                      />
                    </InputGroup>

                    <InputField
                      type='text'
                      label={t('field:email')}
                      name='email'
                    />

                    <InputField
                      type='password'
                      label={t('field:password')}
                      name='password'
                      autoComplete='new-password'
                    />

                    <InputField
                      type='text'
                      label={t('field:mobile')}
                      name='phone'
                      caption={t('you_will_receive_text_message')}
                    />

                    <div css={ActionBarStyles}>
                      <a onClick={() => window.history.go(-1)}>
                        <FontAwesomeIcon icon={['far', 'angle-left']} />
                        {t('action:previous_page')}
                      </a>
                      <Button
                        type='submit'
                        value={t('create_account_submit')}
                        loading={isSubmitting}
                        onClick={() => setAttemptedSubmission(true)}
                      />
                    </div>

                    {submissionError && (
                      <InlineAlert
                        type='error'
                        show={true}
                        message={submissionError}
                      />
                    )}
                  </>
                )}

                {submissionSucceeded && (
                  <div css={SubmissionSucceededStyles(company)}>
                    <h2>{t('registration_succeeded')}</h2>
                    <img src='/email.svg' />
                    <span>{t('confirmation_email_sent')}</span>
                    <span className='email'>{values.email}</span>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </section>
  )
}

export default withRouter(UserRegistration)
