/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'

import { Button, TextareaField } from '..'

import { Company, Tenant, CustomMessages } from '../../types/Interfaces'

import CompanyService from '../../services/CompanyService'
import TenantService from '../../services/TenantService'

import { useRecoilState } from 'recoil'
import { companyState, toggledLanguagesState } from '../../Session'
import { ButtonToggleStyles } from '../../styles/toggle-styles'

interface Props {
  handleSubmit: (
    request: Promise<any>,
    successMessage?: string,
    callback?: () => void,
  ) => void
  entity: Company | Tenant
  service: typeof CompanyService | typeof TenantService
}

const CustomMessagesForm = (props: Props) => {
  const { t } = useTranslation('admin_settings')
  const [company] = useRecoilState(companyState)
  const defaultLanguage = company?.language || 'en'
  const [toggledLanguages, setToggledLanguages] = useRecoilState(
    toggledLanguagesState,
  )

  const fields = [
    'appointment_confirmation',
    'registration_confirmation',
    'feedback_request',
  ] as const

  type Field = typeof fields[number]

  function currentLanguage(field: string) {
    if (
      toggledLanguages.find(
        (toggled) => toggled == `${field}-${props.entity.id}`,
      )
    ) {
      return defaultLanguage === 'en' ? 'nl' : 'en'
    } else {
      return defaultLanguage
    }
  }

  function setLanguage(field: string, language: 'en' | 'nl') {
    const key = `${field}-${props.entity.id}`

    if (currentLanguage(field) === language) return

    if (defaultLanguage === language) {
      setToggledLanguages(toggledLanguages.filter((toggled) => toggled !== key))
    } else {
      setToggledLanguages([key].concat(toggledLanguages))
    }
  }

  function fieldValues(language: 'en' | 'nl') {
    return Object.assign(
      {},
      ...fields.map((field) => {
        return { [field]: defaultForField(language, field) }
      }),
    )
  }

  function defaultForField(language: 'en' | 'nl', field: Field) {
    return (
      props.entity.custom_messages[language][field] ||
      t(`${field}_default_content`, { lng: language })
    )
  }

  const customMessage = (
    type: Field,
    values: { nl: CustomMessages; en: CustomMessages },
    isSubmitting: boolean,
  ): JSX.Element => {
    return (
      <div css={CustomMessageStyles} key={type}>
        <h4>{t(type)}</h4>
        <p>{t(`${type}_details`)}</p>
        <Form>
          <a
            css={ButtonToggleStyles(company)}
            className={currentLanguage(type) == 'en' ? 'active' : ''}
            onClick={() => setLanguage(type, 'en')}
          >
            English
          </a>
          <a
            css={ButtonToggleStyles(company)}
            className={currentLanguage(type) == 'nl' ? 'active' : ''}
            onClick={() => setLanguage(type, 'nl')}
          >
            Nederlands
          </a>
          <TextareaField
            height='18rem'
            name={`${currentLanguage(type)}.${type}`}
          />
          <div
            className={
              values.nl[type] === defaultForField('nl', type) &&
              values.en[type] === defaultForField('en', type)
                ? 'action hidden'
                : 'action'
            }
          >
            <Button
              type='submit'
              size='small'
              value={t('action:save')}
              loading={isSubmitting}
            />
          </div>
        </Form>
      </div>
    )
  }

  return (
    <div>
      <Formik
        initialValues={{
          nl: fieldValues('nl'),
          en: fieldValues('en'),
          id: props.entity.id,
        }}
        onSubmit={(values, { setSubmitting }) => {
          props.handleSubmit(props.service.update(values))
          setSubmitting(false)
        }}
      >
        {({ values, isSubmitting }) => (
          <div>
            {fields.map((field) => customMessage(field, values, isSubmitting))}
          </div>
        )}
      </Formik>
    </div>
  )
}

export default CustomMessagesForm

const CustomMessageStyles = css`
  .action {
    margin-top: -1.5rem;
    opacity: 1;
    transition: 0.2s opacity ease-in-out;
    &.hidden {
      pointer-events: none;
      opacity: 0;
    }
  }
`
