/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { useRecoilValue } from 'recoil'
import { companyState } from '../../../Session'

import {
  Visitor,
  AppointmentFormResponse,
  Appointment,
  User,
  Tenant,
  Company,
} from '../../../types/Interfaces'
import AppointmentService from '../../../services/AppointmentService'
import UserService from '../../../services/UserService'
import TenantService from '../../../services/TenantService'

import VisitService from '../../../services/VisitService'
import * as Validator from '../../../shared/Validations'

import Step1 from './step1'
import Step2 from './step2'
import Step3 from './step3'
import Step4 from './step4'
import ThankYouPage from './ThankYouPage'

import {
  PageStyles,
  VisitorRegistrationFormStyles,
} from '../../../styles/container-styles'

import { PageWidth, InlineAlert } from '../../../components'

const VisitorRegistration = () => {
  const [visitor, setVisitor] = useState<Visitor>()
  const [appointments, setAppointments] = useState<Appointment[]>([])
  const [currentAppointment, setCurrentAppointment] = useState<Appointment>()
  const [users, setUsers] = useState<User[]>([])
  const [visitorEmail, setVisitorEmail] = useState<string>('')
  const [
    successfullyCreatedAppointment,
    setSuccessfullyCreatedAppointment,
  ] = useState(false)
  const [userId, setUserId] = useState<string>('')
  const [submissionError, setSubmissionError] = useState<string>('')
  const [step, _setStep] = useState(1)
  const [transitioning, setTransitioning] = useState(false)
  const [tenants, setTenants] = useState<Tenant[]>([])
  const [tenantsWithUsers, setTenantsWithUsers] = useState<Tenant[]>([])
  const company = useRecoilValue(companyState)

  const setStep = (step: number) => {
    // We need to set 'transitioning' before 'step' to prevent content jumping around during transition
    setTransitioning(true)
    _setStep(step)
  }

  useEffect(() => {
    location.hash = step.toString()
  }, [step])

  const nextStep = () => {
    setStep(step + 1)
  }

  const prevStep = () => {
    setStep(step - 1)
  }

  useEffect(() => {
    window.location.hash = ''
    getTenantsWithUsers()
  }, [])

  useEffect(() => {
    window.addEventListener('hashchange', () => {
      const historyStep = parseInt(window.location.hash.slice(1))
      // Disallow navigating to a previous step when the form has been completed
      if (step == 4) return
      // If the step matches the history do nothing
      if (step == historyStep) return
      // Disallow navigating forward to a step without completing the previous step
      if (step < historyStep) return
      if (historyStep) setStep(historyStep)
    })
  }, [step])

  useEffect(() => {
    if (visitor && company) {
      company.is_multi_tenant ? nextStep() : setStep(3)

      // In the future we perhaps want to support the use case
      // where a visitor may have multiple appointments on the same date
      if (appointments.length && appointments[0].user.id) {
        setStep(4)
        setUserId(appointments[0].user.id.toString())
      }
    }
  }, [visitor])

  const fetchAppointments = (visitorEmail: string) => {
    if (!company?.is_multi_tenant) {
      getEmployees()
    }
    setVisitorEmail(visitorEmail)
    AppointmentService.fetchAll(visitorEmail).then(
      (response: AppointmentFormResponse) => {
        setAppointments(response.appointments)
        setVisitor(response.visitor)
      },
    )
  }

  async function getTenantsWithUsers() {
    const response = await TenantService.fetchTenantsWithUsers()
    setTenantsWithUsers(response.tenants)
  }

  const createVisit = async (visitorPotentiallyExposed: boolean) => {
    await VisitService.create(
      appointments[0].id.toString(),
      !visitorPotentiallyExposed,
      visitorEmail,
    )
    setCurrentAppointment(appointments[0])
  }

  const createAppointment = async (visitorPotentiallyExposed: boolean) => {
    const response = await AppointmentService.create(visitorEmail, null, userId)
    setCurrentAppointment(response)

    await VisitService.create(
      response.id,
      !visitorPotentiallyExposed,
      visitorEmail,
    )
  }

  const visitorHasAppointments = () => {
    return appointments.length > 0
  }

  const completeRegistration = (visitorPotentiallyExposed: boolean) => {
    try {
      if (visitorHasAppointments()) {
        createVisit(visitorPotentiallyExposed)
      } else {
        createAppointment(visitorPotentiallyExposed)
      }
      setSuccessfullyCreatedAppointment(!visitorPotentiallyExposed)
      nextStep()
    } catch {
      setSubmissionError(Validator.unexpectedError)
    }
  }

  const getEmployees = async (tenant_id?: number) => {
    const response = await UserService.fetchAll(tenant_id)
    const employees = response.users
    setUsers(employees)
  }

  const setTenant = (companyOrTenant: Tenant | Company, tenant_id?: number) => {
    setTenants([companyOrTenant])
    getEmployees(tenant_id)
  }

  return (
    <section css={PageStyles()}>
      <PageWidth visitorRegistration={true}>
        <div css={VisitorRegistrationFormStyles(company)}>
          <div className='registration-container'>
            {submissionError && (
              <InlineAlert type='error' show={true} message={submissionError} />
            )}

            <CSSTransition
              in={!transitioning && step == 1}
              timeout={300}
              unmountOnExit
              classNames='step'
              onExited={() => setTransitioning(false)}
            >
              <Step1
                visitor={visitor}
                setAppointments={(visitorEmail: string) =>
                  fetchAppointments(visitorEmail)
                }
              />
            </CSSTransition>

            <CSSTransition
              in={!transitioning && step == 2}
              timeout={300}
              unmountOnExit
              classNames='step'
              onExited={() => setTransitioning(false)}
            >
              <Step2
                visitor={visitor}
                appointments={appointments}
                goToNextStep={nextStep}
                setTenant={setTenant}
                goToFirstStep={prevStep}
                tenantsWithUsers={tenantsWithUsers}
              />
            </CSSTransition>

            <CSSTransition
              in={!transitioning && step == 3}
              timeout={300}
              unmountOnExit
              classNames='step'
              onExited={() => setTransitioning(false)}
            >
              <Step3
                visitor={visitor}
                appointments={appointments}
                users={users}
                bufferAppointment={setUserId}
                tenants={tenants}
                goToNextStep={nextStep}
                goToSecondStep={prevStep}
              />
            </CSSTransition>

            <CSSTransition
              in={!transitioning && step == 4}
              timeout={300}
              unmountOnExit
              classNames='step'
              onExited={() => setTransitioning(false)}
            >
              <Step4 completeRegistration={completeRegistration} />
            </CSSTransition>

            <CSSTransition
              in={!transitioning && step == 5}
              timeout={300}
              unmountOnExit
              classNames='step'
              onExited={() => setTransitioning(false)}
            >
              <ThankYouPage
                successfullyCreatedAppointment={successfullyCreatedAppointment}
                appointment={currentAppointment}
              />
            </CSSTransition>
          </div>
        </div>
      </PageWidth>
    </section>
  )
}

export default withRouter(VisitorRegistration)
