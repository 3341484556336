/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../components'
import Theme from '../../../styles/theme'
import {
  Cough,
  Cold,
  Breath,
  Smell,
  Roommate,
  Temperature,
  Corona,
  Calendar,
  House,
} from '../../../styles/icons'

import { useRecoilValue } from 'recoil'
import { companyState } from '../../../Session'
import { Company } from '../../../types/Interfaces'

interface Props {
  completeRegistration: (visitorPotentiallyExposed: boolean) => void
}

const Step4 = ({ completeRegistration }: Props) => {
  const company = useRecoilValue(companyState)
  const { t } = useTranslation('visitor_form')

  return (
    <div css={Step3Styles(company)}>
      <div css={CurrentStepStyles(company)}>
        <h1>{t('health_statement')}</h1>
        <h4>{t('health_statement_text')}</h4>
        <div css={CoronaCheckListSyles(company)}>
          <div className='checklist-item'>
            <p>{t('did_you_have_any_symptoms')}</p>

            <div css={SymptomsStyles(company)}>
              <div className='symptom'>
                <Cough fill={Theme(company).colors.fontcolors.primary} />
                <label>{t('cough')}</label>
              </div>
              <div className='symptom'>
                <Cold fill={Theme(company).colors.fontcolors.primary} />
                <label>{t('cold_symptoms')}</label>
              </div>
              <div className='symptom'>
                <Temperature fill={Theme(company).colors.fontcolors.primary} />
                <label>{t('high_temperature')}</label>
              </div>
              <div className='symptom'>
                <Breath fill={Theme(company).colors.fontcolors.primary} />
                <label>{t('shortness_of_breath')}</label>
              </div>
              <div className='symptom'>
                <Smell fill={Theme(company).colors.fontcolors.primary} />
                <label>{t('loss_of_smell_or_taste')}</label>
              </div>
            </div>
          </div>
          <div className='checklist-item'>
            <p>{t('potentially_infected_housemate')}</p>
            <figure>
              <Roommate fill={Theme(company).colors.fontcolors.primary} />
            </figure>
          </div>
          <div className='checklist-item'>
            <p>{t('recently_diagnosed_with_covid')}</p>
            <figure>
              <Corona fill={Theme(company).colors.fontcolors.primary} />
            </figure>
          </div>
          <div className='checklist-item'>
            <p>{t('recently_diagnosed_housemate_with_covid')}</p>
            <figure>
              <Calendar fill={Theme(company).colors.fontcolors.primary} />
            </figure>
          </div>
          <div className='checklist-item'>
            <div>
              <p>{t('quarantined_because')}</p>
              <ul>
                <li>{t('because_in_contact_with_covid')}</li>
                <li>{t('because_returned_from_risk_area')}</li>
              </ul>
            </div>
            <figure>
              <House fill={Theme(company).colors.fontcolors.primary} />
            </figure>
          </div>
        </div>
        <div css={ButtonStyles(company)}>
          <Button
            type='submit'
            size='large'
            value=''
            disabled={false}
            onClick={() => completeRegistration(true)}
          >
            {t('yes')}
          </Button>
          <Button
            type='submit'
            size='large'
            value=''
            disabled={false}
            onClick={() => completeRegistration(false)}
          >
            {t('no')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Step4

const CoronaCheckListSyles = (company?: Company) => css`
  .checklist-item {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    align-items: center;
    background: ${Theme(company).colors.backgrounds.inner};
    border-radius: 2rem;
    padding: 3rem 2.4rem;
    margin-bottom: 2.2rem;
    box-shadow: ${Theme(company).colors.borders.mediumContainerShadow};
    @media ${Theme(company).breakpoints.medium} {
      flex-direction: row;
      padding: 3.5rem 5rem;
    }
    @media ${Theme(company).breakpoints.mediumDown} {
      margin-left: -5rem;
      margin-right: -5rem;
    }

    &:first-of-type {
      flex-direction: column;
      margin-top: 3.3rem;

      @media ${Theme(company).breakpoints.medium} {
        margin-top: 6.3rem;
      }
      > div {
        flex-direction: row;
        flex-wrap: wrap;
        .symptom {
          text-align: center;
          width: 50%;
          @media ${Theme(company).breakpoints.medium} {
            width: auto;
          }
        }
      }

      p {
        margin-right: 0;
      }
    }

    &:last-child {
      ul {
        margin: 1rem 0 1rem 3rem;
        list-style: disc;
      }
    }

    p,
    li {
      font-size: 1.8rem;
      letter-spacing: -0.025rem;
      line-height: 3rem;
      margin: 0;
      @media ${Theme(company).breakpoints.medium} {
        font-size: 2rem;
      }
    }

    p {
      text-align: center;
      @media ${Theme(company).breakpoints.medium} {
        margin-right: 8%;
        text-align: left;
      }
    }

    &:first-of-type p {
      text-align: center;
      margin-bottom: 2.5rem;
      @media ${Theme(company).breakpoints.medium} {
        margin-bottom: 4.5rem;
      }
    }

    figure {
      margin-bottom: 3rem;
      min-width: 6.1rem;
      @media ${Theme(company).breakpoints.medium} {
        margin-bottom: 0;
      }
      svg {
        display: block;
        max-height: 6.1rem;
        margin: 0 auto;
      }
    }
  }
`

const SymptomsStyles = (company?: Company) => css`
  display: flex;
  justify-content: space-between;
  max-width: 82rem;
  width: 100%;
  margin: 0 auto;

  .symptom {
    margin-bottom: 1rem;
    @media ${Theme(company).breakpoints.medium} {
      margin-bottom: 0;
    }
    svg {
      display: block;
      max-width: 5rem;
      margin: 0 auto;
      min-height: 6rem;
    }

    label {
      display: inline-block;
      max-width: 12rem;
      margin-top: 1.2rem;
      color: ${Theme(company).colors.fontcolors.body};
      font-size: 1.6rem;
      line-height: 2.4rem;
      text-align: center;
    }
  }
`

const Step3Styles = (company?: Company) => css`
  h1 {
    font-size: min(7vw, 4.5rem);
  }

  h4 {
    color: ${Theme(company).colors.fontcolors.body};
    font-size: 2rem;
    line-height: 3rem;
    font-weight: normal;
  }
`

const StepContainerStyles = (company?: Company) => css`
  margin-bottom: 4rem;
  @media ${Theme(company).breakpoints.medium} {
    margin-bottom: 7.7rem;
  }
`

const ButtonStyles = (company?: Company) => css`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 4rem;
  align-items: center;

  div:first-of-type {
    margin: 0 0 1.6rem 0;
  }

  @media ${Theme(company).breakpoints.medium} {
    margin-top: 7.7rem;
    flex-direction: row;
    div:first-of-type {
      margin: 0 2.6rem 0 0;
    }
  }

  button {
    width: 28.9rem !important;
    padding: 2rem !important;
  }
`

const CurrentStepStyles = (company?: Company) => css`
  background-color: ${Theme(company).colors.backgrounds.inner};
  margin-top: 1.6rem;
  @media ${Theme(company).breakpoints.medium} {
    margin-top: 7.6rem;
  }
`
