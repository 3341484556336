/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, css } from '@emotion/core'
import React from 'react'
import Theme from '../../../styles/theme'
import { Button } from '../../../components'
import { useRecoilValue } from 'recoil'
import { companyState } from '../../../Session'

import { useTranslation } from 'react-i18next'

import { Appointment, Company } from '../../../types/Interfaces'

import UserCard from './UserCard'

interface Props {
  successfullyCreatedAppointment: boolean
  appointment?: Appointment
}

const ThankYouPage = ({
  successfullyCreatedAppointment,
  appointment,
}: Props) => {
  const { t, i18n } = useTranslation('visitor_form')
  const company = useRecoilValue(companyState)

  const tenant = company?.tenants.find(
    (tenant) => tenant.id === appointment?.tenant_id,
  )

  const welcomeMessage = (): string => {
    const tenant = company?.tenants?.find(
      (tenant) => tenant.id === appointment?.tenant_id,
    )

    return (
      (tenant?.custom_messages &&
        tenant?.custom_messages[i18n.language as 'en' | 'nl']
          ?.registration_confirmation) ||
      (company?.custom_messages &&
        company?.custom_messages[i18n.language as 'en' | 'nl']
          ?.registration_confirmation) ||
      t('admin_settings:registration_confirmation_default_content')
    )
  }

  return (
    <div css={ThankYouStyles(company)}>
      {successfullyCreatedAppointment ? (
        <>
          <div className='thankyou-box'>
            <div className='left'>
              <img
                className='tenant-thankyou-logo'
                src={tenant?.logo}
                style={{ maxHeight: '100px' }}
              />
              <h2>{t('registration_completed')}</h2>
              <p>
                {welcomeMessage()
                  .split('%{employee}')
                  .join(appointment?.user.first_name || t('your_host'))
                  .split('%{company}')
                  .join(tenant?.name || company?.name)}
              </p>
            </div>
            <div className='right'>
              {appointment?.user.id && <UserCard user={appointment?.user} />}
            </div>
          </div>
        </>
      ) : (
        <>
          <h2>{t('registration_rejected')}</h2>
          <p>{t('registration_rejected_text')}</p>
        </>
      )}
      <div className='button-styles' css={ButtonStyles(company)}>
        <Button
          type='default'
          alignment='left'
          value={t('back_to_homepage')}
          onClick={() => (window.location.href = '/')}
        />
      </div>
    </div>
  )
}

export default ThankYouPage

const ThankYouStyles = (company?: Company) => css`
  .thankyou-box {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .left {
      max-width: 56rem;
    }

    .right {
      padding-left: 4rem;

      @media ${Theme(company).breakpoints.mediumDown} {
        padding: 0rem;
      }
    }

    @media ${Theme(company).breakpoints.mediumDown} {
      display: block;
      text-align: center;
      max-width: 28rem;
      margin: auto;

      img.tenant-thankyou-logo {
        margin: 5.8rem auto;
      }
    }
  }

  .tenant-thankyou-logo {
    max-width: 20rem;
    margin-bottom: 5.8rem;
  }

  h2 {
    font-size: 3.8rem;
    letter-spacing: -0.05rem;
    line-height: 3.8rem;
    font-weight: 300;
    margin-bottom: 3.3rem;
  }

  p {
    max-width: 65.4rem;
    color: ${Theme(company).colors.fontcolors.body};
    font-size: 2rem;
    line-height: 3rem;
    white-space: pre-wrap;
  }

  button {
    margin-top: 5rem;
  }
`
const ButtonStyles = (company?: Company) => css`
  @media ${Theme(company).breakpoints.mediumDown} {
    display: flex;
    justify-content: center;
  }
`
