/** @jsx jsx */
import { jsx, css } from '@emotion/core'

import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

import { PageWidth, Logo, LanguageSelector } from '../../components'
import Theme from '../../styles/theme'

const Home = (): JSX.Element => {
  const { t } = useTranslation('homepage')

  return (
    <PageWidth wide>
      <header css={headerStyles}>
        <NavLink activeClassName='active' exact to='/'>
          <Logo />
        </NavLink>
        <div className='login'>
          <NavLink activeClassName='active' exact to='/users/sign_in'>
            <span>{t('sign_in')}</span>
            <FontAwesomeIcon
              color={Theme().colors.interaction.success}
              icon={['fas', 'sign-in']}
            />
          </NavLink>
          <LanguageSelector />
        </div>
      </header>
    </PageWidth>
  )
}

export default Home

const headerStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.8rem 0;

  .login {
    display: flex;
    align-items: center;
  }

  a {
    display: flex;
    align-items: center;
    span {
      color: ${Theme().colors.fontcolors.heading};
      margin-right: 0.8rem;
    }
  }
`
