/** @jsx jsx */
import { jsx } from '@emotion/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Theme from '../styles/theme'

import { useRecoilValue } from 'recoil'
import { companyState } from '../Session'

type HealthCheckIconProps = {
  healthy?: boolean | string | null
}

const HealthCheckIcon = ({ healthy }: HealthCheckIconProps) => {
  const company = useRecoilValue(companyState)

  if (healthy === 'true' || healthy === true) {
    return (
      <FontAwesomeIcon
        color={Theme(company).colors.interaction.success}
        icon={['fas', 'check-circle']}
      />
    )
  } else if (healthy === 'false' || healthy === false) {
    return (
      <FontAwesomeIcon
        color={Theme(company).colors.interaction.failure}
        icon={['far', 'exclamation-circle']}
      />
    )
  } else {
    return (
      <FontAwesomeIcon
        color={Theme(company).colors.fontcolors.gray}
        icon={['far', 'question-circle']}
      />
    )
  }
}

export default HealthCheckIcon
