/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { PageWidth } from '../../components'
import Header from './Header'
import DutchPrivacyPolicy from './privacy_policy/nl'
import EnglishPrivacyPolicy from './privacy_policy/en'

const PrivacyPolicy = (): JSX.Element => {
  const { i18n } = useTranslation()
  return (
    <div css={PageStyles}>
      <Header />
      <PageWidth css={PrivacyPolicyStyles}>
        {i18n.language == 'nl' ? (
          <DutchPrivacyPolicy />
        ) : (
          <EnglishPrivacyPolicy />
        )}
      </PageWidth>
    </div>
  )
}

export default PrivacyPolicy

const PageStyles = css`
  .page-width {
    max-width: 112rem;
  }
`

const PrivacyPolicyStyles = css`
  padding-top: 6rem;
  padding-bottom: 6rem;

  h2 {
    margin-top: 4rem;
  }
  ul {
    list-style: disc;
    margin-left: 1.8rem;
  }
`
