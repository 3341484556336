/* eslint-disable react/jsx-key */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, css } from '@emotion/core'
import React, { useEffect, useMemo, useState } from 'react'
import useStateSafe from '../../helpers/use-state-safe'
import Theme from '../../styles/theme'
import Modal from 'react-modal'
import { useTranslation } from 'react-i18next'
import { useAlert } from 'react-alert'
import { Row } from 'react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  PageWidth,
  Button,
  InlineAlert,
  Spinner,
  Table,
} from '../../components'
import UserService from '../../services/UserService'
import { User, Company } from '../../types/Interfaces'
import UserInvitation from '../../pages/authentication/user/UserInvitation'

import { useRecoilState } from 'recoil'
import { companyState, userState } from '../../Session'

import * as Validator from '../../shared/Validations'

import { ModalStyles } from '../../styles/modal-styles'
import { useLanguage } from '../../hooks/language'

function hasTenants(company?: Company): boolean {
  return (
    company !== undefined &&
    company.is_multi_tenant &&
    company.tenants.length > 0
  )
}

const AdminUsers = (): JSX.Element => {
  const { t } = useTranslation('admin_users')
  const [users, setUsers] = useStateSafe<User[]>([])
  const [addingNewUser, setAddingNewUser] = useStateSafe(false)
  const [loaded, setLoaded] = useStateSafe(false)
  const [error, setError] = useStateSafe(false)
  const [company] = useRecoilState(companyState)
  const [user] = useRecoilState(userState)
  const [language] = useLanguage()
  const alert = useAlert()

  useEffect(() => {
    fetchUsers()
  }, [])

  function canDeleteUser(otherUser: User) {
    if (otherUser.id === user?.id) return false
    if (user?.account_type === 'admin') return true
    if (
      user?.account_type === 'global' &&
      ['individual', 'pending'].includes(otherUser.account_type)
    ) {
      return true
    }
    return false
  }

  function canChangeAccountType(otherUser: User) {
    if (otherUser.id === user?.id) return false
    return (
      user &&
      ['admin', 'global'].includes(user.account_type) &&
      (otherUser.account_type == 'individual' ||
        user.account_type == 'admin') && // Recipionists can only change individuals
      otherUser.account_type !== 'pending'
    )
  }

  function isCompanyUser(otherUser: User) {
    return !otherUser.tenant_id
  }

  function updateUsersList(updatedUser: User) {
    const newUsers = users.map((user: User) => {
      if (user.id === updatedUser.id) {
        return updatedUser
      } else {
        return user
      }
    })
    setUsers(newUsers as User[])
  }

  const updateUser = async (otherUser: User, values: DeepPartial<User>) => {
    try {
      const response = await UserService.update(
        Object.assign(
          {
            id: otherUser.id,
          },
          values,
        ),
      )
      alert.show(t('user_updated'))
      updateUsersList(response.user)
    } catch {
      alert.show(t('tenant_receptionist_warning'), { type: 'error' })
    }
  }

  async function deleteUser(otherUser: User) {
    if (confirm(t('user_delete_confirmation'))) {
      await UserService.delete(otherUser)
      fetchUsers()
      alert.show(t('user_deleted'))
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: t('field:name'),
        accessor: 'full_name',
        // NOTE: Sort by last name, but it at the moment it will sort
        // first on prefixes like 'de', 'van', etc.
        sortType: ({ original: a }: Row<User>, { original: b }: Row<User>) => {
          return a.last_name.localeCompare(b.last_name, language)
        },
      } as const,
      {
        Header: t('field:email'),
        accessor: 'email',
      } as const,
      {
        Header: t('field:phone'),
        accessor: 'phone',
      } as const,
      {
        Header: t('field:account_type'),
        accessor: 'account_type',
        Cell({
          value,
          row: { original: otherUser },
        }: {
          row: Row<User>
          value: string
        }) {
          if (canChangeAccountType(otherUser)) {
            return (
              <select
                className='account-type'
                onChange={(event) =>
                  updateUser(otherUser, {
                    account_type: event.target.value as
                      | 'individual'
                      | 'global'
                      | 'admin',
                  })
                }
                defaultValue={otherUser.account_type}
              >
                <option value='admin'>{t('account_type:admin')}</option>
                <option value='individual'>
                  {t('account_type:individual')}
                </option>
                {isCompanyUser(otherUser) && (
                  <option value='global'>{t('account_type:global')}</option>
                )}
              </select>
            )
          } else {
            return t('account_type:' + value)
          }
        },
      } as const,
      {
        id: 'tenant',
        accessor: 'tenant_id',
        Header: t('tenant'),
        Cell({ row: { original: otherUser } }: { row: Row<User> }) {
          if (canChangeAccountType(otherUser)) {
            return (
              user?.account_type !== 'individual' && (
                <select
                  className='tenant'
                  style={{ maxWidth: '100%' }}
                  onChange={(event) =>
                    updateUser(otherUser, {
                      tenant_id: parseInt(event.target.value),
                    })
                  }
                  defaultValue={otherUser.tenant_id}
                  disabled={otherUser.account_type === 'global'}
                >
                  <option value=''></option>
                  {(company?.tenants || []).map((tenant) => (
                    <option key={tenant.id} value={tenant.id}>
                      {tenant.name}
                    </option>
                  ))}
                </select>
              )
            )
          } else {
            return (
              <span>
                {
                  (company?.tenants || []).find(
                    (tenant) => otherUser.tenant_id == tenant.id,
                  )?.name
                }
              </span>
            )
          }
        },
      } as const,
      {
        id: 'options',
        Header: '',
        Cell({ row: { original: otherUser } }: { row: Row<User> }) {
          return (
            canDeleteUser(otherUser) && (
              <FontAwesomeIcon
                color={Theme(company).colors.fontcolors.body}
                className='trash'
                icon={['fas', 'trash-alt']}
                onClick={() => deleteUser(otherUser)}
              />
            )
          )
        },
      } as const,
    ],
    [language, users],
  )

  const fetchUsers = async (delay = true) => {
    const startTime = Date.now()
    setError(false)
    try {
      const response = await UserService.fetchAll()

      if (delay) {
        setTimeout(() => {
          setUsers(response.users)
          setLoaded(true)
        }, Math.max(500 - (Date.now() - startTime), 0))
      } else {
        setUsers(response.users)
        setLoaded(true)
      }
    } catch {
      setError(true)
      setLoaded(true)
    }
  }

  const BaseContent = (
    props: React.PropsWithChildren<unknown>,
  ): JSX.Element => {
    return (
      <section css={DashboardStyles(company)}>
        <PageWidth>
          <header>
            <h1>{t('users')}</h1>
            <Button
              type='default'
              size='default'
              value=''
              onClick={() => setAddingNewUser(true)}
            >
              <div>
                {t('add_new_user')}
                <FontAwesomeIcon icon={['fas', 'user-plus']} />
              </div>
            </Button>
          </header>
          {props.children}
        </PageWidth>

        <Modal
          isOpen={addingNewUser}
          onRequestClose={() => setAddingNewUser(false)}
          ariaHideApp={false}
          style={ModalStyles(company)}
          parentSelector={() => document.body}
        >
          <UserInvitation
            onNewUser={async () => {
              await fetchUsers(false)
              setAddingNewUser(false)
            }}
          />
        </Modal>
      </section>
    )
  }

  if (!loaded) {
    return (
      <BaseContent>
        <Spinner size='large' />
      </BaseContent>
    )
  } else if (error) {
    return (
      <BaseContent>
        <InlineAlert
          type='error'
          show={true}
          message={Validator.unexpectedError}
        />
      </BaseContent>
    )
  } else {
    return (
      <BaseContent>
        <Table
          rows={users}
          sortBy={[{ id: 'full_name' }]}
          columns={columns}
          hiddenColumns={
            hasTenants(company) && !user?.tenant_id ? [] : ['tenant']
          }
          search={true}
          grid={`3fr 3fr 2fr 2fr ${
            hasTenants(company) && !user?.tenant_id ? '2fr' : ''
          } 1fr`}
        />
      </BaseContent>
    )
  }
}

export default AdminUsers

const DashboardStyles = (company?: Company) => css`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6rem;

    @media ${Theme(company).breakpoints.mobileDown} {
      flex-direction: column;
      margin-bottom: 4rem;
    }

    h1 {
      margin: 0;
      @media ${Theme(company).breakpoints.smallDown} {
        margin-bottom: 3rem;
      }
    }

    button {
      font-size: 1.6rem;
    }
  }
`
