/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React from 'react'
import { jsx, css } from '@emotion/core'
import Theme from '../styles/theme'
import { supportedLanguages, useLanguage } from '../hooks/language'

import { useRecoilValue } from 'recoil'
import { companyState } from '../Session'
import { Company } from '../types/Interfaces'

const LanguageFlagSelector = (): JSX.Element => {
  const [selected, setLanguage] = useLanguage()
  const company = useRecoilValue(companyState)

  return (
    <div css={styles(company)}>
      {supportedLanguages.map((lang) => (
        <div
          key={lang}
          className={lang == selected ? 'selected' : ''}
          onClick={() => setLanguage(lang)}
        >
          <div style={{ backgroundImage: `url(/flag-${lang}.svg)` }}>
            {lang}
          </div>
        </div>
      ))}
    </div>
  )
}

export default LanguageFlagSelector

const styles = (company?: Company) => css`
  display: flex;
  margin: 5rem 0;
  justify-content: center;

  > div {
    border: 0.3rem solid transparent;
    border-radius: 0.8rem;

    > div {
      margin: 1.2rem;
      border-radius: 0.4rem;
      width: 8.4rem;
      height: 5.1rem;
      background-repeat: no-repeat;
      background-position: center;
      color: transparent;
      cursor: pointer;
      line-height: 0;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      opacity: 0.25;
    }

    &.selected {
      border-color: ${Theme(company).colors.fontcolors.primary};
    }

    &.selected,
    &:hover {
      > div {
        opacity: 1;
      }
    }
  }

  @media ${Theme(company).breakpoints.mobileDown} {
    margin: 1rem 0;

    > div {
      border-width: 0.15rem;
      border-radius: 0.4rem;

      > div {
        margin: 0.6rem;
        width: 4.2rem;
        height: 2.55rem;
        background-size: contain;
      }
    }
  }
`
