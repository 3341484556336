/** @jsx jsx */
import { css } from '@emotion/core'

import Theme from './theme'
import { Company } from '../types/Interfaces'

export const ModalStyles = (company?: Company) => {
  return {
    overlay: {
      backgroundColor: 'rgba(72, 84, 100, 0.6)',
      zIndex: 99,
    },
    content: {
      padding: '3rem',
      backgroundColor: Theme(company).colors.backgrounds.inner,
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      maxHeight: '80vh',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      boxShadow: '0.4rem 0.8rem 2rem rgba(0,0,0,0.15)',
    },
  }
}

export const DeleteEntityStyles = css`
  cursor: pointer;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
`
