import axios from 'axios'

import { CustomMessages, CompanyColors } from '../types/Interfaces'
import { convertToFormdata } from '../shared/FormDataConverter'

interface Props extends DeepPartial<CompanyColors> {
  logo?: File
  language?: 'en' | 'nl'
  nl?: CustomMessages
  en?: CustomMessages
  theme?: string
  is_multi_tenant?: 'true' | 'false'
  send_review_request?: 'true' | 'false'
}

class CompanyService {
  async get() {
    return axios.get(`/api/company`).then((response) => response.data)
  }

  async update(company: Props) {
    return axios
      .patch(`/api/company`, convertToFormdata(company, 'company'))
      .then((response) => response.data)
  }

  async addDomain(type: 'company' | 'tenant', id: number, domain: string) {
    return axios
      .post(
        `/api/company/whitelisted_domains`,
        Object.assign(
          { domain: domain },
          type === 'tenant' ? { tenant_id: id } : {},
        ),
      )
      .then((response) => response.data)
  }

  async deleteDomain(domainId: number) {
    return axios
      .delete(`/api/company/whitelisted_domains/${domainId}`)
      .then((response) => response.data)
  }
}

export default new CompanyService()
