/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import Dropzone from 'react-dropzone'
import 'react-image-crop/lib/ReactCrop.scss'

import { useRecoilState } from 'recoil'
import { userState } from '../../../Session'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Theme from '../../../styles/theme'

import { Company } from '../../../types/Interfaces'
import { useRecoilValue } from 'recoil'
import { companyState } from '../../../Session'

interface Props {
  triggerCropPhoto: () => void
  setImage: (image: File) => void
}

const UploadPhoto = (props: Props) => {
  const [user] = useRecoilState(userState)
  const company = useRecoilValue(companyState)

  const upload = ([file]: File[]) => {
    props.triggerCropPhoto()
    props.setImage(file)
  }

  return (
    <div className='dropzone-portrait' css={ProfilePicStyles(company)}>
      <Dropzone onDrop={upload} multiple={false}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()} className='photo-input-field'>
              <input {...getInputProps()} />
              <div className='dropzone'>
                <div className='camera-box'>
                  <FontAwesomeIcon
                    className='camera'
                    icon={['fas', 'camera']}
                  />
                </div>
                <img src={user?.portrait ? user.portrait : '/silhouette.png'} />
              </div>
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  )
}

const ProfilePicStyles = (company?: Company) => css`
  max-width: 10.5rem;
  outline: none;
  margin: auto;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  img {
    border-radius: 50%;
    border: 0.4rem solid #ffffff;
    box-shadow: 0 0.1rem 2rem 0 rgba(0, 0, 0, 0.1);
    height: 10.4rem;
    width: 10.4rem;
    margin: 0 auto;
    z-index: 0;
    position: relative;
  }

  .photo-input-field:focus {
    outline: none;
  }

  .dropzone {
    position: relative;
    width: fit-content;

    .camera-box {
      position: absolute;
      z-index: 1;
      padding: 0.7rem;
      background: white;
      border-radius: 50%;
      bottom: 1rem;
      right: -0.4rem;
      padding: 0.5rem 0.8rem 0.4rem 0.8rem;
      text-align: center;
    }

    .camera {
      color: ${Theme(company).colors.fontcolors.primary};
      background: white;
      z-index: 1;
    }

    .green-banner {
      width: 100%;
      height: 10.4rem;
      background-color: #edf8ec;
      position: absolute;
    }
  }
`

export default UploadPhoto
