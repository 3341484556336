import axios from 'axios'
import { Tenant } from '../types/Interfaces'
import { convertToFormdata } from '../shared/FormDataConverter'

class TenantService {
  async fetchTenantsWithUsers() {
    return axios.get(`/api/tenants`).then((response) => response.data)
  }

  async delete(tenant: Tenant) {
    return axios
      .delete(`/api/tenants/${tenant.id}`)
      .then((response) => response.data)
  }

  async create(values: DeepPartial<Tenant>) {
    return axios
      .post(`/api/tenants`, { tenant: values })
      .then((response) => response.data)
  }

  async update(values: DeepPartial<Tenant>) {
    return axios
      .put(`/api/tenants/${values.id}`, { tenant: values })
      .then((response) => response.data)
  }

  async updateLogo(file: File, tenant: DeepPartial<Tenant>) {
    return axios
      .patch(
        `/api/tenants/${tenant.id}`,
        convertToFormdata({ logo: file }, 'tenant'),
      )
      .then((response) => response.data)
  }
}

export default new TenantService()
