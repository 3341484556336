/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'
import { Field, FieldProps } from 'formik'
import Theme from '../../styles/theme'

// Not needed yet, and it takes up unnecessary space in the CustomMessagesForm
// import FieldCaptionOrError from './FieldCaptionOrError'

import FieldLabel from './FieldLabel'

import { useRecoilValue } from 'recoil'
import { companyState } from '../../Session'
import { Company } from '../../types/Interfaces'

interface Props {
  label?: string
  name: string
  caption?: string | JSX.Element
  disabled?: boolean
  height?: string
  placeholder?: string
  onChange?: (value: string | number) => void
}

const Textarea = (props: Props) => {
  const company = useRecoilValue(companyState)
  const customOnChange = {
    ...(props.onChange && { onChange: props.onChange }),
  }

  return (
    <Field name={props.name} {...customOnChange}>
      {({ field, meta }: FieldProps) => (
        <div
          css={TextareaStyles(props, company)}
          // Have to use classes, conditional Styles causes issues
          className={`${props.caption ? 'has-caption' : ''} ${
            meta.touched && meta.error ? 'has-error' : ''
          }`}
        >
          {props.label && <FieldLabel>{props.label}</FieldLabel>}
          <textarea
            {...field}
            disabled={!!props.disabled}
            placeholder={props.placeholder}
          />
          {/* <FieldCaptionOrError caption={props.caption} error={meta.error} /> */}
        </div>
      )}
    </Field>
  )
}

export default Textarea

const TextareaStyles = (props: Props, company?: Company) => css`
  position: relative;
  margin: 1.8rem 0 2.3rem;

  textarea {
    margin: 0.5rem 0 0;
    font-size: ${props.placeholder ? '1.6rem' : '1.3rem'};
    line-height: 2.1rem;
    font-weight: ${props.placeholder ? '300' : '400'};
    padding: 1.5rem;
    border-radius: 1rem;
    border: 0.1rem solid ${Theme(company).colors.backgrounds.outer};
    background: ${Theme(company).colors.backgrounds.outer};
    color: ${Theme(company).colors.fontcolors.body};
    width: 100%;
    height: ${props.height || '9rem'};
    resize: vertical;
    transition: 0.2s padding ease-in-out;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: #070707 !important;
    }

    &:hover,
    &:focus {
      outline: 0;
    }

    &[type='time'] {
      width: 8rem;
    }
  }

  &.has-caption,
  &.has-error {
    input {
      margin: 1.8rem 0 0.3rem;
    }
  }
  &.has-error {
    textarea {
      border-color: ${Theme(company).colors.borders.error};
    }

    label {
      color: ${Theme(company).colors.fontcolors.error};
    }
  }
`
