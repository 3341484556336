/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AlertProviderProps } from 'react-alert'

import { useRecoilValue } from 'recoil'
import { companyState } from '../Session'

import Theme from '../styles/theme'

import { Company } from '../types/Interfaces'

function alertIcon(type?: 'success' | 'info' | 'error'): JSX.Element {
  switch (type) {
    case 'success':
      return (
        <FontAwesomeIcon
          color={Theme().colors.interaction.success}
          icon={['fas', 'check-circle']}
        />
      )
    case 'error':
      return (
        <FontAwesomeIcon
          color={Theme().colors.interaction.failure}
          icon={['far', 'exclamation-circle']}
        />
      )
    default:
      return (
        <FontAwesomeIcon
          color={Theme().colors.borders.blue}
          icon={['far', 'exclamation-circle']}
        />
      )
  }
}

const TemplateElement = ({ message, options, close }: any) => {
  const company = useRecoilValue(companyState)

  return (
    <div css={AlertStyles(company)}>
      <div css={ContentWrapperStyles}>
        <div css={IconStyles}>{alertIcon(options.type)}</div>
        <div css={MessageStyles}>{message}</div>
      </div>
      <div onClick={close} css={CloseButtonStyles(company)}>
        <svg
          fill={Theme(company).colors.fontcolors.body}
          xmlns='http://www.w3.org/2000/svg'
          height='25'
          width='25'
          viewBox='0 0 48 48'
        >
          <path d='M38 12.83l-2.83-2.83-11.17 11.17-11.17-11.17-2.83 2.83 11.17 11.17-11.17 11.17 2.83 2.83 11.17-11.17 11.17 11.17 2.83-2.83-11.17-11.17z' />
          <path d='M0 0h48v48h-48z' fill='none' />
        </svg>
      </div>
    </div>
  )
}

const AlertTemplate: AlertProviderProps = {
  template: TemplateElement,
  type: 'success',
  timeout: 7000,
  containerStyle: {
    top: '8rem',
    left: 'calc(50% - 17.5rem)',
    width: 'unset',
    pointerEvents: 'unset',
  },
}

export default AlertTemplate

const AlertStyles = (company?: Company) => css`
  background-color: ${Theme(company).colors.backgrounds.inner};
  color: ${Theme(company).colors.fontcolors.body};
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0.5rem 0.8rem 0 rgba(0, 0, 0, 0.3);
  width: 35rem;
  max-width: 90vw;
  box-sizing: border-box;
  position: relative;
`

const ContentWrapperStyles = css`
  padding: 1rem 6rem 1rem 1rem;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`

const IconStyles = css`
  display: flex;
  justify-content: center;
  width: 5rem;
  font-size: 2.5rem;
`

const MessageStyles = css`
  flex: 3;
  text-align: center;
  width: 100%;
`

const CloseButtonStyles = (company?: Company) => css`
  min-width: 5rem;
  height: 100%;
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: ${Theme(company).colors.backgrounds.inner};
  }
`
