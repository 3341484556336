/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, css } from '@emotion/core'
import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { NewCompany } from '../../../types/Interfaces'

import Theme from '../../../styles/theme'

import * as Validator from '../../../shared/Validations'

import { useRecoilValue } from 'recoil'
import { companyState } from '../../../Session'

import { LanguageSelector } from '../../../components'
import {
  PageStyles,
  MainStyles,
  ActionBarStyles,
  SubmissionSucceededStyles,
} from '../../../styles/container-styles'

import {
  Logo,
  Button,
  InlineAlert,
  InputField,
  InputGroup,
  PageWidth,
} from '../../../components'
import AuthenticationService from '../../../services/AuthenticationService'

const newFormValues = {
  email: '',
  password: '',
  company_name: '',
  first_name: '',
  last_name: '',
  language: 'en',
  phone: '',
}

const CompanyRegistration = (): JSX.Element => {
  const [attemptedSubmission, setAttemptedSubmission] = useState(false)
  const [submissionSucceeded, setSubmissionSucceeded] = useState(false)
  const [submissionError, setSubmissionError] = useState<string | undefined>()
  const company = useRecoilValue(companyState)
  const { t, i18n } = useTranslation('company_registration')

  const domain = (company: string): string => {
    if (company.length === 0) return t('company_name_link')
    return company
      .toLowerCase()
      .replace(/[^a-z0-9\-_]+/g, '-')
      .replace(/^[-\s]+|[-\s]+$/g, '')
      .replace(/-[-\s]*-/g, ',')
  }

  return (
    <section css={PageStyles(company)}>
      <PageWidth css={PageWidthStyles} wide>
        <div css={FormStyles}>
          <LanguageSelector />
          <div className='image'>
            <figure style={{ backgroundImage: `url(/visitors.jpg)` }} />
          </div>
          <div className='form-container' css={MainStyles}>
            <Logo />

            <Formik
              initialValues={newFormValues}
              validateOnChange={attemptedSubmission}
              validateOnBlur={attemptedSubmission}
              validate={(values) => {
                let error: string | undefined
                const errors: DeepPartial<NewCompany> = {}

                // TODO: How can we make this DRY while keeping TypeScript happy?
                if ((error = Validator.companySignupEmail(values.email)))
                  errors.email = error

                if ((error = Validator.password(values.password)))
                  errors.password = error

                if ((error = Validator.emptyField(values.company_name)))
                  errors.company_name = error

                if ((error = Validator.firstName(values.first_name)))
                  errors.first_name = error

                if ((error = Validator.lastName(values.last_name)))
                  errors.last_name = error

                if ((error = Validator.phone(values.phone)))
                  errors.phone = error

                return errors
              }}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmissionError(undefined)
                try {
                  values.language = i18n.language
                  const response = await AuthenticationService.registerCompany(
                    values,
                  )

                  if (response.success) {
                    setSubmissionSucceeded(true)
                  } else {
                    setSubmissionError(Validator.formatErrors(response))
                  }
                } catch (err) {
                  setSubmissionError(Validator.unexpectedError)
                }
                setSubmitting(false)
              }}
            >
              {({ values, isSubmitting }) => (
                <Form>
                  {!submissionSucceeded && (
                    <>
                      <InputGroup>
                        <InputField
                          type='text'
                          label={t('field:first_name')}
                          name='first_name'
                        />

                        <InputField
                          type='text'
                          label={t('field:last_name')}
                          name='last_name'
                        />
                      </InputGroup>

                      <InputField
                        type='text'
                        label={t('field:email')}
                        name='email'
                      />

                      <InputField
                        type='tel'
                        label={t('field:mobile')}
                        name='phone'
                      />

                      <InputField
                        type='text'
                        label={t('field:company_name')}
                        name='company_name'
                      />

                      <InputField
                        type='password'
                        label={t('field:password')}
                        name='password'
                        autoComplete='new-password'
                      />

                      <h5>{t('registration_link')}</h5>
                      <div className='sub-domain'>
                        <FontAwesomeIcon icon={['fas', 'globe']} />

                        <span>
                          <span>arrived.at/</span>
                          <span className='domain'>
                            {domain(values.company_name)}
                          </span>
                        </span>
                      </div>

                      <div className='action-bar' css={ActionBarStyles}>
                        <Button
                          type='submit'
                          value={t('register_free')}
                          loading={isSubmitting}
                          onClick={() => setAttemptedSubmission(true)}
                        />
                      </div>

                      {submissionError && (
                        <InlineAlert
                          type='error'
                          show={true}
                          message={submissionError}
                        />
                      )}
                    </>
                  )}

                  {submissionSucceeded && (
                    <div css={SubmissionSucceededStyles(company)}>
                      <h2>{t('registration_succeeded')}</h2>
                      <img src='/email.svg' />
                      <span>{t('confirmation_email_sent')}</span>
                      <span className='email'>{values.email}</span>
                      {/* <Button
                        type='default'
                        value=''
                        onClick={() =>
                          (window.location.href = `https://${domain(
                            values.company_name,
                          )}.arrived.at/admin`)
                        }
                      >
                        <div>
                          {t('go_to_dashboard')}
                          <FontAwesomeIcon icon={['far', 'angle-right']} />
                        </div>
                      </Button> */}
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </PageWidth>
    </section>
  )
}

export default CompanyRegistration

const FormStyles = css`
  display: flex;
  box-shadow: 0 0 2rem 0 rgba(198, 198, 198, 0.2);
  @media ${Theme().breakpoints.mediumDown} {
    box-shadow: none;
  }

  .language-selector {
    position: absolute;
    z-index: 1;
    right: 4rem;
    top: 2rem;
    @media ${Theme().breakpoints.mediumDown} {
      right: 1rem;
    }
  }

  .form-container {
    min-width: 62rem;
    min-height: 83.5rem;
    width: 50%;
    padding: 7rem 9.4rem 5rem;
    background: ${Theme().colors.backgrounds.inner};

    @media ${Theme().breakpoints.mediumDown} {
      min-width: 0;
      width: 100%;
      padding: 7rem 2em;
    }

    h4 {
      text-align: center;
      font-size: 2.6rem;
      letter-spacing: 0.034rem;
      line-height: 2.4rem;
      font-weight: 300;
      margin-bottom: 6.6rem;
    }

    h5 {
      margin-top: 4.5rem;
      font-size: 2rem;
      letter-spacing: -0.026rem;
      line-height: 3rem;
    }

    .action-bar {
      margin-top: 4.4rem;
      div {
        justify-content: center;
      }
    }
    .sub-domain {
      display: flex;
      align-items: center;
      border-radius: 1rem;
      background-color: ${Theme().colors.backgrounds.outer};
      padding: 1.1rem 2rem;
      margin-bottom: 3.5rem;
      font-weight: normal;

      svg {
        margin-right: 1rem;
      }

      span:first-of-type {
        font-weight: 300;
      }

      .domain {
        color: ${Theme().colors.fontcolors.primary};
      }
    }
  }

  .image {
    width: 50%;
    @media ${Theme().breakpoints.mediumDown} {
      display: none;
    }
    figure {
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: 50%;
    }
  }
  figure.logo {
    max-width: 50%;
    margin: 0 auto 4rem;
    max-width: 18rem;
  }
`
const PageWidthStyles = css`
  @media ${Theme().breakpoints.mediumDown} {
    padding: 0;
  }
`
