/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useLayoutEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import qs from 'qs'
import { useRecoilState } from 'recoil'
import { userState, companyState } from '../../../Session'
import { useTranslation } from 'react-i18next'

import {
  PageStyles,
  ContainerStyles,
  MainStyles,
  ActionBarStyles,
} from '../../../styles/container-styles'

import { InlineAlert, Button, InputField, Logo } from '../../../components'
import AuthenticationService from '../../../services/AuthenticationService'

import { FormResponse, User } from '../../../types/Interfaces'

import * as Validator from '../../../shared/Validations'

const UserResetPassword = (props: RouteComponentProps) => {
  const { t } = useTranslation('user_authentication')
  const [company] = useRecoilState(companyState)
  const [user] = useRecoilState(userState)
  const [attemptedSubmission, setAttemptedSubmission] = useState(false)
  const [submissionError, setSubmissionError] = useState<string | undefined>()

  useLayoutEffect(() => {
    if (user) props.history.push('/admin')
  }, [])

  return (
    <section css={PageStyles(company)}>
      <div css={ContainerStyles('small', company)}>
        <div css={MainStyles}>
          <Logo url={company?.logo} />
          <h4>{t('reset_password')}</h4>
          <p>{t('reset_password_confirm_details')}</p>

          <Formik
            initialValues={{
              password: '',
              reset_password_token: qs.parse(props.location.search, {
                ignoreQueryPrefix: true,
              }).reset_password_token,
            }}
            validateOnChange={attemptedSubmission}
            validateOnBlur={attemptedSubmission}
            validate={(values) => {
              let error: string | undefined
              const errors: DeepPartial<User> = {}
              if ((error = Validator.password(values.password)))
                errors.password = error

              return errors
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmissionError(undefined)
              try {
                const response: FormResponse = await AuthenticationService.changePassword(
                  values,
                )
                if (response.success) {
                  window.location.href = '/admin'
                } else {
                  setSubmissionError(response.errors)
                }
              } catch {
                setSubmissionError(Validator.unexpectedError)
              }
              setSubmitting(false)
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <InputField
                  type='hidden'
                  label='Token'
                  name='reset_password_token'
                />
                <InputField
                  type='password'
                  label={t('new_password')}
                  name='password'
                  autoComplete='new-password'
                />

                <div css={ActionBarStyles}>
                  <Button
                    type='submit'
                    value={t('action:submit')}
                    loading={isSubmitting}
                    onClick={() => setAttemptedSubmission(true)}
                  />
                </div>

                <InlineAlert
                  type='error'
                  show={!!submissionError}
                  message={submissionError}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </section>
  )
}

export default withRouter(UserResetPassword)
