/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useState } from 'react'
import { Formik, Form } from 'formik'
import { useAlert } from 'react-alert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Visitor, FormResponse } from '../../types/Interfaces'

import * as Validator from '../../shared/Validations'

import Theme from '../../styles/theme'
import { ActionBarStyles } from '../../styles/container-styles'
import { DeleteEntityStyles } from '../../styles/modal-styles'

import { useRecoilValue } from 'recoil'
import { companyState } from '../../Session'

import { Button, InlineAlert, InputField, InputGroup } from '../../components'
import VisitorService from '../../services/VisitorService'
import { useTranslation } from 'react-i18next'

interface Props {
  onVisitorChange: () => void
  visitor: Visitor | null
}

const VisitorForm = (props: Props) => {
  const { t } = useTranslation('admin_visitors')
  const [attemptedSubmission, setAttemptedSubmission] = useState(false)
  const [submissionError, setSubmissionError] = useState<string | undefined>()
  const company = useRecoilValue(companyState)
  const alert = useAlert()

  const newFormValues: DeepPartial<Visitor> = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
  }

  async function deleteVisitor(setSubmitting: (isSubmitting: boolean) => void) {
    if (confirm(t('visitor_delete_confirmation'))) {
      setSubmitting(true)

      setSubmissionError(undefined)

      if (props.visitor) {
        try {
          await VisitorService.delete(props.visitor.id)
          alert.show(t('visitor_deleted'))
          props.onVisitorChange()
        } catch {
          setSubmissionError(Validator.unexpectedError)
        }
      }

      setSubmitting(false)
    }
  }

  return (
    <section>
      <div>
        <div>
          <Formik
            initialValues={props.visitor || newFormValues}
            validateOnChange={attemptedSubmission}
            validateOnBlur={attemptedSubmission}
            validate={(values) => {
              let error: string | undefined
              const errors: any = {}
              if ((error = Validator.visitorEmail(values.email?.trim())))
                errors.email = error

              if ((error = Validator.firstName(values.first_name)))
                errors.first_name = error

              if ((error = Validator.lastName(values.last_name)))
                errors.last_name = error

              if (values.phone?.length) {
                if ((error = Validator.phone(values.phone)))
                  errors.phone = error
              }

              return errors
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmissionError(undefined)
              const response: FormResponse = await (props.visitor
                ? VisitorService.update(values)
                : VisitorService.create(values))
              if (response.success) {
                alert.show(
                  props.visitor ? t('visitor_updated') : t('visitor_added'),
                )
                props.onVisitorChange()
              } else {
                setSubmissionError(Validator.formatErrors(response))
                setSubmitting(false)
              }
            }}
          >
            {({ isSubmitting, setSubmitting }) => (
              <Form>
                <h4>
                  {props.visitor ? t('update_visitor') : t('add_new_visitor')}
                </h4>
                <InputGroup>
                  <InputField
                    type='text'
                    label={t('field:first_name')}
                    name='first_name'
                  />
                  <InputField
                    type='text'
                    label={t('field:last_name')}
                    name='last_name'
                  />
                </InputGroup>
                <InputGroup>
                  <InputField
                    type='text'
                    label={t('field:email')}
                    name='email'
                  />
                  <InputField
                    type='tel'
                    label={t('field:mobile')}
                    name='phone'
                  />
                </InputGroup>
                <div css={ActionBarStyles}>
                  {props.visitor && (
                    <div css={DeleteEntityStyles}>
                      <span onClick={() => deleteVisitor(setSubmitting)}>
                        <FontAwesomeIcon
                          color={Theme(company).colors.fontcolors.body}
                          className='trash'
                          icon={['fas', 'trash-alt']}
                        />
                        {t('action:delete')}
                      </span>
                    </div>
                  )}

                  <Button
                    type='submit'
                    value={props.visitor ? t('action:update') : t('action:add')}
                    loading={isSubmitting}
                    onClick={() => setAttemptedSubmission(true)}
                  />
                </div>
                <InlineAlert
                  type='error'
                  show={!!submissionError}
                  message={submissionError}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </section>
  )
}

export default VisitorForm
