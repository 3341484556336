/** @jsx jsx */
import { jsx } from '@emotion/core'

const EnglishPrivacyPolicy = (): JSX.Element => {
  return (
    <div>
      <h1>Privacy Policy</h1>
      <h2>What data is collected when you use ARRIVED?</h2>

      <p>
        We process the personal data necessary for the ARRIVED service, more
        specifically the following personal data:
      </p>
      <ul>
        <li>First and last name;</li>
        <li>Phone number;</li>
        <li>Email address;</li>
        <li>Company name;</li>
      </ul>

      <p>
        In addition, the date and time are recorded, as well as the person with
        whom you have the appointment, and your consent to the processing of
        this data.
      </p>
      <h2>What is the legal basis for processing the data?</h2>
      <p>
        Your data will be used to register and plan your visit. The registration
        is, among other things, meant for source and contact investigation by
        the Health Services in the event of an infection. You participate on a
        voluntary basis. If you do not wish to give permission, access to the
        building cannot be refused. There is also a health check - also called
        triage - where you will be asked, among other things, whether you have a
        cold or a fever.
      </p>

      <p>
        If you have an account, we will also use your data to manage it. In
        addition, we may send you transactional emails and text messages, for
        example to remind you of a visit or to request a review of your visit.
      </p>

      <h2>Is the data shared with third parties?</h2>

      <p>
        We share the data with the location of your visit, such as tenants and
        receptions in offices, in order to carry out the services on site. We
        also share the data in case of source and contact research by the Health
        Services.
      </p>

      <h2>How is the information used?</h2>

      <p>
        Information provided for registration is used for that purpose as well
        as for visit reviews. We may send you emails or text messages regarding
        your registration, such as next steps, missing information,
        confirmations and cancellations. The information is also used to answer
        any questions. Finally, we record information for compiling user
        statistics and for securing the website.
      </p>

      <h2>Who collects the data?</h2>

      <p>
        ARRIVED collects your data. Our interactions with you on the websites
        and applications allow us to use third party software that also has
        (limited) access to your data. All data is protected with the highest
        security, encryption and two-step verification (where available).
      </p>

      <p>
        We handle your data with care and ensure that we only ask for the
        necessary data and never ask for information that is not necessary for
        ARRIVED to function properly.
      </p>

      <p>
        ARRIVED identity and contact information: <br />
        Bouwens Receptiediensten B.V. <br />
        Emmaplein 2<br /> 1075 AW Amsterdam <br />
        <a href='mailto:info@bouwensen.nl'> info@bouwensen.nl</a> <br />
        020-6422820 <br />
      </p>

      <h2>How long do we keep your personal data?</h2>
      <p>
        Your personal data will not be kept longer than necessary. The data for
        the health check is stored for a maximum of 14 days. After the
        aforementioned period, the data for the health check will be permanently
        deleted.
      </p>

      <h2>Access and / or change of your data</h2>
      <p>
        If you wish to inspect the personal data registered about you and / or
        change or delete this data, you can send a request to
        <a href='mailto:info@bouwensen.nl'> info@bouwensen.nl</a>. It is also
        possible to object to the processing of the personal data registered by
        you via this e-mail address.
      </p>

      <h2>Complaints</h2>

      <p>
        If you have any questions, comments or complaints about the protection
        of your personal data by ARRIVED, you can contact us by e-mail at
        <a href='mailto:info@bouwensen.nl'> info@bouwensen.nl</a>. ARRIVED is
        happy to help you find a solution. If that does not work, you always
        have the right to file a complaint with the Dutch Data Protection
        Authority at
        <a href='https://autoriteitpersoonsgegevens.nl/'>
          https://autoriteitpersoonsgegevens.nl/
        </a>
        .
      </p>
      <h2>Amendments</h2>
      <p>
        ARRIVED can make amendments, additions or changes to its privacy
        statement. The most current privacy statement can be viewed at all times
        on the ARRIVED website. This version was drawn up in October 2020.
      </p>
    </div>
  )
}

export default EnglishPrivacyPolicy
