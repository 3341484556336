/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'
import Theme from '../../styles/theme'

import { useRecoilValue } from 'recoil'
import { companyState } from '../../Session'
import { Company } from '../../types/Interfaces'

interface Props extends React.PropsWithChildren<unknown> {
  value: string
  onClick?: () => void
  disabled?: boolean
  loading?: boolean
  size?: 'small' | 'default' | 'large'
  alignment?: 'left' | 'right'
  type: 'cancel' | 'default' | 'delete' | 'submit' | 'inverted'
}

const Button = (props: Props) => {
  const company = useRecoilValue(companyState)

  const buttonClick = () => {
    if (props.onClick) {
      props.onClick()
    }
  }

  return (
    <div css={ButtonStyles(props, company)}>
      <button
        className={`${props.type} ${props.loading ? 'loading' : ''}`}
        type={props.type == 'submit' ? 'submit' : undefined}
        onClick={props.loading ? undefined : buttonClick}
        disabled={props.disabled}
      >
        {props.children}
        {props.value}
      </button>
    </div>
  )
}

export default Button

const ButtonStyles = (props: Props, company?: Company) => css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-${props.alignment == 'left' ? 'start' : 'end'};

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  button {
    display: block;
    width: auto;
    padding: ${props.size == 'small'
      ? '1rem 1.5rem'
      : props.size == 'large'
      ? '2rem 2rem 2rem 4rem'
      : '1.7rem'};
    border: none;
    background: ${Theme(company).colors.buttoncolors.default};
    background: ${Theme(company).colors.buttoncolors[
      props.type as 'cancel' | 'default' | 'delete' | 'inverted'
    ]};
    border-radius: ${props.size == 'large'
      ? '1rem'
      : Theme(company).layouts.buttonBorderRadius};
    color: ${props.type == 'inverted'
      ? Theme(company).colors.buttoncolors.default
      : Theme(company).colors.fontcolors.inverted};
    font-size: ${props.size == 'large' ? '2.5rem' : '1.6rem'};
    font-weight: normal;
    cursor: pointer;
    transition: 150ms all ease-in-out;
    @media ${Theme(company).breakpoints.medium} {
      padding: ${props.size == 'small'
        ? '1rem 2rem'
        : props.size == 'large'
        ? '2rem 2rem 2rem 4rem'
        : '1.7rem'};
    }
    &:focus,
    &:active {
      outline: none;
    }
    &:hover {
      opacity: 0.85;
    }
    &:disabled {
      background: ${Theme(company).colors.backgrounds.outer};
      color: ${Theme(company).colors.fontcolors.disabled};
      &:hover {
        transform: none;
        cursor: not-allowed;
      }
    }
    &.loading {
      position: relative;
      padding-right: 4.5rem;
      cursor: normal;
      &:before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        right: 1.5rem;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        border: 0.2rem solid ${Theme(company).colors.backgrounds.inner};
        border-top-color: ${Theme(company).colors.backgrounds.primary};
        animation: spinner 1.2s linear infinite;
      }
    }

    svg {
      margin-left: 1rem;
      margin-right: 0.6rem;
    }
  }
`
