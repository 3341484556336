/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React from 'react'
import { jsx, css } from '@emotion/core'
import Theme from '../styles/theme'
import { supportedLanguages, useLanguage } from '../hooks/language'

import { useRecoilValue } from 'recoil'
import { companyState } from '../Session'
import { Company } from '../types/Interfaces'

const LanguageSelector = (): JSX.Element => {
  const [selected, setLanguage] = useLanguage()
  const company = useRecoilValue(companyState)

  return (
    <>
      {supportedLanguages.map(
        (lang) =>
          lang != selected && (
            <div
              key={lang}
              css={styles(company)}
              className='language-selector'
              onClick={() => setLanguage(lang)}
            >
              <div className={[lang, 'language'].join(' ')}>
                {lang.toUpperCase()}
              </div>
            </div>
          ),
      )}
    </>
  )
}

export default LanguageSelector

const styles = (company?: Company) => css`
  display: flex;

  .language {
    position: relative;
    margin: 0 1.2rem;
    border: 0.2rem solid ${Theme(company).colors.fontcolors.body};
    border-radius: 0.4rem;
    height: 3rem;
    width: 3.6rem;
    color: ${Theme(company).colors.fontcolors.body};
    cursor: pointer;
    text-align: center;
    line-height: 2.6rem;
    font-size: 1.3rem;
    font-weight: bold;
    text-transform: uppercase;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover {
      color: ${Theme(company).colors.fontcolors.primary};
      border-color: ${Theme(company).colors.fontcolors.primary};
    }
  }
`
