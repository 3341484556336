/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, css } from '@emotion/core'
import React, { useEffect, useState } from 'react'
import useStateSafe from '../../helpers/use-state-safe'
import { Formik, Form, Field } from 'formik'
import Calendar from 'react-calendar'
import moment from 'moment'
import { useAlert } from 'react-alert'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import reactStringReplace from 'react-string-replace'
import { components } from 'react-select'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  AppointmentFormFields,
  User,
  Company,
  Visitor,
} from '../../types/Interfaces'

import * as Validator from '../../shared/Validations'

import Theme from '../../styles/theme'
import { CheckboxStyles } from '../../styles/global-styles'
import { CalendarStyles } from '../../styles/calendar-styles'
import { ActionBarStyles } from '../../styles/container-styles'
import { DeleteEntityStyles } from '../../styles/modal-styles'
import { ButtonToggleStyles } from '../../styles/toggle-styles'
import 'react-calendar/dist/Calendar.css'

import { useRecoilValue } from 'recoil'
import { userState, companyState } from '../../Session'

import {
  Button,
  InlineAlert,
  InputField,
  InputGroup,
  SelectFieldWrapper,
  Spinner,
  StyledSelect,
  TextareaField,
} from '../../components'

import VisitorRegistrationService from '../../services/VisitorRegistrationService'
import AppointmentService from '../../services/AppointmentService'
import UserService from '../../services/UserService'
import VisitorService from '../../services/VisitorService'
import VisitService from '../../services/VisitService'
import { useLanguage } from '../../hooks/language'

interface Props {
  onAppointmentChange: () => void
  selectedDate: Date
  appointmentData?: AppointmentFormFields
}

const AppointmentForm = (props: Props) => {
  const { t, i18n } = useTranslation('admin_appointments')
  const [language] = useLanguage()
  const [users, setUsers] = useStateSafe<User[]>([])
  const [visitors, setVisitors] = useStateSafe<Visitor[]>([])
  const [attemptedSubmission, setAttemptedSubmission] = useState(false)
  const [submissionError, setSubmissionError] = useState<string | undefined>()
  const [loaded, setLoaded] = useStateSafe(false)
  const [visitorsLoaded, setVisitorsLoaded] = useStateSafe(false)
  const [usersLoaded, setUsersLoaded] = useStateSafe(false)
  const [loadingStartTime, setLoadingStartTime] = useState<number | undefined>()
  const [addingNewVisitor, setAddingNewVisitor] = useStateSafe(false)
  const user = useRecoilValue(userState)
  const company = useRecoilValue(companyState)
  const alert = useAlert()

  useEffect(() => {
    setLoadingStartTime(Date.now())
    VisitorService.fetchAll().then((response) => {
      setVisitors(response.visitors)
      setVisitorsLoaded(true)
    })
    if (user?.account_type !== 'individual') {
      UserService.fetchAll().then((secondResponse) => {
        setUsers(secondResponse.users)
        setUsersLoaded(true)
      })
    } else {
      setUsersLoaded(true)
    }
  }, [])

  useEffect(() => {
    if (visitorsLoaded && usersLoaded) {
      setTimeout(() => {
        setLoaded(true)
      }, Math.max(500 - (Date.now() - (loadingStartTime || 0)), 0))
    }
  }, [visitorsLoaded, usersLoaded])

  function scheduledAt(values: AppointmentFormFields) {
    if (!values.scheduled_at_time && values.unplanned) {
      return undefined
    } else {
      return moment(
        `${values.scheduled_at_date} ${values.scheduled_at_time}`,
        'DD-MM-YYYY HH:mm',
      ).toDate()
    }
  }

  function arrivedAt(values: AppointmentFormFields): Date | null {
    if (!values.arrived_at_time?.length) return null

    return moment(
      `${values.scheduled_at_date} ${values.arrived_at_time}`,
      'DD-MM-YYYY HH:mm',
    ).toDate()
  }

  function submissionCheck(values: AppointmentFormFields): boolean {
    if (!props.appointmentData) return true
    if (props.appointmentData.arrived_at_time) return true
    if (!values.arrived_at_time) return true

    return confirm(t('visit_arrival_confirmation'))
  }

  async function deleteAppointment(
    setSubmitting: (isSubmitting: boolean) => void,
  ) {
    if (!props.appointmentData?.appointment_id) return

    if (confirm(t('visit_delete_confirmation'))) {
      setSubmitting(true)

      setSubmissionError(undefined)

      try {
        await AppointmentService.delete(props.appointmentData.appointment_id)
        alert.show(t('visit_deleted'))
        props.onAppointmentChange()
      } catch {
        setSubmissionError(Validator.unexpectedError)
      }

      setSubmitting(false)
    }
  }

  const feedbackRequest = (
    values: AppointmentFormFields,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => void,
  ) => {
    const past = moment(values.scheduled_at_date, 'DD-MM-YYYY').isBefore(
      moment(),
      'day',
    )

    if (past || !company?.send_review_request) {
      return (
        <>
          <Field
            type='checkbox'
            name='feedback'
            value='true'
            checked={values.send_review_request === true}
            disabled={past && !values.arrived_at_time}
            onChange={() =>
              setFieldValue('send_review_request', !values.send_review_request)
            }
          />
          {past ? t('feedback_email_send_past') : t('feedback_email_send')}
        </>
      )
    } else {
      return (
        <>
          <Field
            type='checkbox'
            name='feedback'
            value='true'
            checked={values.send_review_request === false}
            onChange={() => {
              setFieldValue(
                'send_review_request',
                values.send_review_request === null ||
                  values.send_review_request === undefined
                  ? false
                  : !values.send_review_request,
              )
            }}
          />
          {t('feedback_email_dont_send')}
        </>
      )
    }
  }

  const updateConfirmationEmailContent = (
    userId: number | string,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => void,
  ) => {
    const user = users.find((user) => user.id === userId)
    const tenant = company?.tenants?.find(
      (tenant) => tenant.id === user?.tenant_id,
    )

    setFieldValue(
      'notes',
      (tenant?.custom_messages &&
        tenant?.custom_messages[i18n.language as 'en' | 'nl']
          ?.appointment_confirmation) ||
        (company?.custom_messages &&
          company?.custom_messages[i18n.language as 'en' | 'nl']
            ?.appointment_confirmation) ||
        t('admin_settings:appointment_confirmation_default_content'),
    )
  }

  const newFormValues: AppointmentFormFields = {
    email: '',
    first_name: '',
    last_name: '',
    phone: '',
    scheduled_at_date: moment(props.selectedDate).format('DD-MM-YYYY'),
    scheduled_at_time: moment().isSame(moment(props.selectedDate), 'date')
      ? moment().format('HH:mm')
      : '09:00',
    notes:
      (company?.custom_messages &&
        company?.custom_messages[i18n.language as 'en' | 'nl']
          ?.appointment_confirmation) ||
      t('admin_settings:appointment_confirmation_default_content'),
  }

  return (
    <section>
      <div css={AppointmentFormStyles}>
        {loaded && (
          <div>
            <Formik
              initialValues={props.appointmentData || newFormValues}
              validateOnChange={attemptedSubmission}
              validateOnBlur={attemptedSubmission}
              validate={(values) => {
                let error: string | undefined
                const errors: AppointmentFormFields = {}
                if (addingNewVisitor) {
                  if ((error = Validator.visitorEmail(values.email))) {
                    errors.email = error
                  } else if (!!visitors.find((v) => v.email === values.email)) {
                    errors.email = t('visitor_already_exists')
                  }

                  if ((error = Validator.firstName(values.first_name)))
                    errors.first_name = error

                  if ((error = Validator.lastName(values.last_name)))
                    errors.last_name = error

                  if (values.phone?.length) {
                    if ((error = Validator.phone(values.phone)))
                      errors.phone = error
                  }
                } else {
                  if ((error = Validator.userId(values.visitor_id)))
                    errors.visitor_id = error as any
                }

                if (!values.unplanned) {
                  if ((error = Validator.time(values.scheduled_at_time)))
                    errors.scheduled_at_time = error
                }

                // Arrived time can't be removed once it's been set
                if (props.appointmentData?.arrived_at_time) {
                  if ((error = Validator.time(values.arrived_at_time)))
                    errors.arrived_at_time = error
                }

                // Health check data is required when the visitor has arrived
                if (values.arrived_at_time) {
                  if ((error = Validator.healthCheck(values.healthy)))
                    errors.healthy = error
                }

                if (user?.account_type !== 'individual') {
                  if ((error = Validator.userId(values.user_id)))
                    errors.user_id = error
                }

                return errors
              }}
              onSubmit={async (values, { setSubmitting }) => {
                if (submissionCheck(values)) {
                  setSubmissionError(undefined)

                  try {
                    if (props.appointmentData) {
                      await VisitService.update(
                        Object.assign(values, {
                          arrived_at: arrivedAt(values),
                        }),
                      )

                      const response = await AppointmentService.update(
                        Object.assign(values, {
                          scheduled_at: scheduledAt(values),
                        }),
                      )
                      if (response.success) {
                        alert.show(t('changes_saved'))
                        props.onAppointmentChange()
                      } else {
                        setSubmissionError(Validator.unexpectedError)
                      }
                    } else {
                      if (addingNewVisitor) {
                        await VisitorRegistrationService.register(values)
                      }
                      const response = await AppointmentService.create(
                        values.email || null,
                        values.visitor_id || null,
                        (values.user_id || (user?.id as number)).toString(),
                        scheduledAt(values),
                        values.send_review_request,
                        company?.language,
                        { notes: values.notes },
                      )

                      if (response.id) {
                        alert.show(
                          response.notes
                            ? t('visit_planned')
                            : t('visit_planned_not_confirmed'),
                        )
                        props.onAppointmentChange()
                      } else {
                        setSubmissionError(Validator.unexpectedError)
                      }
                    }
                  } catch {
                    setSubmissionError(Validator.unexpectedError)
                  }

                  setSubmitting(false)
                }
              }}
            >
              {({ isSubmitting, setSubmitting, setFieldValue, values }) => (
                <Form>
                  <h4>
                    {props.appointmentData
                      ? t('update_visit')
                      : t('plan_visit')}
                  </h4>

                  <div css={ColumnStyles(company)}>
                    <div className='left'>
                      {!props.appointmentData && (
                        <div css={VisitorToggleStyles}>
                          <a
                            css={ButtonToggleStyles(company)}
                            className={addingNewVisitor ? '' : 'active'}
                            onClick={() => setAddingNewVisitor(false)}
                          >
                            {t('existing_visitor')}
                          </a>
                          <a
                            css={ButtonToggleStyles(company)}
                            className={addingNewVisitor ? 'active' : ''}
                            onClick={() => setAddingNewVisitor(true)}
                          >
                            {t('new_visitor')}
                          </a>
                        </div>
                      )}

                      <div
                        css={VisitorSelectionStyles(company)}
                        className={
                          user?.account_type !== 'individual'
                            ? 'as-global-or-admin'
                            : 'as-individual'
                        }
                      >
                        {!addingNewVisitor && (
                          <div>
                            <SelectFieldWrapper
                              label={t('visitor')}
                              name='visitor_id'
                              defaultValue={props.appointmentData?.visitor_id}
                            >
                              <StyledSelect
                                name='visitor_id'
                                placeholder={t('visitor')}
                                className='visitor-registration'
                                customOption={VisitorSelectOption}
                                maxMenuHeight={400}
                                options={visitors.map((visitor) => {
                                  return {
                                    label: visitor.full_name,
                                    value: visitor.id,
                                    email: visitor.email,
                                  }
                                })}
                              />
                            </SelectFieldWrapper>

                            <InputGroup>
                              <div css={ReadOnlyFieldStyles}>
                                <label>{t('field:mobile')}</label>
                                <div>
                                  {visitors.find(
                                    (v) => v.id === values.visitor_id,
                                  )?.phone || '-'}
                                </div>
                              </div>

                              <div css={ReadOnlyFieldStyles}>
                                <label>{t('field:email')}</label>
                                <div>
                                  {' '}
                                  {visitors.find(
                                    (v) => v.id === values.visitor_id,
                                  )?.email || '-'}
                                </div>
                              </div>
                            </InputGroup>
                          </div>
                        )}

                        {addingNewVisitor && (
                          <div>
                            <InputGroup>
                              <InputField
                                type='text'
                                label={t('field:first_name')}
                                name='first_name'
                              />
                              <InputField
                                type='text'
                                label={t('field:last_name')}
                                name='last_name'
                              />
                            </InputGroup>

                            <InputGroup>
                              <InputField
                                type='tel'
                                label={t('field:mobile')}
                                name='phone'
                              />
                              <InputField
                                type='text'
                                label={t('field:email')}
                                name='email'
                              />
                            </InputGroup>
                          </div>
                        )}

                        {user?.account_type !== 'individual' && (
                          <SelectFieldWrapper
                            label={t('appointment_with')}
                            name='user_id'
                            defaultValue={props.appointmentData?.user_id}
                            customOnChange={(userId) =>
                              updateConfirmationEmailContent(
                                userId,
                                setFieldValue,
                              )
                            }
                          >
                            <StyledSelect
                              name='user_id'
                              placeholder={t('appointment_with')}
                              className='visitor-registration'
                              options={users.map((user) => {
                                return {
                                  label: user.first_name + ' ' + user.last_name,
                                  value: user.id,
                                }
                              })}
                            />
                          </SelectFieldWrapper>
                        )}
                      </div>

                      {props.appointmentData && (
                        <SelectFieldWrapper
                          label={t('health_check')}
                          disabled={!values.arrived_at_time}
                          name='healthy'
                          withIcons={true}
                          defaultValue={props.appointmentData?.healthy}
                        >
                          <StyledSelect
                            name='healthy'
                            placeholder={t('health_check')}
                            options={[
                              { label: t('accepted'), value: 'true' },
                              { label: t('rejected'), value: 'false' },
                            ]}
                            isSearchable={false}
                          />
                        </SelectFieldWrapper>
                      )}

                      {!props.appointmentData && (
                        <TextareaField
                          label={t('confirmation_email')}
                          name='notes'
                        />
                      )}

                      <div css={FeedbackStyles(company)}>
                        <label>{t('feedback_email')}</label>
                        <div css={CheckboxStyles}>
                          <label>
                            <FontAwesomeIcon icon={['far', 'info-circle']} />
                            {reactStringReplace(
                              t('feedback_email_default'),
                              '%{turned_on}',
                              (match) => (
                                <NavLink key={match} to='/admin/settings'>
                                  {company?.send_review_request
                                    ? t('turned_on')
                                    : t('turned_off')}
                                </NavLink>
                              ),
                            )}
                          </label>

                          <label>
                            {feedbackRequest(values, setFieldValue)}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className='divider' />
                    <div className='right'>
                      <div className='time-container'>
                        <div
                          className='react-calendar-container'
                          css={CalendarStyles(company)}
                        >
                          <Calendar
                            locale={language}
                            onChange={(date) =>
                              setFieldValue(
                                'scheduled_at_date',
                                moment(date as Date).format('DD-MM-YYYY'),
                              )
                            }
                            value={moment(
                              values.scheduled_at_date,
                              'DD-MM-YYYY',
                            ).toDate()}
                            minDate={new Date()}
                          />
                        </div>

                        <InputGroup>
                          <InputField
                            disabled={true}
                            type='text'
                            label={t('scheduled_at_date')}
                            name='scheduled_at_date'
                          />

                          {!values.unplanned && (
                            <InputField
                              type='time'
                              label={t('scheduled_at_time')}
                              name='scheduled_at_time'
                            />
                          )}

                          {props.appointmentData && (
                            <InputField
                              type='time'
                              label={t('arrived_at_time')}
                              name='arrived_at_time'
                            />
                          )}
                        </InputGroup>
                      </div>
                      {!props.appointmentData && (
                        <Button
                          type='submit'
                          value={t('action:add')}
                          loading={isSubmitting}
                          onClick={() => setAttemptedSubmission(true)}
                        />
                      )}
                    </div>
                  </div>

                  {props.appointmentData && (
                    <div css={ActionBarStyles}>
                      <div css={DeleteEntityStyles}>
                        <span onClick={() => deleteAppointment(setSubmitting)}>
                          <FontAwesomeIcon
                            color={Theme(company).colors.fontcolors.body}
                            className='trash'
                            icon={['fas', 'trash-alt']}
                          />
                          {t('action:delete')}
                        </span>
                      </div>

                      <Button
                        type='submit'
                        value={t('action:update')}
                        loading={isSubmitting}
                        onClick={() => setAttemptedSubmission(true)}
                      />
                    </div>
                  )}

                  {submissionError && (
                    <InlineAlert
                      type='error'
                      show={true}
                      message={submissionError}
                    />
                  )}
                </Form>
              )}
            </Formik>
          </div>
        )}
        {!loaded && <Spinner size='large' />}
      </div>
    </section>
  )
}

export default AppointmentForm

const VisitorSelectOption = (props: any) => {
  const option = props.options.find(
    (t: {
      label: string
      value: string
      company_name: string
      portrait: string
    }) => t.value === props.value,
  )
  return (
    <components.Option {...props}>
      <div css={VisitorSelectOptionStyles}>
        <div>
          <div>{props.label}</div>
          <div className='details'>{option.email}</div>
        </div>
      </div>
    </components.Option>
  )
}

const VisitorSelectOptionStyles = css`
  .details {
    font-size: 1.4rem;
    opacity: 0.5;
  }
`

const VisitorToggleStyles = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  > a {
    flex-grow: 1;
    &:first-of-type {
      margin-right: 1rem;
    }
  }
`

const ReadOnlyFieldStyles = css`
  margin-top: 1.5rem;

  label {
    font-weight: 400;
    font-size: 1.3rem;
  }

  div {
    font-size: 1.4rem;
  }
`

const AppointmentFormStyles = css`
  max-width: calc(100vw - 12rem);
  @media (min-width: 880px) {
    min-width: 75rem;
  }

  .react-calendar-container {
    max-width: calc(100vw - 12rem);
    min-height: 35rem;
    margin-bottom: 0;

    .react-calendar {
      background: unset;
    }
  }
`

const ColumnStyles = (company?: Company) => css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .left,
  .right {
    flex-grow: 1;
  }

  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .right {
    > .time-container {
      padding: 2rem;
      border-radius: 1rem;
      background-color: ${Theme(company).colors.backgrounds.outer};
    }

    > div:not(.time-container) button {
      margin-top: 3rem;
    }
  }

  .divider {
    width: 5rem;
    height: 0.1rem;
  }
`

const FeedbackStyles = (company?: Company) => css`
  > label {
    color: ${Theme(company).colors.fontcolors.primary};
    font-weight: 400;
  }

  > div {
    margin-top: 1rem;
    font-size: 1.3rem;

    a {
      display: inline-block;
      margin: 0 0.5rem;
      color: ${Theme(company).colors.fontcolors.primary};
    }
  }
`

const VisitorSelectionStyles = (company?: Company) => css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 2rem 2rem;
  min-width: 39.2rem;
  border-radius: 1rem;
  background-color: ${Theme(company).colors.backgrounds.outer};

  &.as-individual {
    min-height: 18.8rem;
  }

  &.as-global-or-admin {
    min-height: 26.8rem;
  }
`
