import { useEffect, useRef, useState, Dispatch, SetStateAction } from 'react'

/* originally via:
https://gist.github.com/AlpacaGoesCrazy/25e3a15fcd4e57fb8ccd408d488554d7
*/

const useStateSafe = function <T>(
  initialValue: T,
): [T, Dispatch<SetStateAction<T>>] {
  const isMounted = useRef(false) // useRef to memorize if the component is mounted between renders
  const [state, setState] = useState<T>(initialValue)

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  })

  const setStateSafe = (t: T | ((prev: T) => T)) => {
    if (isMounted.current) {
      // do not call setState if the component already unmounted
      setState(t)
    }
  }

  return [state, setStateSafe]
}

export default useStateSafe
