/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { ChangeEvent, useEffect, useState } from 'react'
import useStateSafe from '../../helpers/use-state-safe'
import { useAlert } from 'react-alert'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { Formik, Form } from 'formik'
import { companyState } from '../../Session'
import Toggle from 'react-toggle'
import 'react-toggle/style.css'

import LogoDropZone from '../../shared/LogoDropZone'
import Theme from '../../styles/theme'
import { CheckboxStyles } from '../../styles/global-styles'
import { ActionBarStyles } from '../../styles/container-styles'
import { ToggleStyles } from '../../styles/toggle-styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Company,
  FormResponse,
  CompanyColors,
  Tenant,
} from '../../types/Interfaces'

import TenantsAdminShow from '../../components/TenantsAdminShow'

import {
  PageWidth,
  Button,
  InlineAlert,
  ColorField,
  QrPoster,
  Spinner,
} from '../../components'
import CustomMessagesForm from '../../components/admin/CustomMessagesForm'
import ValidatedDomainsForm from '../../components/admin/ValidatedDomainsForm'
import AdminTenants from './AdminTenants'

import CompanyService from '../../services/CompanyService'

import { userState } from '../../Session'
import { verifyUserAccountType } from '../../helpers/user'

import * as Validator from '../../shared/Validations'
import { Language } from '../../hooks/language'

const AdminSettings = (): JSX.Element => {
  const { t } = useTranslation('admin_settings')
  const [company, setCompany] = useRecoilState(companyState)
  const [loaded, setLoaded] = useStateSafe(false)
  const alert = useAlert()
  const [expandedTenantIds, setExpandedTenantIds] = useState<number[]>([99])
  const [user] = useRecoilState(userState)

  useEffect(() => {
    fetchCompany()
  }, [])

  async function fetchCompany() {
    const startTime = Date.now()
    try {
      const response = await CompanyService.get()

      setTimeout(() => {
        setCompany(response)
        setLoaded(true)
      }, Math.max(500 - (Date.now() - startTime), 0))
    } catch {
      setLoaded(true)
    }
  }

  const upload = async ([file]: File[]) => {
    handleUpdateResult(CompanyService.update({ logo: file }))
  }

  const handleLanguageChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const language = event.target.value as Language
    handleUpdateResult(CompanyService.update({ language }))
  }

  const handleTenancyChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const is_multi_tenant = event.target.value as 'true' | 'false'
    handleUpdateResult(CompanyService.update({ is_multi_tenant }))
  }

  const handleUpdateResult = async (
    request: Promise<FormResponse>,
    successMessage?: string,
    callback?: () => void,
  ) => {
    try {
      const response = await request

      if (response.success) {
        alert.show(successMessage || t('settings_updated'))
        setCompany(response.body as Company)
        if (callback) callback()
      } else {
        alert.show(Validator.formatErrors(response), {
          type: 'error',
          timeout: 0,
        })
      }
    } catch {
      alert.show(Validator.unexpectedError, { type: 'error', timeout: 0 })
    }
  }

  const BaseContent = (
    props: React.PropsWithChildren<unknown>,
  ): JSX.Element => {
    return (
      <section css={SettingsStyles(company)}>
        <PageWidth>
          <h1>{t('settings')}</h1>
          {props.children}
        </PageWidth>
      </section>
    )
  }

  if (!loaded) {
    return (
      <BaseContent>
        <Spinner size='large' />
      </BaseContent>
    )
  } else if (company && verifyUserAccountType('company_admin', user)) {
    return (
      <BaseContent>
        <div className='settings'>
          <div className='columns'>
            <div className='left'>
              <h3>{t('registration_via_link')}</h3>
              <div className='sub-domain'>
                <FontAwesomeIcon icon={['fas', 'globe']} />
                <span>
                  <span>arrived.at/</span>
                  <span className='domain'>{company.domain}</span>
                </span>
              </div>

              <h3>{t('registration_via_qr_code')}</h3>
              <div className='qr-code'>
                <div className='explanation'>
                  <p>{t('registration_via_qr_code_details')} </p>
                </div>
                <div>
                  <img src='/qr.svg' />
                </div>
              </div>

              <div className='download-links'>
                <a href='/qr.png' download={true} className='download'>
                  <FontAwesomeIcon icon={['fas', 'cloud-download']} />{' '}
                  {t('download_qr_code')}
                </a>

                <a
                  download={true}
                  onClick={() => QrPoster(company)}
                  className='download'
                >
                  <FontAwesomeIcon icon={['fas', 'cloud-download']} />{' '}
                  {t('download_qr_poster')}
                </a>
              </div>

              <h3>{t('default_language')}</h3>
              <p>{t('default_language_details')}</p>

              <div css={CheckboxStyles}>
                <label>
                  <input
                    type='radio'
                    name='language'
                    value='en'
                    checked={company.language === 'en'}
                    onChange={handleLanguageChange}
                  />{' '}
                  English
                </label>

                <label>
                  <input
                    type='radio'
                    name='language'
                    value='nl'
                    checked={company.language === 'nl'}
                    onChange={handleLanguageChange}
                  />{' '}
                  Nederlands
                </label>
              </div>
            </div>
            <div className='right'>
              <LogoDropZone uploadLogo={upload} company={company} />

              <h3>{t('theme')}</h3>
              <div className='themes'>
                {['light', 'dark', 'custom'].map((theme) => (
                  <div
                    key={theme}
                    className={`${theme} ${
                      company.theme == theme ? 'selected' : ''
                    }`}
                    onClick={() => {
                      if (company.theme != theme)
                        handleUpdateResult(CompanyService.update({ theme }))
                    }}
                  >
                    <FontAwesomeIcon
                      icon={[
                        'fas',
                        theme == 'light'
                          ? 'lightbulb-on'
                          : theme == 'dark'
                          ? 'moon-stars'
                          : 'sliders-h',
                      ]}
                    />
                    <span>{t(theme)}</span>
                  </div>
                ))}
              </div>
              {company.theme == 'custom' && (
                <Formik
                  initialValues={company.colors}
                  validateOnChange={true}
                  validateOnBlur={true}
                  validate={(values) => {
                    let error: string | undefined
                    const errors: DeepPartial<CompanyColors> = {}

                    if ((error = Validator.color(values.css_primary_color)))
                      errors.css_primary_color = error

                    if ((error = Validator.color(values.css_header_text_color)))
                      errors.css_header_text_color = error

                    if ((error = Validator.color(values.css_text_color)))
                      errors.css_text_color = error

                    if (
                      (error = Validator.color(
                        values.css_outer_background_color,
                      ))
                    )
                      errors.css_outer_background_color = error

                    if (
                      (error = Validator.color(
                        values.css_inner_background_color,
                      ))
                    )
                      errors.css_inner_background_color = error

                    return errors
                  }}
                  onSubmit={async (values) => {
                    handleUpdateResult(CompanyService.update(values))
                  }}
                >
                  {({ values, isSubmitting, setFieldValue }) => (
                    <Form>
                      <div css={CustomColorStyles}>
                        {Object.keys(values).map((field) => (
                          <ColorField
                            key={field}
                            label={t(`field:${field}`)}
                            name={field}
                            setColor={(color) =>
                              setFieldValue(
                                field,
                                color.replace('#', '').toUpperCase(),
                              )
                            }
                          />
                        ))}
                      </div>

                      <div css={ActionBarStyles}>
                        <a
                          onClick={() => {
                            const companyCopy = Object.assign({}, company, {
                              colors: values,
                            })
                            setCompany(companyCopy)
                          }}
                        >
                          {t('action:preview')}
                        </a>

                        <Button
                          type='submit'
                          size='small'
                          value={t('action:save')}
                          loading={isSubmitting}
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </div>
        </div>

        <div className='company'>
          <h2>{company.name}</h2>
          <div className='columns'>
            <div className='left'>
              <ValidatedDomainsForm
                handleSubmit={handleUpdateResult}
                entity={company}
                type='company'
              />

              <h4>{t('office_type')}</h4>
              <p>{t('office_type_details')}</p>
              <div css={CheckboxStyles}>
                <label>
                  <input
                    type='radio'
                    name='is_multi_tenant'
                    value='false'
                    checked={!company.is_multi_tenant}
                    onChange={handleTenancyChange}
                  />{' '}
                  {t('single_tenant')}
                </label>

                <label>
                  <input
                    type='radio'
                    name='is_multi_tenant'
                    value='true'
                    checked={company.is_multi_tenant}
                    onChange={handleTenancyChange}
                  />{' '}
                  {t('multi_tenant')}
                </label>
              </div>
            </div>
            <div className='right'>
              <CustomMessagesForm
                handleSubmit={handleUpdateResult}
                service={CompanyService}
                entity={company}
              />
              <div css={ToggleStyles}>
                <Toggle
                  id='automated_emails'
                  checked={company.send_review_request}
                  onChange={() =>
                    handleUpdateResult(
                      CompanyService.update({
                        send_review_request: company.send_review_request
                          ? 'false'
                          : 'true',
                      }),
                    )
                  }
                />
                <label htmlFor='automated_emails'>
                  {t('feedback_automated_message')}
                </label>
              </div>
              <p>{t('feedback_automated_message_details')}</p>
            </div>
          </div>
        </div>

        {(company.tenants.length || company.is_multi_tenant) && (
          <AdminTenants
            expandedTenantIds={expandedTenantIds}
            setExpandedTenantIds={setExpandedTenantIds}
            tenants={company.tenants}
            active={company.is_multi_tenant}
            propagateResult={(response: Company) => {
              setCompany(response)
            }}
          />
        )}
      </BaseContent>
    )
  } else if (company && verifyUserAccountType('tenant_admin', user)) {
    return (
      <BaseContent>
        <TenantsAdminShow
          onAdminPage={false}
          tenant={
            company.tenants.find(
              (tenant) => tenant.id === user?.tenant_id,
            ) as Tenant
          }
          propagateResult={(response: Company) => {
            setCompany(response)
          }}
          active={company.is_multi_tenant}
          expandedTenantIds={[user?.tenant_id] as number[]}
          setExpandedTenantIds={setExpandedTenantIds}
        />
      </BaseContent>
    )
  } else {
    return (
      <BaseContent>
        <InlineAlert
          type='error'
          show={true}
          message={Validator.unexpectedError}
        />
      </BaseContent>
    )
  }
}

export default AdminSettings

const CustomColorStyles = css`
  margin: 3rem 0;
`

const SettingsStyles = (company?: Company) => css`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .settings,
  .company {
    background: ${Theme(company).colors.backgrounds.inner};
    padding: 3.4rem 4.4rem 4.4rem;
    box-shadow: ${Theme(company).colors.borders.mediumContainerShadow};
    margin-top: 3.5rem;

    h2 {
      color: ${Theme(company).colors.fontcolors.body};
      font-size: 2.6rem;
      font-weight: 300;
      letter-spacing: -0.034rem;
    }
  }

  .columns {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
    @media ${Theme(company).breakpoints.desktop} {
      flex-direction: row;
    }

    .left,
    .right {
      width: 100%;
      @media ${Theme(company).breakpoints.desktop} {
        width: calc(50% - 12rem);
      }

      flex-grow: 1;

      h3,
      h4 {
        margin-top: 4rem;
      }

      h4 {
        font-size: 1.6rem;
        margin-bottom: 1.3rem;
      }

      .sub-title {
        color: ${Theme(company).colors.fontcolors.body};
        font-size: 1.6rem;
        margin-bottom: 2.6rem;
      }

      .sub-domain {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 1rem;
        background-color: ${Theme(company).colors.backgrounds.outer};
        padding: 2.5rem 2rem;
        font-size: 2rem;
        font-weight: normal;

        svg {
          margin-right: 1rem;
        }

        span:first-of-type {
          font-weight: 300;
        }

        .domain {
          color: ${Theme(company).colors.fontcolors.primary};
        }
      }

      .themes {
        display: flex;
        justify-content: space-between;

        > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          border: 0.1rem solid ${Theme(company).colors.borders.light};
          padding: 3rem 3rem 1.2rem;
          border-radius: 1rem;
          width: 31%;
          text-align: center;
          cursor: pointer;
          min-height: 11.6rem;
          font-weight: normal;

          &.disabled {
            opacity: 0.3;
            cursor: not-allowed;
          }

          &.selected {
            background-color: ${Theme(company).colors.backgrounds.outer};
            border: 0.1rem solid ${Theme(company).colors.backgrounds.outer};
          }

          span {
            font-size: 1.3rem;
          }

          &.dark {
            svg {
              position: relative;
              top: -0.6rem;
              font-size: 3.5rem;
            }
          }

          &.light {
            svg {
              font-size: 3rem;
            }
          }

          &.custom {
            svg {
              font-size: 3rem;
            }
          }
        }
      }
    }

    .left {
      margin-right: 12rem;
      @media ${Theme(company).breakpoints.mediumDown} {
        margin-right: 0;
      }

      h3:first-of-type,
      h4:first-of-type {
        margin-top: 1rem;
      }
    }

    .right {
      @media ${Theme(company).breakpoints.desktop} {
        h3:first-of-type,
        h4:first-of-type {
          margin-top: 1rem;
        }
      }
    }
  }

  .qr-code {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    @media ${Theme(company).breakpoints.mediumDown} {
      flex-direction: column;
    }

    .explanation {
      width: 51%;
      @media ${Theme(company).breakpoints.mediumDown} {
        width: 100%;
      }
    }

    > div:last-of-type {
      img {
        max-width: 13.5rem;
        height: 13.5rem;
        filter: invert(${company?.theme == 'dark' ? 0.7 : 0});
        @media ${Theme(company).breakpoints.mediumDown} {
          margin: 2rem auto;
        }
      }
    }
  }

  a.download {
    display: block;
    font-weight: normal;
    @media ${Theme(company).breakpoints.mediumDown} {
      margin: 0 auto;
      text-align: center;
    }
    margin-bottom: 1rem;

    svg {
      margin-right: 0.5rem;
    }
  }
`
